
<section class="m-3">
    <div class="container px-md-0 table-responsive">

        <table class="table table-bordered" id="consentRecord">
            <thead class="thead-light">
                <tr>
                    <th style="display: none;">S/No</th>
                    <th>Created On</th>                    
                    <th>Symptoms Summary</th>
                    <th>Possible Causes</th>
                    <th>Suggested Care</th>
                    <!-- <th>View</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of consentdata; index as i">
                    <td style="display: none;">{{i + 1}}</td>
                    <td>{{data.createdOn | date:'dd/MM/yyyy '}}</td>                    
                    <td>{{data.symptomSummary}}</td>
                    <td>{{data.possible_causes}}</td>
                    <td>{{data.triage_result}}</td>
                    <!-- <td> 
                        <button href="#Modal1" class="btn fa-btn" data-toggle="modal"  aria-hidden="true">View</button>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</section>

<div id="Modal1" class="modal fade">
    <!-- class modal and fade -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <!-- modal header -->
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
      ×
    </button>
            </div>
            <div class="modal-body  result-shade1">
                <!-- modal body -->
               
                Welcome in view 
            </div>
        </div>
        <!-- / .modal-content -->

    </div>
    <!-- / .modal-dialog -->

</div>

