<!-- <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-10">
        <h5 class="card-title font-weight-bold">
            <p>Contact us at contact@oxvc.health if you have any questions
        </h5>
        <br>
    </div>
</div> -->
<!--  -->
<div class="card">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a href="#home" class="nav-link active" id="profilelist" data-toggle="tab">Profile</a>
        </li>
        <li class="nav-item">
            <a href="#profile" class="nav-link" id="otherlist" data-toggle="tab">Other Information</a>
        </li>
    </ul>
    <form id="msform" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm">
        <div class="tab-content">
            <div class="tab-pane fade show active" id="home">
                <div class="card Profiledetails">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" name="firstname" class="form-control required" ngModel required #firstname="ngModel" placeholder="Enter First Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="lastName" placeholder="Enter Last Name" ngModel required #address1_line1="ngModel" #lastName="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Email</label>
                                    <div class="col-sm-9">
                                        <input type="email" class="form-control" name="emailaddress1" placeholder="Enter Email" ngModel required #emailaddress1="ngModel" #name="ngModel" (keyup)='checkEMAIL($event)'>
                                        <span id="emailInfo" class="tooltiptext card">
                                            <p [ngClass]="(!specialCharacteremail ? 'text-danger': 'tooltiptext')  ">
                                                <i class="material-icons"></i>Please enter valid email address
                                            </p>
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Phone</label>
                                    <div class="col-sm-9">

                                        <input class="form-control" type="text" id="mobilephone" name="mobilephone" ngModel #mobilephone="ngModel" #name="ngModel" ng2TelInput (countryChange)="onCountryChange($event)" [ng2TelInputOptions]="{initialCountry: 'gb'}" placeholder="Enter mobile phone"
                                            (keyup)='checkmobile($event)' maxlength="20">
                                        <span id="mobileInfo" class="tooltiptext" style="border: 0px;">
                                                      <p [ngClass]="(!specialCharactermobile ? 'text-danger': 'tooltiptext')  ">
                                                          <i class="material-icons"></i>Please enter valid mobile number eg(+447895462415)
                                                      </p>
                                                  </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Gender</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id='purpose' name="gendercode" placeholder="Select Gender" required ngModel #gendercode="ngModel" #name="ngModel">
                                                <option value="">--Select Gender--</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-3 required">Birthdate</label>
                                    <div class="col-sm-9">
                                        <input type="date" class="form-control" name="birthdate" required placeholder="Enter Birthdate" ngModel #birthdate="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-3">NHS Number</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="bcrm_nhsnumber" placeholder="Enter NHS " ngModel #bcrm_nhsnumber="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Funded</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id='purpose' name="bcrm_funded" ngModel required #bcrm_funded="ngModel" #name="ngModel" (change)="IsCompanyEnable($event.target.value)">
                                                <option disabled>Select Funded Type</option>
                                                <option value="1">Private</option>
                                                <option value="2">NHS</option>
                                                <option value="3">Insurance</option>
                                            </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="row pull-right" style="padding: 1% 1% 2% 1%;">
                    <input type="button" id="next" class="btn btn-primary btnNext " value="Next" [disabled]="firstname.errors?.required || lastName.errors?.required || emailaddress1.errors?.required || birthdate.errors?.required|| gendercode.errors?.required|| bcrm_funded.errors?.required">
                </div>
            </div>

            <div class="tab-pane fade" id="profile">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label for="address" class="col-sm-4">Address Lookup</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter Address Lookup" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group" *ngIf="!isSelectfunded">
                                    <label class="bmd-label-floating col-sm-4">Insurance</label>
                                    <div class="col-sm-8">
                                        <select class="form-control" id='purpose' name="parentaccountid" ngModel #parentaccountid="ngModel" #name="ngModel" [disabled]="!isInsuranceEnable">
                                                <option value="" disabled>Select Insurance Company</option>
                                                <option *ngFor="let insuranceCompany of insuranceCompanyList"
                                                    [value]="insuranceCompany.accountid">
                                                    {{insuranceCompany.name}}</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-4">Street</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="Enter Street" name="address1_line1" ngModel #address1_line1="ngModel" #name="ngModel" id="route">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group" *ngIf="!isSelectfunded">
                                    <label class="bmd-label-floating col-sm-4">Health Code Number</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="bcrm_healthcodenumber" placeholder="Enter Health Code Number" ngModel #bcrm_healthcodenumber="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-4">State</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="address1_stateorprovince" placeholder="Enter State" ngModel #address1_stateorprovince="ngModel" #name="ngModel" id="administrative_area_level_1">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group" *ngIf="!isSelectfunded">
                                    <label class="bmd-label-floating col-sm-4">Policy Number</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="bcrm_policynumber" placeholder="Enter Policy Number" ngModel #bcrm_policynumber="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-4">Country</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="address1_country" placeholder="Enter Country" ngModel #address1_country="ngModel" #name="ngModel" id="country">
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-4">City</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="address1_city" placeholder="Enter City" ngModel #address1_city="ngModel" #name="ngModel" id="locality">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating col-sm-4">Postal Code</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" name="address1_postalcode" placeholder="Enter Zip Code" ngModel #address1_postalcode="ngModel" #name="ngModel" id="postal_code">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row parantRow" style="margin-left: 0%;">
                            <p style="color: black;font-size: 20px;">GDPR</p>
                        </div>
                        <div class="row parantRow">

                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Emails</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='donotemail'
                                                    name="donotemail" ngModel #name="ngModel" [ngModel]="true" (change)="getEmail($event.target.checked)">
                                                <div class="slider round">
                                                            <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                            <!--END-->
                                                        </div>
                                            </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Follow Mail</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='followemail' name="followemail"
                                                ngModel #name="ngModel" [ngModel]="true" (change)="getFollowEmail($event.target.checked)">
                                                <div class="slider round">
                                                    <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                    <!--END-->
                                                </div>
                                           </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row parantRow">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Newsletter</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='donotbulkemail'
                                                name="donotbulkemail" ngModel #name="ngModel" [ngModel]="true" (change)="getBulkEmail($event.target.checked)">
                                                <div class="slider round">
                                                    <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                    <!--END-->
                                                </div>
                                           </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Phone Calls</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='donotphone' name="donotphone"
                                                ngModel #name="ngModel" [ngModel]="true" (change)="getPhone($event.target.checked)">
                                                <div class="slider round">
                                                    <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                    <!--END-->
                                                </div>
                                           </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row parantRow">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Mails</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='donotpostalmail'
                                                name="donotpostalmail" ngModel #name="ngModel" [ngModel]="true" (change)="getMails($event.target.checked)"> 
                                                <div class="slider round">
                                                    <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                    <!--END-->
                                                </div>
                                           </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-4">Faxes</label>
                                    <div class="col-sm-5">
                                        <label class="switch"><input type="checkbox" id='donotfax' name="donotfax" ngModel
                                                #name="ngModel" [ngModel]="true" (change)="getFax($event.target.checked)">
                                                <div class="slider round">
                                                    <!--ADDED HTML --><span class="on">Yes</span> <span class="off">No</span>
                                                    <!--END-->
                                                </div>
                                           </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row parantRow" style="margin-left: 0%;">
                            <p style="color: black;font-size: 20px;">WHERE DID YOU HERE ABOUT US?</p>
                        </div>
                        <div class="row parantRow">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <div class="col-sm-7">
                                        <select class="form-control" id='wheredidyouhearaboutus' name="bcrm_wheredidyouhearaboutus" #name="ngModel" #bcrm_wheredidyouhearaboutus="ngModel" ngModel>
                                                <option value="" disabled>Where did you here about us?</option>
                                                <option value="1">DOCTORnow Cars</option>
                                                <option value="2">Local Marketing e.g. Banners</option>
                                                <option value="3">Railway Station</option>
                                                <option value="4">Employer/Work</option>
                                                <option value="5">Local/Previous Knowledge</option>
                                                <option value="6">Social Media</option>
                                                <option value="7">Hospital/Specialist/ Pharmacy</option>
                                                <option value="8">Magazine/Door Drop</option>
                                                <option value="9">Word of Mouth/Recommendation</option>
                                                <option value="10">Insurance Company</option>
                                                <option value="11">MASTA</option>
                                                <option value="12">Internet</option>
                                                <option value="13">NHS GP</option>
                                                <option value="14">Other</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row parantRow" style="margin-left: 0%;">
                            <p style="color: black;font-size: 20px;">PATIENT CONSENT OPTIONS(please tick preferences)
                            </p>
                        </div>

                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"><input type="checkbox" name="bcrm_iunderstandthatdoctornowisaprivategpservi" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I understand
                                        that OX.gp is a Private GP Service and that fees will apply for
                                        consultations and treatments</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"><input type="checkbox" name="bcrm_iunderstandthatacancellationfeewillbeappl" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I understand
                                        that a cancellation fee will be applied if appointments are not
                                        cancelled within 4 hours’ notice</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"> <input type="checkbox" name="bcrm_iwishtoreceivetextmessagesfromdoctornow" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I wish to
                                        receive TEXT messages from OX.gp (ie appointment and prescription
                                        reminders)</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"><input type="checkbox" name="bcrm_iwishtoreceiveemailsfromdoctornow" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I wish to receive EMAILS from OX.gp
                                        (ie results, appointment confirmations, copies of referrals, emails from
                                        OX.gp team, updates on services)</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"> <input type="checkbox" name="bcrm_iwishtoreceivepostalcommunicationfromdoct" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I wish to
                                        receive POSTAL communication from OX.gp (Note that we are unable to send
                                        appointment and prescription reminders via POST)</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"><input type="checkbox" name="bcrm_iwouldliketoreceiveupdatesaboutnewservice" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I would like
                                        to receive updates about new services and developments at OX.gp</span>
                            </div>

                        </div>
                        <div class="row parantRow">
                            <div class="col-md-10 col-sm-10"> <input type="checkbox" name="bcrm_iamhappyfordoctornowtocontactmynextofkin" ngModel />&nbsp;<span style="color: black;font-size: 16px;">I am happy
                                        for OX.gp to contact my Next of Kin / Emergency Contact identified above,
                                        in case of emergency, and I have their consent for this</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row pull-right" style="padding: 1% 1% 1% 1%;">
                    <button type="Button" class="btn btn-primary pull-left btnPrevious ">Back</button>&nbsp;&nbsp;
                    <input type="submit" class="btn btn-primary " value="Confirm" [disabled]='!it.valid'>
                </div>
            </div>

        </div>
    </form>
</div>