<div [ngClass]="{'d-none':!Istoken ,'main-wrapper':Istoken}">

  <!-- partial:../../partials/_sidebar.html -->
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="#" class="sidebar-brand">
        <img src="../../../assets/images/OX_general practice.png" style="width: 150px;" alt="">
      </a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">

        <li class="nav-item active PDComponent 21">
          <a href="/PDComponent" skipLocationChange class="nav-link " (click)="selectLink('PDComponent' ,21)"
            routerLink="./PDComponent">
            <i class="link-icon" data-feather="box"></i><img src="../../../assets/images/profile.png" height="25">
            <span class="link-title">Profile</span>
          </a>
        </li>
        <li class="nav-item allergy 1">
          <a (click)="selectLink('allergy' ,1)" routerLink="./allergy" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 3.png" height="25">
            <span class="link-title">Allergy</span>
          </a>
        </li>
        <li class="nav-item Condition 2">
          <a (click)="selectLink('Condition',2)" routerLink="./ConditionNew" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 5.png" height="25">
            <span class="link-title">Conditions</span>
          </a>
        </li>
        <li class="nav-item invoices 3">
          <a (click)="selectLink('invoices' ,3)" routerLink="./invoices" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 5.png" height="25">
            <span class="link-title">Invoices</span>
          </a>
        </li>
        <li class="nav-item testResult 4">
          <a (click)="selectLink('testResult' ,4)" routerLink="./testResult" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 6.png" height="25">
            <span class="link-title">Test Results</span>
          </a>
        </li>
        <li class="nav-item observations 5">
          <a (click)="selectLink('observations',5)" routerLink="./observations" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 7.png" height="25">
            <span class="link-title">Observations</span>
          </a>
        </li>

        <li [ngClass]="!prescriptionsPatient ? 'd-none' : 'nav-item prescript 6'">
          <a (click)="selectLink('prescript',6)" routerLink="./prescript" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 8.png" height="25">
            <span class="link-title">Prescriptions</span>
          </a>
        </li>
        <li class="nav-item digiImage 7">
          <a (click)="selectLink('digiImage', 7)" routerLink="./digiImage" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 9.png" height="25">
            <span class="link-title">Digital Imagery</span>
          </a>
        </li>
        <li [ngClass]="!documentsPatient ? 'd-none' : 'nav-item docs 8'">
          <a (click)="selectLink('docs' ,8)" routerLink="./docs" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 10.png" height="25">
            <span class="link-title">Documents</span>
          </a>
        </li>
        <li class="nav-item consents 9">
          <a (click)="selectLink('consents' ,9)" routerLink="./consents" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 10.png" height="25">
            <span class="link-title">Consent for treatment</span>
          </a>
        </li>
        <li class="nav-item onlineconsult 10">
          <a (click)="selectLink('onlineconsult',10)" routerLink="./onlineconsult" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 10.png" height="25">
            <span class="link-title">Online Consultation</span>
          </a>
        </li>
        <li class="nav-item ChatHistory 11">
          <a (click)="selectLink('ChatHistory', 11)" routerLink="./ChatHistory" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 10.png" height="25">
            <span class="link-title">Chat History</span>
          </a>
        </li>
        <li class="nav-item sick 12">
          <a href="/sick" (click)="selectLink('sick' ,12)" routerLink="./sick" skipLocationChange class="nav-link">
            <img src="../../../assets/images/Asset 3.png" height="25">
            <span class="link-title">Sick Notes</span>
          </a>
        </li>
        <li [ngClass]="!appointmentsPatient ? 'd-none' : 'nav-item'">
          <a class="nav-link Reception 20" (click)="selectNavLink('Reception' ,20)" skipLocationChange
            routerLink="./root" id="" role="button" aria-haspopup="true" aria-expanded="false">
            <img src="../../../assets/images/Recept.jpg" height="25">
            <span class="link-title">Reception </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>

  <!-- partial -->
  <!-- partial:../../partials/_navbar.html -->
  <nav class="navbar">
    <a href="#" class="sidebar-toggler">
      <i class="fa fa-bars"></i>
    </a>
    <div class="navbar-content">

      <ul class="navbar-nav ">
        <li class="nav-item dropdown nav-profile ">
          <a class=" btn-profile dropdown-toggle" id="profileDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{UserName}}
          </a>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-header d-flex flex-column align-items-center">
              <!-- <div class="figure mb-3">
                <img src="../../../assets/images/doctor.jpg" alt="">
              </div> -->
              <div class="info text-center">
                <p class="name font-weight-bold mb-0">{{fullName}}</p>
                <p class="email text-muted mb-3">{{EmailProfile}}</p>
              </div>
            </div>
            <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                <li class="nav-item  ">
                  <a class="nav-link home  17" (click)="selectNavLink('home' ,17)" id="profileDropdown" routerLink=""
                    role="button" aria-haspopup="true" aria-expanded="false">
                    Home
                  </a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link Clnician  18" (click)="selectNavLink('Clnician' ,18)" routerLink="./DeptClinic"
                    skipLocationChange id="profileDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    Clinician
                  </a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link dropdown-toggle howitWork 19" (click)="selectNavLink('howitWork' ,19)"
                    routerLink="./howitWork" skipLocationChange id="profileDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">
                    How it work
                  </a>
                </li>
                <!-- <li class="nav-item dropdown nav-profile  ">
                  <a  class="nav-link dropdown-toggle root Reception 20" (click)="selectNavLink('Reception' ,20)" routerLink="./root" id="profileDropdown" role="button"
                  skipLocationChange   aria-haspopup="true" aria-expanded="false">
                    Reception
                  </a>
                </li> -->
                <li class="nav-item ">
                  <a (click)="LogOut()" class="nav-link">
                    <i data-feather="log-out"></i>
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <!-- partial -->



  <!-- partial:../../partials/_footer.html -->

  <!-- partial -->


</div>