
 <app-header [ngClass]="{'d-none':Istoken}"></app-header>
<div  [ngClass]="{'main-wrapper':Istoken}">
    <div [ngClass]="{'page-wrapper':Istoken}">
        <div [ngClass]="{'page-content':Istoken}">
            <div [ngClass]="{'row':Istoken}">
                <div [ngClass]="{'col-md-12 grid-margin stretch-card':Istoken}">
                    <div [ngClass]="{'card':Istoken}">
                        <div id="demo" class="carousel slide" data-ride="carousel">
                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="bradcam_area breadcam_bg bradcam_overlay">
                                        <div class="container containerpadding">
                                            <div class="row" style="justify-content: center; margin-top: 45px;">
                                                <p class="hcolor"> <span id="mastheadTitle">See a clinician</span></p>

                                            </div>

                                            <!-- <div class="row" *ngIf="!Istoken" style="justify-content: center;">
                        <button class="btn-custom" (click)="login()">Login</button>&nbsp;&nbsp;&nbsp;
                        <button class="btn-custom" routerLink="./contactus" skipLocationChange>Register</button>
                    </div>

                    <div class="row" *ngIf="Istoken" style="justify-content: center;">
                        <button class="btn-book" routerLink="./bookAppointment" skipLocationChange>Book an Appointment</button>
                    </div> -->

                                            <div class="whiteback" *ngIf="!Istoken">
                                                <div class="row" *ngIf="!Istoken" style="justify-content: center;">
                                                    <button class="btn-custom12" (click)="login()">OXGP
                                                        Login</button>&nbsp;&nbsp;&nbsp;
                                                    <button class="btn-custom12" routerLink="./contactus"
                                                        skipLocationChange>Register</button>&nbsp;&nbsp;&nbsp;
                                                    <img style="cursor: pointer;" width="300px" height="60px"
                                                        src="../../../assets/images/MicrosoftTeams-image (1).png">
                                                </div>
                                            </div>
                                            <div class="row" style="justify-content: center;">
                                                <div class="">
                                                    <div id="typedElement"><span></span></div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="headingsection">
                            <div id="myCarousel" class="carousel slide" data-interval="3000" data-ride="carousel">
                                <div class="carousel-inner">
                                    <h1 style="color: #323D86;font-family: 'Yantramanav', sans-serif; text-align: center;"
                                        class="margin">Who will you meet
                                    </h1>
                                    <div class="carousel-item" *ngFor="let item of slides; index as i;first as isFirst"
                                        [ngClass]="{active:isFirst}">

                                        <div class="row">
                                            <div class="col-lg-3 col-xs-12 col-sm-3 col-md-3 "></div>
                                            <div class="col-lg-3 col-xs-12 col-sm-3 col-md-3"
                                                *ngFor="let doctors of item">
                                                <div class="card_test">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-4 cl-xs-12 col-lg-3">
                                                                <span *ngIf="doctors.ProfilePhoto != null">
                                                                    <img src="data:image/jpeg;base64,{{doctors.ProfilePhoto}}"
                                                                        class="img-fluid rounded-circle  img-thumbnail "
                                                                        [routerLink]="['/doctors', doctors.Id ]"
                                                                        width="150" height="150" />
                                                                </span>

                                                                <span *ngIf="doctors.ProfilePhoto == null">
                                                                    <img src="{{DefaultMainImage}}"
                                                                        class="img-fluid rounded-circle  img-thumbnail "
                                                                        [routerLink]="['/doctors', doctors.Id ]"
                                                                        width="150" height="150" />
                                                                </span>

                                                            </div>
                                                            <div class="col-md-4">
                                                                <p class="float-left name">
                                                                    {{doctors.JobTitle}}
                                                                </p>
                                                                <div class="clearfix"></div>

                                                                <p class="name1 a">{{doctors.About}}</p>
                                                                <div class="displayname">
                                                                    <tr class="name2">{{doctors.DisplayName}}</tr>
                                                                    

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- Carousel controls -->
                                <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#myCarousel" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                        <br>
                        <div class="container_padding"
                            style="background-color: #F0F3F8;border-bottom: 1px solid #ADC1D0;">
                            <h1 class="what_we_treat">What we treat</h1>
                            <div class="row row_margin" style="justify-content: center;">
                                <div class="col-lg-1 col-md-1"></div>
                                <div class="col-lg-10 col-md-10 row">
                                    <div class="col-lg-2 col-md-3 col-xs-3 col-sm-3 cardkb" *ngFor="let x of data">
                                        <img src="{{x.bcrm_imageurl}}" height="80" alt="Card image cap"
                                            [routerLink]="['/kbarticleDetails', x.Id ]" skipLocationChange>
                                        <p class="kbtitle"><a class="tittle" style="font-family: sans-serif;"
                                                [routerLink]="['/kbarticleDetails', x.Id ]"
                                                skipLocationChange>{{x.title}}</a></p>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-1"></div>
                            </div>
                        </div>
                        <header class="hero-text">

                            <div class="hero" data-arrows="true" data-autoplay="true">
                                <!--.hero-slide-->

                                <div class="hero-slide">
                                    <img alt="Mars Image" class="img-responsive cover"
                                        src="../../assets/images/banner1.png">

                                    <div class="header-content text-white position-absolute slide-content">
                                        <div class="header-content">
                                            <h1 class="hcolor fadeheading">What people say</h1>
                                        </div>
                                        <p class="" class="fadeanimation container">I wanted a second opinion before
                                            agreeing to surgery. I used the OX. platform to find an experienced
                                            specialist and from the comfort of my own home talked through the various
                                            options with him. It gave me much more confidence that I was
                                            making the right decision.</p>

                                    </div>
                                </div>
                                <!--.hero-slide-->

                                <div class="hero-slide">
                                    <img alt="Mars Image" class="img-responsive cover"
                                        src="../../assets/images/banner1.png">
                                    <div class="header-content text-white position-absolute slide-content">
                                        <div class="header-content">
                                            <h1 class="hcolor fadeheading">What people say</h1>
                                        </div>
                                        <p class="" class="fadeanimation container">I know it sounds silly, but having
                                            my medical data online in one place, accessible to a range of doctors is
                                            very reassuring to me. It drives me nuts that GP’s, hospitals and clinics
                                            cant access my medical history,so having it all securely
                                            online in one place, seems like a responsible approach as I start to get
                                            older.</p>

                                    </div>
                                </div>
                                <!--.hero-slide-->

                                <div class="hero-slide">
                                    <img alt="Mars Image" class="img-responsive cover"
                                        src="../../assets/images/banner1.png">
                                    <div class="header-content text-white position-absolute slide-content">
                                        <div class="header-content">
                                            <h1 class="hcolor fadeheading">What people say</h1>
                                        </div>
                                        <p class="" class="fadeanimation container">I travel quite a lot. I do my
                                            banking and my pension and investments online, and now I’ve got my
                                            healthcare advice online, it’s liberating to know that I can access my
                                            healthcare provider from wherever I am in the world.</p>
                                    </div>
                                </div>
                            </div>
                            <!--.hero-->
                        </header>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <br> -->