<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Documents</h3>
                    <p><a href="index.html">Home /</a>Documents</p>
                </div>
            </div>
        </div>
    </div>

</div>
<br>
<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <div class="card">
            <div class="card-body">
                <div class="list-group">
                    <a class="list-group-item active" style="color: white;font-size:20px;">Files <span
                            style="float:right;cursor:pointer;">
                            <button type="button" class="btn btn-info btn-sm" (click)="AddFolder()">Create New
                                Folder</button>
                        </span></a>
                    <ol class="breadcrumb">
                        <!-- <li class="breadcrumb-item active" *ngFor="let p of path ;let indexOfelement=index;" (click)="back(p)"><a>{{p}}</a></li> -->
                        <li class="breadcrumb-item active" *ngFor="let p of path ;let indexOfelement=index;"
                            (click)="back(p)">
                            <span *ngIf="indexOfelement==0; else elseTemplate">
                                <a>Home</a>
                            </span>
                            <ng-template #elseTemplate>
                                <a>{{p}}</a>
                            </ng-template>
                        </li>
                    </ol>
                    <form class="navbar-form" autocomplete="off">
                        <span class="bmd-form-group">
                            <div class="input-group no-border">
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    placeholder="Global Search For All Documents">
                            </div>
                        </span>
                    </form>
                    <hr />
                    <div class="row" style="padding: 1%;">
                        <div class="col-md-7">
                            <div class="row" style="background-color:#8fc9f9;color:white;">
                                <div class="col-md-6">Name</div>
                                <div class="col-md-3">Modified Date</div>
                            </div>
                            <div class="row" *ngFor="let tdata of tableData['children'] | filter:searchText">
                                <div class="col-md-6" (click)="DownloadIntoLocal(tdata)" style="cursor:pointer;">
                                    <!--ng-click="DownloadFile(tdata)"-->
                                    <i class="fa fa-{{tdata.type}}"></i> {{tdata.label}}
                                </div>
                                <div class="col-md-3">
                                    {{tdata.date | date:'medium'}}
                                </div>
                                <!-- <div class="col-sm-1">
                                    <i class="fa fa-trash" style="cursor:pointer;" aria-hidden="true" ng-click="Remove(tdata)"></i>
                                </div> -->
                            </div>
                            <!--<div class="col-md-1"></div>-->
                        </div>
                        <div class="col-md-5">
                            <p class="text-center">Upload Document</p>
                            <div class="text-center">
                                <input type="file" (change)="onUploadFile($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
    <div class="col-md-1"></div>
</div><br />
<div id="push"></div>
<div class="modal fade" id="W2P1" role="dialog">
    <form class="form" #it="ngForm" (ngSubmit)="onUploadFolder(it)" #f="ngForm" autocomplete="off">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Information</h5>
                    <!-- modal header -->
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        ×
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-group">
                        <label class="control-label">Folder Name::</label>
                        <div class="">
                            <input type="text" name="name" class="form-control" ngModel required #name="ngModel"
                                placeholder="Enter name" id="foldername">
                            <!-- <input type="text" class="form-control"  name="name" ngModel="allergy.name" #name="ngModel" placeholder="Enter name"> -->
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <input type="submit" class="btn btn-primary" value="Submit"> <button type="button"
                        class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </form>
</div>