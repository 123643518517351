<!-- bcrm_appointmentemrno********bcrm_appointmentno -->

<!-- <div class="col-md-6" >
    <select class="form-control" style="border-radius:0px !important;" name="bcrm_patientguardianvalueAll" [(ngModel)]="bcrm_patientguardianvalueAll" (change)="ChecktheValueofProxyandFilterdata($event)">
        <option value="" disabled>Select Guardian / Proxy</option>
        <option value={{userMainId}}>{{userMainname}} (Me)</option>
        <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
        </option>
      </select>
</div><br> -->


<form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off">


    <div class="row">
        <div class="col-6 col-md-2">
            <div class="form-group bmd-form-group">
                <label class="bmd-label-floating">Start Date</label>
                <input class='form-control round' type="date" id="ToDate" name="toStartDate" ngModel
                    #toStartDate="ngModel" #name="ngModel" [(ngModel)]="ToDate">
            </div>
        </div>
        <div class="col-6 col-md-2">
            <div class="form-group bmd-form-group">
                <label class="bmd-label-floating">End Date</label>
                <input class='form-control round' type="date" id="FromDate" name="fromEndDate" ngModel
                    #fromEndDate="ngModel" #name="ngModel" [(ngModel)]="FromDate">
            </div>
        </div>
        <div class="col-6 col-md-2">
            <div class="form-group bmd-form-group">
                <label class="bmd-label-floating">&nbsp;</label><br>
                <button class='btn btn-primary round-btn'><i class="fa fa-search" aria-hidden="true"></i></button>&nbsp;
                <button class='btn btn-primary round-btn' (click)="ReloadComponent()"><i class="fa fa-refresh"
                        aria-hidden="true"></i></button>
            </div>

        </div>

        <div class="col-6 col-md-6">
            <!-- Small button groups (default and split) -->
            <div class="form-group bmd-form-group pull-right">
                <label class="bmd-label-floating">&nbsp;</label><br>
                <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Create Request For
                </button>
                <div class="border dropdown-menu">
                    <a class="dropdown-item" routerLink="../reuestAptNew" skipLocationChange>Lobby Request</a>
                    <a class="dropdown-item" routerLink="../bookNewApt" skipLocationChange>Book Appointment</a>

                </div>
            </div>
        </div>
    </div>
</form>
<div class="row" style="margin-top: 1%;">
    <div class="col-12 col-md-12">
        <div class="">
            <div class="">
                <div class="list-group">
                    <a class="list-group-item active" style="color: white;">Appointment</a>
                    <span id="allappointments">
                        <span *ngFor="let appointment of allappointments;let i = index;">
                            <a class="list-group-item list-Item-Details">
                                <table style="width: 100%;">
                                    <tr>
                                        <td style="width:10%"> <i class="fa fa-calendar"></i>
                                            {{appointment.StartTime | date:'dd-MMM-yyyy'}}

                                        </td>
                                        <td style="width:10%"> <i class="fa fa-clock-o"></i>
                                            {{appointment.StartTime | date:'HH:mm'}}
                                            <!-- {{appointment.EndTime | date:'HH:mm'}} -->
                                        </td>



                                        <td style="width:25%">
                                            <i *ngIf="appointment.bcrm_appointmenttype!=102" class="fa fa-user-md"></i>
                                            <i *ngIf="appointment.bcrm_appointmenttype==102" class="fa fa-users"></i>
                                            {{appointment.doctor_name}},
                                            {{appointment.ServiceName}}, {{appointment.bcrm_appointmentno}}
                                            <!-- <span
                                                class="label lable-status-{{appointment.bcrm_doctorstatusformatted}} pull-right">{{appointment.bcrm_doctorstatusformatted}}</span> -->
                                        </td>

                                        <td style="width:20%"><i class="fa fa-id-card" aria-hidden="true"></i>
                                            {{appointment.subject}}
                                        </td>
                                        <td style="width:5%">
                                            <span
                                                class="label lable-status-{{appointment.bcrm_doctorstatusformatted}} pull-right">{{appointment.bcrm_doctorstatusformatted}}</span>
                                        </td>
                                        <!-- <td>
                                                                      Guardian/Proxy Name :- {{appointment.bcrm_patientguardianName}}

                                                                  </td> -->

                                        <td class="pull-right">
                                            <span *ngIf="appointment.bcrm_doctorstatus==2">
                                                <a (click)="StartMeeting(appointment)" title="Join virtual consultation" class="btn btn-primary">
                                                    <i class="fa fa-headphones"></i>Join
                                                </a>
                                                <a (click)="StartACSChat(appointment)" title="Join virtual consultation" class="btn btn-primary">
                                                   ACS Chat
                                                </a>
                                            </span>
                                            <a *ngIf="appointment.bcrm_appointmenttype!=102" title="Messages"
                                                (click)="openSendSMS(appointment)" style="color: white;"
                                                class="btn btn-primary"><i class="fa fa-comments"
                                                    aria-hidden="true"></i>
                                            </a>
                                            <a data-toggle="collapse" href="#collapseOneAppointment{{i+1}}" title="Expand/Collapse"
                                                class="btn btn-primary fa-btn"><i class="fa fa-arrow-circle-down"></i>
                                                </a>

                                        </td>
                                    </tr>
                                </table>
                            </a>
                            <div id="collapseOneAppointment{{i+1}}" class="collapse in">
                                <div class="card">
                                    <div class="card-body">
                                        <div id='external-events' class='external-events'>
                                            <div class="row">
                                                <div class="col-6 ">
                                                    <th class=""> Appointment No &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"> &nbsp;{{appointment.bcrm_appointmentno}}</td>
                                                </div>
                                                <div class="col-6 ">
                                                    <th class=""> Start Time &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"> &nbsp;{{appointment.StartTime| date:'dd-MMM-yyyy | HH:mm aa'}}
                                                    </td>
                                                </div>
                                                <div class="col-6 ">
                                                    <th class=""> Subject &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"> &nbsp;{{appointment.subject}}</td>
                                                </div>
                                                <div class="col-6 ">
                                                    <th class=""> Service &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"> &nbsp;{{appointment.ServiceName}}</td>
                                                </div>


                                            </div>
                                            <div class="row"
                                                *ngIf="appointment.actualstart != null && appointment.actualstart != '0001-01-01T00:00:00'">

                                                <div class="col-6 ">
                                                    <th class=""> Actual Start &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"> &nbsp;{{appointment.actualstart | date:'dd-MMM-yyyy | HH:mm aa'}}
                                                    </td>
                                                </div>
                                                <div class="col-6 ">
                                                    <th class=""> Actual End &nbsp;<span class="pull-right"> :
                                                            &nbsp;</span> </th>
                                                    <td class=" ml-3"
                                                        *ngIf="appointment.actualend != '0001-01-01T00:00:00'">
                                                        &nbsp;{{appointment.actualend | date:'dd-MMM-yyyy | HH:mm aa'}}</td>
                                                </div>
                                            </div>
                                            <!-- <table class="table-sm">
                                        <tr>
                                            <th scope="col" class="col-static" width="30%">Appointment No <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">{{appointment.bcrm_appointmentno}}
                                            </td>
                                            <th scope="col" class="col-static" width="10%">Start Time <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">{{appointment.StartTime | date:'medium'}}
                                            </td>

                                        </tr>
                                        <tr *ngIf="appointment.actualstart != null && appointment.actualstart != '0001-01-01T00:00:00'">
                                            <th scope="col" class="col-static">Actual Start <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">{{appointment.actualstart | date:'short'}}
                                            </td>

                                            <th scope="col" class="col-static">Actual End <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">
                                                <div *ngIf="appointment.actualend != '0001-01-01T00:00:00'">
                                                    {{appointment.actualend | date:'short'}}
                                                </div>

                                            </td>
                                        </tr>

                                        <tr>
                                            <th scope="col" class="col-static">Subject <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">{{appointment.subject}}</td>

                                            <th scope="col" class="col-static">Service <span class="pull-right">:</span></th>
                                            <td class="col-dynamic">{{appointment.ServiceName}}</td>
                                        </tr>

                                    </table> -->

                                            
                                            <div class="row ">
                                                <div class="col-sm-6 col-md-6 col-xl-6 mb-2"
                                                    *ngFor="let qna of appointment.bcrm_customerresponse">
                                                    <th class="col-static"> {{qna.question}} : <a class="text-muted"
                                                            style="font-weight: 400;"> {{qna.answer}}</a></th>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </span>
                </div>
                <br>
                <div class="list-group">
                    <a class="list-group-item active" style="color: white;">Request Appointment</a>
                    <span id="Lobbydata">
                        <span *ngFor="let lobby of Lobbydata | filter:'935000002';let i = index;">
                            <a class="list-group-item list-Item-Details">
                                <table style="width: 100%;">
                                    <tr>
                                        <td style="width:10%"> <i class="fa fa-calendar"></i>
                                            {{lobby.createdon | date:'dd-MMM-yyyy'}}
                                        </td>
                                        <td style="width:10%">
                                            <i *ngIf="lobby.bcrm_appointmenttype!=102" class="fa fa-user-md"></i>
                                            <i *ngIf="lobby.bcrm_appointmenttype==102" class="fa fa-users"></i> <span
                                                *ngIf="lobby.Doctorfullname==''"> Any
                                                One</span> {{lobby.staffName}}
                                        </td>
                                        <td style="width:50%"><i class="fa fa-sticky-note-o"></i> {{lobby.subject}} ,
                                            {{lobby.serviceName}}
                                            <span
                                                class="label lable-status-{{lobby.participantStatusFormatted}} pull-right">{{lobby.participantStatusFormatted}}</span>
                                        </td>
                                        <td class="pull-right">
                                            <a data-toggle="collapse" href="#collapseOnelobby{{i+1}}"
                                                class="btn btn-primary fa-btn"><i class="fa fa-arrow-circle-down"></i>
                                                </a>
                                        </td>
                                    </tr>
                                </table>
                            </a>
                            <div id="collapseOnelobby{{i+1}}" class="collapse in">
                                <div class="card">
                                    <div class="card-body">
                                        <div id='external-events' class='external-events'>
                                            <table class="table-sm">
                                                <tr>
                                                    <th scope="col" class="col-static">Appointment No. <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic">{{lobby.appointmentNumber}}
                                                    </td>
                                                    <th scope="col" class="col-static">Start Time <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic"
                                                        *ngIf="lobby.startDate != '0001-01-01T00:00:00'">
                                                        {{lobby.startDate  | date:'dd-MMM-yyyy | HH:mm aa'}}
                                                </tr>
                                                <tr>
                                                    <th scope="col" class="col-static">Notes <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic">{{lobby.comments}}</td>
                                                    <th scope="col" class="col-static">Funded <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic">{{lobby.funded}}</td>
                                                </tr>
                                                <tr
                                                    *ngIf="lobby.actualStart != null && lobby.actualStart != '0001-01-01T00:00:00'">
                                                    <th scope="col" class="col-static">Actual Start <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic">{{lobby.actualStart | date:'dd-MMM-yyyy | HH:mm aa'}}
                                                    </td>

                                                    <th scope="col" class="col-static">Actual End <span
                                                            class="pull-right">:</span></th>
                                                    <td class="col-dynamic">
                                                        <div *ngIf="lobby.actualEnd != '0001-01-01T00:00:00'">
                                                            {{lobby.actualend | date:'dd-MMM-yyyy | HH:mm aa'}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="myModalcomment" tabindex="-1" role="dialog" aria-labelledby="comment" data-keyboard="false"
    data-backdrop="static" aria-hidden="true">
    <form class="form" #SMS="ngForm" (ngSubmit)="sendMessage(SMS)" #f="ngForm" autocomplete="off">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background-color:#2196f3de !important ">
                    <h5 class="modal-title" style="color: white;">Message Box</h5>
                    <!-- modal header -->
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" style="color: white;">
                        ×
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container custome_msg-scroll">
                        <div class="messaging">
                            <div class="inbox_msg">

                                <div class="mesgs">
                                    <div class="scroll" *ngFor="let Msg of msg">
                                        <div class="msg_history">
                                            <div class="outgoing_msg" *ngIf="Msg.bcrm_smsfrom == 2">
                                                <div class="sent_msg">
                                                    <p>{{Msg.bcrm_message}}</p>
                                                    <span class="time_date">
                                                        {{Msg.createdOn | date:'dd-MMM-yyyy | HH:mm aa'}}</span>
                                                </div>
                                            </div>
                                            <div class="incoming_msg" *ngIf="Msg.bcrm_smsfrom == 1">
                                                <!-- <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div> -->
                                                <div class="received_msg">
                                                    <div class="received_withd_msg">
                                                        <p>{{Msg.bcrm_message}}</p>
                                                        <span class="time_date">
                                                            {{Msg.createdOn | date:'dd-MMM-yyyy | HH:mm aa'}} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="send-wrap">
                        <input class="form-control send-message" id="bcrm_message" name="bcrm_message" ngModel required
                            #bcrm_message="ngModel" rows="3" placeholder="Type a message...">
                        <!-- <button class="msg_send_btn" type="submit"><i class="fa fa-paper-plane-o"
                                aria-hidden="true"></i></button> -->
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-6 fontsize12">
                            <!-- <input type="checkbox" id="IsSendEmailForGroupMsg">
                            <label for="attachment">Send as Email too?</label> -->

                        </div>
                        <div class="col-md-6  col-sm-6 fontsize12">
                            <div class="pull-right">
                                <label for="attachment">Add attachment</label>
                                <button class="atttachFile" type="button"
                                    (click)="openFileExplorer('openFileForGroupMsg')">
                                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                                </button>
                                <input id="openFileForGroupMsg" type="file" nv-file-select=""
                                    (change)="onUploadFile($event)" uploader="uploader" multiple
                                    style="display:none" /><br />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="RebrandUrlChecklength">
                        <label for="attachment">File attachment:</label>
                        <ul style="list-style-type:disc">
                            <li *ngFor="let item of RebrandUrl">
                                <div class="row">
                                    <div class="col-4"> {{item}} </div>
                                    <div class="col-1" style="margin-top:4px"><i class="fa fa-window-close pull-right"
                                            style="color:red" (click)="removeLink(item)" aria-hidden="true"></i></div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="card-footer">

                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-sm btn-PopUpPrimary" type="button"
                                    (click)="ClosetheMsgPopuponBack()">Back</button>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-sm btn-PopUpPrimary" type="button"
                                    (click)="getMessages(SendMsgObj.appointmentId)"> Refresh</button>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-sm btn-PopUpPrimary" type="submit"> Send </button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </form>
    <!-- /.modal-dialog -->
</div>