import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from 'src/app/ApiServices/Department/departments.service';
import { Router } from "@angular/router"
import { ToastrService } from 'ngx-toastr';
import axios from 'axios';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-deparments',
  templateUrl: './deparments.component.html',
  styleUrls: ['./deparments.component.css']
})

export class DeparmentsComponent implements OnInit {

  //------ variables  -----//
  allDeparment: any;
  allDoctors: any;
  departmentDetails: object;
  Token: string;
  allDeparments: boolean = true;
  searchText;
  searchDept;
  myImage;
  isLoggedIn: boolean = false;
  constructor(public departmentService: DepartmentsService,
    private router: Router,
    private toastr: ToastrService,
    private headerComponent: HeaderComponent) {
    this.isLoggedIn = headerComponent.loggedIn;
   
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-Doctor").addClass("active");
    /******End:Active Current Component Nav****** */
  }
  ngOnInit(): void {
    debugger; 
    axios.get(`${environment.apiUrl}/api/NoAuth/GetDepartments`).then(
          // //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    // /**************************************** */
      (response) => { 
        debugger;
        var result = response.data;
        this.allDeparment = result;
        var option = '';
        for (var i = 0; i < this.allDeparment.length; i++) {
             var optionvalue = this.allDeparment[i];
               option += "<option value='" + optionvalue.id + "'>" + optionvalue.name + "</option>";
          }
          $('.js-example-basic-single').html(option);
          $('.js-example-basic-single').select2();
        //var Data=this.allDeparment;
        /***********  Get All Doctores On load Of Page  ******/
        this.allDoctorsDataByDept(this.allDeparment[0]['Id']);
        // //*************Show / hide loader *******************/
        // $('#ftco-loader').removeClass('show');
        // /**************************************** */
      },
      (error) => {
        
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }
    );
    var localThis = this;
    $('#mySelect2').on('select2:select', function (e) {
      
          var Dept_Id = e.params.data.id;
          localThis.allDoctorsDataByDept(Dept_Id);
      });
    // /**Get All Departments And Show on the Side nav** */
    
  }
  /*Api to get all the Doctors On load  */
  allDoctorsData(): void {
    $(".fc-event").removeClass("Active");
    $(`#DefaultSelect`).addClass("Active");
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.departmentService.getDoctorsdata().subscribe((res) => {
debugger;

      this.allDoctors = res;
      this.searchText = '';
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      });
  }
  allDoctorsDataByDept(Dept_Id): void {
debugger
    this.allDoctors = [];
    $(".fc-event").removeClass("Active");
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    //checking by anjali//
    axios.get(`${environment.apiUrl}/api/NoAuth/GetMembers?Id=${Dept_Id}`).then(
      (response) => {
        
        var res = response.data;
        $(`#${Dept_Id}`).addClass("Active");
        
        this.allDoctors = res;
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
        this.backToAllDepartments();
      },
      (error) => {
        
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
   
      }
    );
  }
  backToAllDepartments(): void {  
    this.myImage = '';
    this.allDeparments = true;
  }
  getProfile(DoctorID: string, Image: string): void {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.departmentDetails = null;
    this.departmentService.getDepartmentsMembersProfile(DoctorID).subscribe((res) => {
      this.departmentDetails = res;
      this.allDeparments = false;
      this.myImage = Image;
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
    });
  }
}
