<!-- <section style="margin: 3% 0% 1% 0%;">
    <div class="row no-gutters" style="justify-content: center;">

        <div class="col-lg-9 col-md-12 heading-section3">
            <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Consent</h1>
        </div>
    </div>
</section> -->
<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <br>

                            <section id="ngx" class="container" *ngIf="proceduredata!=null">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                </div>
                                                <div class="col-md-6">

                                                    <label style="font-weight: bold; font-size: 96%;"> You are scheduled
                                                        for an upcoming {{proceduredata.description}}. Please review the
                                                        following
                                                        information: </label>
                                                </div>
                                                <div class="col-md-3">
                                                </div>

                                            </div>
                                            <aw-wizard #wizard navBarLayout="large-empty-symbols">
                                                <aw-wizard-step [stepTitle]="'Step 1'"
                                                    [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
                                                    <form id="msform" #personalForm="ngForm" class="editForm"
                                                        novalidate>
                                                        <h4 class="head text-center">Personal Information</h4>
                                                        <br />
                                                        <div class='row'>
                                                            <div class='col-12'>

                                                                <fieldset>
                                                                    <div class="table-responsive"
                                                                        style="overflow-x: hidden;">
                                                                        <table class="table">
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <tr>
                                                                                        <th>First Name</th>
                                                                                        <th>:</th>
                                                                                        <td>{{profile.firstname}}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>Email</th>
                                                                                        <th>:</th>
                                                                                        <td>{{profile.emailaddress1}}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>Phone</th>
                                                                                        <th>:</th>
                                                                                        <td>{{profile.mobilephone}}</td>
                                                                                    </tr>

                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <tr>
                                                                                        <th>Last Name</th>
                                                                                        <th>:</th>
                                                                                        <td>{{profile.lastname}}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>Birthday</th>
                                                                                        <th>:</th>
                                                                                        <td>{{profile.birthdate |
                                                                                            date:'dd-MM-yyyy'}} <b
                                                                                                style="font-weight:  600;">Age</b>:{{DOBInYear}}
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <th>Procedure/Date</th>
                                                                                        <th>:</th>
                                                                                        <td>{{proceduredata.performedStartDate
                                                                                            | date:'dd-MM-yyyy'}}</td>
                                                                                    </tr>

                                                                                </div>

                                                                            </div>


                                                                        </table>
                                                                    </div>
                                                                </fieldset>



                                                                <div class="row">
                                                                    <div class="col-md-1">
                                                                        <label class="switch"><input type="checkbox"
                                                                                id="exampleCheck1" name="abc"
                                                                                [(ngModel)]="firstStep">
                                                                            <div class="slider round"><span
                                                                                    class="on">Yes</span><span
                                                                                    class="off">No</span></div>
                                                                        </label>
                                                                    </div>

                                                                    <div class="col-md-11">
                                                                        <label> Please confirm the above information is
                                                                            correct.</label>
                                                                    </div>
                                                                </div>
                                                                <!-- </div> -->
                                                                <div class="row parantRow" *ngIf="firstStep==false">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            {{proceduredata.pleaseEnterWhatIsNotCorrect}}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="form-group text-center">
                                                                    <button type="button" uiSref="work" id="btn1tab"
                                                                        class="btn  btn-info btn-raised center"
                                                                        awNextStep
                                                                        [disabled]="(firstStep==false && bcrm_pleaseenterwhatisnotcorrect==null)"
                                                                        (click)="savequestion()">
                                                                        Next
                                                                        <span style="margin-left:10px;">
                                                                            <i class="ft-chevron-right"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </aw-wizard-step>


                                                <aw-wizard-step [stepTitle]="'Step 2'"
                                                    [navigationSymbol]="{ symbol: '&#xf0b1;', fontFamily: 'FontAwesome' }">
                                                    <form #personalForm="ngForm" class="editForm" novalidate>
                                                        <h4 class="head text-center">Video</h4>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                            </div>
                                                            <div class="col-md-6">

                                                                <label style="font-weight: bold;">Please watch the
                                                                    following video and review the
                                                                    information relating to your
                                                                    {{proceduredata.description}}</label>
                                                            </div>
                                                            <div class="col-md-3">
                                                            </div>

                                                        </div>

                                                        <br />
                                                        <div class='row'>
                                                            <div class='col-12'>

                                                                <div class="row">
                                                                    <iframe [src]="videourl" width="100%" height="400px"
                                                                        frameborder="0"> </iframe>
                                                                </div><br>

                                                                <div class="row">
                                                                    <div class="col-md-1">
                                                                        <label class="switch"><input type="checkbox"
                                                                                id="exampleCheck3" name="exampleCheck3"
                                                                                [(ngModel)]="stepVideo">
                                                                            <div class="slider round"><span
                                                                                    class="on">Yes</span><span
                                                                                    class="off">No</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label> Have you watched the video and reviewed
                                                                            the information on your
                                                                            {{proceduredata.description}}?</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-1">
                                                                        <label class="switch"><input type="checkbox"
                                                                                id="consentonline" name="consnetonline"
                                                                                (click)="showMessage()"
                                                                                [(ngModel)]="IsConsentOnline">
                                                                            <div class="slider round"><span
                                                                                    class="on">Yes</span><span
                                                                                    class="off">No</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label>Are you happy to consent online?
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-1">
                                                                        <label class="switch"><input type="checkbox"
                                                                                id="poupdataopen" name="poupdataopen"
                                                                                (click)="openDoc()"
                                                                                [(ngModel)]="stepVideodata">
                                                                            <div class="slider round"><span
                                                                                    class="on">Yes</span><span
                                                                                    class="off">No</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label>Would you like more information about
                                                                            your {{proceduredata.description}}?</label>
                                                                    </div>
                                                                </div>

                                                                <div class="row" id="docList"
                                                                    *ngIf="additionalvalue != undefined && stepVideodata">
                                                                    <ul>
                                                                        <li *ngFor="let data of urlList"><a
                                                                                href="{{data.url}}" target="_blank"
                                                                                style="cursor: pointer;text-decoration: underline;">{{data.name}}</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>


                                                                <div class="form-group text-center space-20">
                                                                    <h4>Additional Information</h4>
                                                                    <button uiSref="personal" type="button"
                                                                        class="btn btn-raised btn-secondary mr-1"
                                                                        awPreviousStep>
                                                                        <span style="margin-right:10px;">
                                                                            <i class="ft-chevron-left"></i>
                                                                        </span> Previous</button>
                                                                    <button uiSref="address" type="button"
                                                                        class="btn btn-raised btn-info center"
                                                                        [disabled]="IsConsentOnline==false" id="btn3tab"
                                                                        awNextStep>
                                                                        Next
                                                                        <span style="margin-left:10px;">
                                                                            <i class="ft-chevron-right"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </aw-wizard-step>


                                                <aw-wizard-step [stepTitle]="'Step 3'"
                                                    [navigationSymbol]="{ symbol: '&#xf0ac;', fontFamily: 'FontAwesome' }">


                                                    <form #addressForm="ngForm" class="editForm" novalidate>
                                                        <h4 class="head text-center">Consent Form Page</h4>
                                                        <br />
                                                        <fieldset>
                                                            <div class="form-card">

                                                                <div
                                                                    *ngFor="let data of consentquestion  let i = index;">
                                                                    <div *ngIf="data.type==1">
                                                                        <label for="Questions">{{i+1}}.
                                                                            {{data.name}}</label>
                                                                        <input class="form-control" ngModel
                                                                            #data.bcrm_answer="ngModel"
                                                                            [(ngModel)]="data.bcrm_answer" required
                                                                            id="text" name="text" type="text"
                                                                            placeholder="Answer">
                                                                    </div>

                                                                    <div *ngIf="data.type==2">
                                                                        <label for="Questions">{{i+1}}.
                                                                            {{data.name}}</label>
                                                                        <input class="form-control input-md" ngModel
                                                                            #data.bcrm_answer="ngModel"
                                                                            [(ngModel)]="data.bcrm_answer" required
                                                                            id="date" name="date" type="date">
                                                                    </div>


                                                                    <div *ngIf="data.type==3">
                                                                        <label for="Questions">{{i+1}}.
                                                                            {{data.name}}</label><br>
                                                                        <label class="switch"><input type="checkbox"
                                                                                id="Questionsccheckbox"
                                                                                name="Questionsccheckbox"
                                                                                [(ngModel)]="data.bcrm_answer">
                                                                            <div class="slider round"><span
                                                                                    class="on">Yes</span><span
                                                                                    class="off">No</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div *ngIf="data.type==4">
                                                                        <label for="Questions">{{i+1}}.
                                                                            {{data.name}}</label>

                                                                    </div>
                                                                    <br>
                                                                </div>

                                                            </div>

                                                        </fieldset>
                                                        <br>
                                                        <div class='row'>
                                                            <div class='col-sm-12'>


                                                                <div class="form-group text-center">
                                                                    <button uiSref="work" type="button"
                                                                        class="btn btn-raised btn-secondary mr-1"
                                                                        awPreviousStep>
                                                                        <span style="margin-right:10px;">
                                                                            <i class="ft-chevron-left"></i>
                                                                        </span> Previous</button>
                                                                    <button uiSref="result" type="button"
                                                                        class="btn btn-raised btn-success center"
                                                                        awResetWizard (click)="savealldata()">
                                                                        Finish
                                                                        <span style="margin-left:10px;">
                                                                            <i class="ft-chevron-right"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>

                                                </aw-wizard-step>
                                            </aw-wizard>


                                        </div>
                                    </div>
                                </div>

                            </section><br>
                            <!-- The Modal -->
                            <div class="modal fade" id="myModal" *ngIf="stepVideodata==true">
                                <div class="modal-dialog modal-xl">
                                    <div class="modal-content">

                                        <!-- Modal Header -->
                                        <div class="modal-header">
                                            <h4 class="modal-title">Terms & Condition</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <ol>
                                                <li><strong>Our terms and conditions</strong></li>
                                            </ol>

                                            <ul>
                                                <li><strong>What they cover:</strong> These terms and conditions, the
                                                    Clinical Services Terms (see clause 4) below, the Privacy Policy
                                                    (see clause 3.2 below), the Cookie Policy (see clause 3.3 below)
                                                    and, where relevant, the Clinical Practice
                                                    Contract (see clause 3.4 below) (together, the
                                                    <strong><em>&ldquo;Terms&rdquo;</em></strong>) are the basis on
                                                    which we supply services to you. They also set out your rights and
                                                    responsibilities when using our website at OXvc.Health (the
                                                    <strong>&ldquo;Website&rdquo;</strong>).
                                                </li>
                                            </ul>

                                            <ul>
                                                <li><strong>Why you should read them:</strong> Please read the Terms
                                                    carefully as they form the basis of our legally binding contract
                                                    with you (see clause 3, below) once you start using our Website.
                                                    They tell you who we are, how we provide
                                                    services to you, how you and we may change or end our legal
                                                    relationship, what to do if there is a problem and other important
                                                    information. If you think there is an error in the Terms, please
                                                    contact us to discuss this.</li>
                                            </ul>
                                        </div>

                                        <!-- Modal footer -->
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"
                                                data-dismiss="modal">Close</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>