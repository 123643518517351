<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="profile-page tx-13">

                <div class="row profile-body">
                    <!-- left wrapper start -->
                    <div class="col-md-6">
                        <div class="row">
                            <div class=" d-md-block col-md-12 col-xl-12 left-wrapper">
                                <div class="card rounded">
                                    <div class="card-body row pt-3 pb-3">
                                        <div class="col-md-6">
                                            <div class="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                <div class="d-flex align-items-center hover-pointer">
                                                    <img class="img-xs rounded-circle" [src]="imgURL" alt="">
                                                    <div class="ml-2">
                                                        <p>{{profile?.firstname}}&nbsp;{{profile?.lastname}}</p>
                                                        <p class="text-muted">{{profile?.emailaddress1}}</p>
                                                        <!-- <p class="tx-11 text-muted">12 Mutual Friends</p> -->

                                                    </div>
                                                </div>
                                                <a class="dropdown-item d-flex float-right" class="nav-link"
                                                    routerLink="/PAtientDetailEdit"><i data-feather="edit-2"
                                                        class="icon-sm mr-2"></i> <span class="">Edit</span></a>
                                                <!-- <button class="btn btn-icon"><i data-feather="user-plus" data-toggle="tooltip" title="Connect"></i></button> -->
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    <label
                                                        class="tx-11 font-weight-bold mb-0 text-uppercase">{{'Profile.Phone'|translate}}:</label>
                                                    <p class="text-muted">{{profile?.mobilephone}}</p>
                                                </div>
                                                <div class="col-md-6">
                                                    <label
                                                        class="tx-11 font-weight-bold mb-0 text-uppercase">{{'Profile.NHSNumber'|translate}}:</label>
                                                    <p class="text-muted">{{profile?.bcrm_nhsnumber}}</p>
                                                </div>
                                            </div>
                                            <div class="row pt-1">
                                                <div class="col">
                                                    <label class="tx-11 font-weight-bold mb-0 text-uppercase">Date of
                                                        birth:</label>
                                                    <p class="text-muted">{{profile?.birthdate | date:'dd-MMM-yyyy'}}</p>
                                                </div>
                                                <div class="col">
                                                    <label
                                                        class="tx-11 font-weight-bold mb-0 text-uppercase">{{'Profile.gender'|translate}}:</label>
                                                    <p class="text-muted">{{profile?.gender}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <h6>Address:</h6>
                                            <hr>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <p><b>{{'Profile.street'|translate}} :</b>
                                                        {{profile?.address1_line1}} </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p><b>{{'Profile.city'|translate}} :</b>
                                                        {{profile?.address1_city}} </p>
                                                </div>
                                            </div>
                                            <div class="row pt-2">
                                                <div class="col-md-6">
                                                    <p><b>{{'Profile.country'|translate}} :</b>
                                                        {{profile?.address1_country}}</p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p><b>{{'Profile.state'|translate}} :</b>
                                                        {{profile?.address1_stateorprovince}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row pt-2">
                                                <div class="col-md-6">
                                                    <p><b>Emergency contact :</b>
                                                        {{profile?.emergencycontact}} </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p><b>{{'Profile.zip'|translate}} :</b>
                                                        {{profile?.address1_postalcode}} </p>
                                                </div>
                                            </div>
                                            <!-- <div class="row  mt-3">
                            <div class="col">
                                <p>{{'Profile.ABOUTUS'|translate}} :-
                                    {{profile?.bcrm_wheredidyouhearaboutus}} </p>
                            </div>
                        </div> -->

                                        </div>
                                     
                                        <div class="row pt-1">
                                            <div class="col-md-12 p-3">
                                                <hr class="mt-0">
                                                <h6> {{'Profile.OtherInfo.SOCIALHISTORY'|translate}}</h6>

                                                <div class="row p-1">
                                                    <div class="col-md-6 ">
                                                        <p>{{'Profile.occupation'|translate}}:
                                                            {{profile?.bcrm_occuption}} </p>
                                                    </div>
                                                    <div class="col-md-6  ">
                                                        <p>{{'Profile.OtherInfo.SmokingStatus'|translate}} :
                                                            {{profile?.bcrm_smokingstatus}} </p>
                                                    </div>
                                                    <div class="col-md-6 pt-1">
                                                        <p>{{'Profile.OtherInfo.StoppedSmoking'|translate}} :
                                                            {{profile?.bcrm_stoppedsmoking}} </p>
                                                    </div>
                                                    <div class="col-md-6 pt-1">
                                                        <p>
                                                            {{'Profile.OtherInfo.Alcoholintake'|translate}} :
                                                            {{profile?.bcrm_alcoholintake}} </p>
                                                    </div>
                                                    <div class="col-md-6 pt-1">
                                                        <p>{{'Profile.OtherInfo.StoppedDrinkingAlcohol'|translate}}:
                                                            {{profile?.bcrm_stoppeddrinkingalcohol}} </p>
                                                    </div>
                                                    <div class="col-md-6 pt-1">
                                                        <p>{{'Profile.OtherInfo.Otherdetails'|translate}} :
                                                            {{profile?.otherDetails}} </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- left wrapper end -->
                    <!-- middle wrapper start -->
                    <div class="col-md-6 col-xl-6 middle-wrapper">
                        <div class="row">
                            <div class="col-md-12 grid-margin">
                                <div class="card rounded">
                                    <div class="card-body pb-0">
                                        <div class="row p-2">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <h6>{{'Profile.OtherInfo.NEXTOFKIN'|translate}}</h6>
                                                </div><hr>
                                                <div class="row ">
                                                    <p>{{'Profile.firstName'|translate}} :
                                                        {{profile?.bcrm_kinsfirstname}}
                                                    </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.Surname'|translate}} :
                                                        {{profile?.bcrm_kinslastname}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.Relationship'|translate}} :
                                                        {{profile?.msemr_contact1relationship_name}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.Contacttelno'|translate}} :
                                                        {{profile?.telephone1}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.address'|translate}}: {{profile?.address2_line1}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.Postcode'|translate}}:-
                                                        {{profile?.address2_postalcode}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'PatnerInfo.emailAddress'|translate}}:-
                                                        {{profile?.emailaddress2}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <h6>{{'Profile.OtherInfo.CURRENTGPDETAILS'|translate}}</h6>
                                                </div><hr>
                                                <div class="row ">
                                                    <p>{{'Profile.GP.gpSurgeryName'|translate}}:
                                                        {{profile?.bcrm_gpsurgeryname}} </p>
                                                </div>
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.GPType'|translate}} :-
                                                        {{profile?.bcrm_gptype}}
                                                    </p>
                                                </div>
                                                <div class="row  pt-2">
                                                    <p>{{'Profile.OtherInfo.Town'|translate}}:
                                                        {{profile?.address3_city}}
                                                    </p>

                                                </div>
                                                <!-- <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.DoctorName'|translate}} :
                                                        {{profile?.bcrm_doctorsname}} </p>
                                                </div> -->
                                                <div class="row pt-2">
                                                    <p>{{'Profile.OtherInfo.Telno'|translate}} :
                                                        {{profile?.telephone3}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <!-- <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center"> -->
                                        <!-- <img class="img-xs rounded-circle" src="https://via.placeholder.com/37x37" alt="">													 -->
                                        <!-- <div class="ml-2">
                                                <p>Address :- </p>
                                                <p class="tx-11 text-muted">1900, Broadway New york, NY, United States 10023</p>
                                            </div> -->
                                        <br>
                           <br>
                                        <div class="row">
                                            <!-- <div class="col-md-6">
                                                <p>{{'Profile.ABOUTUS'|translate}} :-
                                                    {{profile?.bcrm_wheredidyouhearaboutus}} </p>
                                            </div> -->
                                        </div>

                                        <!-- <h5>{{'Profile.GDPR'|translate}}:</h5>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-6 pt-1">
                                                <div class="row">
                                                    <div class="col">{{'Profile.email'|translate}} :</div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="donotemail" [ngModel]="profile?.donotemail"
                                                                disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 pt-1">
                                                <div class="row">
                                                    <div class="col">
                                                        {{'Profile.FollowMail'|translate}} :
                                                    </div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="followemail" [ngModel]="profile?.followemail"
                                                                disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col">{{'Profile.Newsletter'|translate}}:</div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="donotbulkemail"
                                                                [ngModel]="profile?.donotbulkemail" disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col">{{'Profile.PhoneCalls'|translate}} :</div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="donotphone" [ngModel]="profile?.donotphone"
                                                                disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col">{{'Profile.Mails'|translate}} :</div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="donotpostalmail"
                                                                [ngModel]="profile?.donotpostalmail" disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col">{{'Profile.Faxes'|translate}} :</div>
                                                    <div class="col">
                                                        <label class="switch"><input type="checkbox" id="togBtn"
                                                                name="donotfax" [ngModel]="profile?.donotfax" disabled>
                                                            <div class="slider round">
                                                                <span class="on">{{'Common.Yes'|translate}}</span>
                                                                <span class="off">{{'Common.No'|translate}}</span>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-12 col-xl-12 middle-wrapper">
                        <div class="row">
                            <div class="col-md-8 grid-margin">
                                <div class="card rounded">
                                    <div class="card-header">
                                        <h5> {{'Profile.OtherInfo.followingconditions'|translate}}</h5>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_diabetes" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Diabetes'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_thyroiddisease" disabled
                                                    name="bcrm_diabetes">&nbsp;
                                                {{'Profile.OtherInfo.Thyroiddisease'|translate}}</div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_transientischaemicattack" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.IschaemicAttack'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_asthma" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Asthma'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_highbloodpressure" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Highbloodpressure'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_epilepsy" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Epilepsy'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_mentalillness" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Mentalillness'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_heartdisease" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Heartdisease'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_kidneydisease" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Kidneydisease'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_depression" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Depression'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_heartrhythmproblems" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Heartrhythmproblems'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_stroke" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Stroke'|translate}}
                                            </div>
                                            <div class="col-md-3 mt-1">&nbsp;<input type="checkbox"
                                                    [ngModel]="profile?.bcrm_cancer" disabled
                                                    name="bcrm_diabetes">&nbsp;{{'Profile.OtherInfo.Cancer'|translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid-margin">
                                <div class="card rounded">
                                    <div class="card-header">
                                        <div class="row p-2">
                                            <h6>
                                                {{'Profile.OtherInfo.surgicaloperations'|translate}}:
                                            </h6>
                                            <br>
                                            <textarea name="" disabled
                                                [ngModel]="profile?.bcrm_pleasegivedetailsofanyothersignificantill"
                                                class="form-control" id="" rows="4"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-xl-12 middle-wrapper">
                        <div class="row">
                            <div class="col-md-6 grid-margin">
                                <div class="card rounded">
                                    <div class="card-header">
                                     
                                        <div class="row p-2">
                                            <h6>
                                                {{'Profile.OtherInfo.yourimmediatefamily'|translate}}:
                                            </h6>
                                            <br>
                                            <textarea name="" disabled
                                                [ngModel]="profile?.bcrm_pleasegivedetailsofanymedicalconditionswi"
                                                class="form-control" id="" rows="5"></textarea>
                                        </div>
                                        <div class="row p-2">
                                            <h6>
                                                {{'Profile.OtherInfo.anymedications'|translate}}:
                                            </h6>
                                            <br>
                                            <textarea name="" disabled class="form-control" id="" rows="5"></textarea>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 grid-margin">
                                <div class="card rounded">
                                    <div class="card-header">
                                        <div class="row">
                                            <h6> {{'Profile.OtherInfo.PATIENTCONSENOPTIONS'|translate}}</h6>
                                            <!-- <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iunderstandthatdoctornowisaprivategpservi"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.consultationsandtreatments'|translate}}</div>
                                            <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iunderstandthatacancellationfeewillbeappl"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.4hoursnotic'|translate}}</div> -->
                                            <div class="col-md-12 pt-4"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iwishtoreceivetextmessagesfromdoctornow"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.messagesfromOXvc'|translate}}</div>
                                            <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iwishtoreceiveemailsfromdoctornow"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.EMAILSfromOXvc'|translate}}</div>
                                            <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iwishtoreceivepostalcommunicationfromdoct"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.communicationfromOXvc'|translate}}</div>
                                            <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iwouldliketoreceiveupdatesaboutnewservice"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.developmentsatOXvc'|translate}}</div>
                                            <div class="col-md-12 pt-2"><input type="checkbox" disabled
                                                    [ngModel]="profile?.bcrm_iamhappyfordoctornowtocontactmynextofkin"
                                                    name="bcrm_diabetes">
                                                {{'Profile.OtherInfo.Ihavetheirconsentforthis'|translate}}</div>
                                                
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>