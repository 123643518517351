<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                           <div class="row heading">
                                <div class="col">
                                    <h5> <i data-feather="file-text"> </i> Conditions <span style="color: gray;"></span>
                                    </h5>
                                    &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                        (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                            aria-hidden="true"></i></button>
                                            &nbsp; 
                                </div>
                           
                            </div>
                            <hr>
                            <div class="table-responsive">
                                <table class="table" id="dataTableExample">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Condition Code</th>
                                            <th>Practitioner</th>
                                            <th>Clinical Status</th>
                                            <th>Verification Status</th>
                                            <th>Created on</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of Condition">
                                            <td>{{data.msemr_name}}</td>
                                            <td>{{data.msemr_conditioncode}}</td>
                                            <td>{{data.msemr_practitioner}}</td>
                                            <td>{{data.msemr_clinicalstatus}}</td>
                                            <td>{{data.msemr_verificationstatus}}</td>
                                            <td>{{data.createdOn | date:'dd-MMM-yyyy '}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>