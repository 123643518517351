import { Component, OnInit } from '@angular/core';
import axios from 'axios';
declare var $: any;
@Component({
  selector: 'app-corona',
  templateUrl: './corona.component.html',
  styleUrls: ['./corona.component.css']
})
export class CoronaComponent implements OnInit {

  constructor() { 
        /******Active Current Component Nav****** */
        $(".nav-item").removeClass("active");
        $("#nav-item-NHS").addClass("active");
        /******End:Active Current Component Nav****** */
  }

  ngOnInit(): void {
   
    const config = {
      
      headers: { 'postman-token': '230f832a-c9f2-7014-f25c-cb795dea9a79' }
  };
  axios.get(`https://api.nhs.uk/conditions/coronavirus-covid-19?url=localhost%3A49319%2FCoronaVirus%2FCoronaVirus&modules=false`,
            config)
            .then((response) => {

              
              if (response !== null) {

                if (response.data.description !== "" && response.data.description !== undefined) {
                    $('#des').append("<h4>" + response.data.description + "</h4><br>");
                }
                if (response.data.mainEntityOfPage[0].mainEntityOfPage.length > 0) {
                    for (var i = 0; i < response.data.mainEntityOfPage[0].mainEntityOfPage.length; i++) {
                        $('#mainContent').append("<h3>" + response.data.mainEntityOfPage[0].mainEntityOfPage[i].headline + "</h3>");
                        $('#mainContent').append("<p>" + response.data.mainEntityOfPage[0].mainEntityOfPage[i].text + "</p>");
                    }
                }
                if (response.data.hasPart.length > 0) {
                    for (var i = 0; i < response.data.hasPart.length; i++) {
                       $('#accordion').append("<div class='card'><div class='card-header'><a class='collapsed card-link' data-toggle='collapse' href='#" + response.data.hasPart[i].name + "'><h5 class='mb-0'>" + response.data.hasPart[i].name + "</h5></a></div><div id='" + response.data.hasPart[i].name + "'class='collapse' data-parent='#accordion'><div class='card-body'><p id='description'>" + response.data.hasPart[i].description + "</p><p id='overview'>" + response.data.hasPart[i].text + "</p></div></div></div><br>");

                    }
                }
                if (response.data.url !== "" && response.data.url !== undefined) {
                    $('#MoreInfo').append("<a target='_blank' href='" + response.data.url + "'><p>More Information</p></a>");
                }
               
            }
      })
  }

}
