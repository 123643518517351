import { Component, OnInit } from '@angular/core';
import { ChathistoryService } from 'src/app/ApiServices/chathistory/chathistory.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-history-chat',
  templateUrl: './history-chat.component.html',
  styleUrls: ['./history-chat.component.css']
})
export class HistoryChatComponent implements OnInit {

  consentdata: any;
  proceduredata: any;
  procedureTime: any;

  constructor(private ChathistoryService: ChathistoryService) { }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
     environment.LoginUserId= data.userId;
    this.GetAllProcedure();
  }
  RefreshPage(){
    this.ngOnInit()
  }
  GetAllProcedure(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.consentdata = null;

    this.ChathistoryService.getAllProcedure(environment.LoginUserId).subscribe((res) => {debugger
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
      if (res != null) {debugger
        this.consentdata = res;
debugger;
        this.proceduredata = res[0];
        console.log(this.proceduredata)
        var datestarttime = this.proceduredata.performedStartDate.toString("dd-MMMM-yyyy").split('T');
        this.procedureTime = datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[0] + ":" + datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[1];

      }

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
 
      // this.router.navigate(['/']);
    });

  }
}
