import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
import { environment } from 'src/environments/environment';
import { ConsentService } from '../../../ApiServices/consent.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'app-consents',
  templateUrl: './consents.component.html',
  styleUrls: ['./consents.component.css']
})
export class ConsentsComponent implements OnInit {
  consentdata: any;
  proceduredata: any;
  profile: any = {};
  DOBInYear: any;
  consentquestion: any;
  procedureId: any;
  additionalInfoList;
  urlList: object;
  additionalvalue: object;
  videourl: any;
  procedureTime: any;
  constructor(private profileservice: ProfileService, private consentservice: ConsentService, private router: Router, private toastr: ToastrService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail=data.userEamil;
     environment.LoginUserId= data.userId;
    this.videourl = null;
    this.GetAllProcedure();
  }
  RefreshPage(){
    this.ngOnInit()
  }
  GetAllProcedure(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.consentdata = null;

    this.consentservice.getAllProcedure(environment.LoginUserId).subscribe((res) => {debugger
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
      if (res != null) {debugger
        this.consentdata = res;
debugger
        this.proceduredata = res[0];
        console.log(this.proceduredata)
        var datestarttime = this.proceduredata.performedStartDate.toString("dd-MMMM-yyyy").split('T');
        this.procedureTime = datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[0] + ":" + datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[1];

      }

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });

  }

  getconsentquestion(Id) {
    debugger
    // this.profileservice.getconsentquestion(Id).subscribe((res) => {
    //   this.consentquestion = res;
    // });
    this.profileservice.getProceduredetail(Id).subscribe((res:any) => {
      // ****************Split additional info data into urls******************
      var additionalArray = [];
      this.consentquestion = res.procedureQuestion;
      this.additionalvalue = res.additionalInfo;
      if (res.additionalInfo != undefined) {
        let d = res.additionalInfo;
        this.additionalInfoList = d.split(';');
        for (var i = 0; i < this.additionalInfoList.length; i++) {
          let values = this.additionalInfoList[i].split("<>");
          let dataToPush = {
            name: values[0],
            url: values[1]
          };
          additionalArray.push(dataToPush);
        }
        this.urlList = additionalArray;
      }
    })
  }

  getprocedure(procedureID) {
    this.router.navigate(['consents'], { state: { data: procedureID, skipLocationChange: true } });
  }
  ViewDetail(procedure) {
    debugger
    this.getprofile();
    this.getconsentquestion(procedure.Id);

  }
  getprofile() {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.profileservice.getProfile(environment.LoginUserEmail).subscribe((res) => {

      this.profile = res;

      $('#ftco-loader').removeClass('show');
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });

  }
}
