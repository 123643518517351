import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
declare var $: any;

@Component({
  selector: 'app-digital-imagery',
  templateUrl: './digital-imagery.component.html',
  styleUrls: ['./digital-imagery.component.css']
})
export class DigitalImageryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    debugger;
 
    this.GetPatientCimarRecord("SIMON AAGAARD JENSEN", "1999-01-01", "30303030303030303030303030303030", "Oxford40it%1", "369ef1d6-4ed2-4c49-b982-e8bc907431b4" ,"zygote365" );
  }
  GetPatientCimarRecord = function (P_Name, P_DOB, cimar_iv_hex_key, cimar_passphrase, cimar_user_account_uuid, Cimar_vanity_name) {
    var jsonFilter = { "filter.patient_name.equals": P_Name, "filter.patient_birth_date.equals": P_DOB.split('-').join('') }

    var filterString = JSON.stringify(jsonFilter, null, '');
    // encrypt the filter

    var passphrase = cimar_passphrase; // "Oxford40it%1";
    var key = CryptoJS.enc.Utf8.parse(passphrase.padEnd(16, '\0'));
    var iv = CryptoJS.enc.Hex.parse(cimar_iv_hex_key);
    var encryptedFilter = CryptoJS.AES.encrypt(filterString, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
    // Base64 encode the encryption
    var encoded64Filter = encryptedFilter.ciphertext.toString(CryptoJS.enc.Base64);
    // url encode the filter
    var encodedUrlFilter = encodeURIComponent(encoded64Filter);
    // Construct a URL as follows with the appropriate substitutions

    var VANITY_NAME = Cimar_vanity_name;//"Oxvh365"; 
    var USER_ACCOUNT_UUID = cimar_user_account_uuid; //"369ef1d6-4ed2-4c49-b982-e8bc907431b4";
    var ENCRYPTED_HASH = encodedUrlFilter;
    var CIMAR_VIEWER_URL = "https://<VANITY_NAME>.cimar.co.uk/api/v3/link/external?u=<USER_ACCOUNT_UUID>&v=<ENCRYPTED_HASH>";
    CIMAR_VIEWER_URL = CIMAR_VIEWER_URL.replace("<VANITY_NAME>", VANITY_NAME);
    CIMAR_VIEWER_URL = CIMAR_VIEWER_URL.replace("<USER_ACCOUNT_UUID>", USER_ACCOUNT_UUID);
    CIMAR_VIEWER_URL = CIMAR_VIEWER_URL.replace("<ENCRYPTED_HASH>", ENCRYPTED_HASH);

    $("#Cimar_Report_Data").html(" <div class='embed-responsive  embed-responsive-4by3'><iframe class='embed-responsive-item' src='" + CIMAR_VIEWER_URL + "' allowfullscreen></iframe></div>");
    console.log(CIMAR_VIEWER_URL);



}
}
