import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howitworknew',
  templateUrl: './howitworknew.component.html',
  styleUrls: ['./howitworknew.component.css']
})
export class HowitworknewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
