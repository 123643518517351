import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TdlobservationService } from 'src/app/ApiServices/Observation/tdlobservation.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.css']
})
export class TestResultsComponent implements OnInit {


  observations: any;
  observationsdata: any;
  observationsResult: any;
  pivotarray: any = [];
  datearray: any = [];
  constructor(private ObservationService: TdlobservationService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserId = data.userId;
    this.getTDLObservations();
    this.getObservations()
  }
  getObservations(): void {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ObservationService.getObservation(environment.LoginUserId).subscribe((res) => {
      if (res != null) {

        this.observations = res;
        setTimeout(function () {
          $('#tdlTable').DataTable();
        }, 50);

      }

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
  getTDLObservations() {
    this.ObservationService.getPivottable(environment.LoginUserId).subscribe((res) => {
      if (res != null) {

        this.observationsdata = res;

        this.onload(this.observationsdata);

      }
      else {
        this.pivottable();
      }
    })
  }

  onload(results) {
    this.pivotarray = []
    this.datearray = []
    debugger;

    function formatDate(date) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var time = date.toLocaleTimeString();

      return day + '-' + monthNames[monthIndex] + '-' + year + ' ' + time;
    }

    for (var i = 0; i < results.length; i++) {
      debugger

      var pivottablem = {

        // Date_Of_Test: formatDate(new Date(results[i]["DateTimeofTransaction"]))
     

        Date_Of_Test: moment(results[i]["createdOn"]).format("DD-MMM-YYYY"),
        //sDate_Of_Test: (results[i]["createdOn"]).format("DD-MMM-YYYY"),
        Value: results[i]["bcrm_observationvalue"],
        Test: results[i]["bcrm_text"],
        Unit: results[i]["bcrm_units"],
        Range: results[i]["bcrm_referenceRange"],
        Code: results[i]["bcrm_identifier"],
        Flag: results[i]["bcrm_abnormalFlags"],
      }
      this.pivotarray.push(pivottablem);
      this.datearray.push(results[i]["DateTimeofTransaction"]);



    }
    this.pivottable();
  }

  pivottable() {
    debugger;

    var derivers = $.pivotUtilities.derivers;
    var renderers = $.extend($.pivotUtilities.renderers,
      $.pivotUtilities.c3_renderers);
    var sortAs = $.pivotUtilities.sortAs;
    $("#output").pivotUI(this.pivotarray, {
      renderers: renderers,
      rows: ["Date_Of_Test"],
      cols: ["Test", "Unit"],
      vals: ["Value"],
      aggregatorName: "Last",
      rendererName: "Col Heatmap",
      showAggregationOnValueField: false,
      rendererOptions: {
        c3: {
          data: {
            colors: {
              Liberal: '#dc3912', Conservative: '#3366cc', NDP: '#ff9900',
              Green: '#109618', 'Bloc Quebecois': '#990099'
            }
          }
        }
      },
      sorters: {

        "Date_Of_Test": sortAs(this.datearray),
      }
    });




    //   var sortAs = $.pivotUtilities.sortAs;
    //   $("#output").pivotUI(this.pivotarray, {
    //     rows: ["Date_Of_Test"],
    //     cols: ["Test", "Unit"],
    //     vals: ["Value"],
    //     aggregatorName: "Last",
    //     rendererName: "Col Heatmap",
    //     showAggregationOnValueField: false,
    //     sorters: {
    //       "createdOn": sortAs(this.datearray),
    //     }
    //   });
  }
  RefreshPage(){
    this.ngOnInit()
  }
  OpenDetails(Observation) {
    debugger
    this.router.navigate(['/observationDetail', Observation.Id], {state: { ObservationDetail: Observation },skipLocationChange: true });
  }
}
