import { Component, OnInit } from '@angular/core';
import { GetAppointmentsService } from 'src/app/ApiServices/Booking/get-appointments.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-patient-only-view',
  templateUrl: './patient-only-view.component.html',
  styleUrls: ['./patient-only-view.component.css']
})
export class PatientOnlyViewComponent implements OnInit {
  Token: string;
  date: Date;
  listOfAppointments: object;
  CurrentAppointmentData: any
  CurretTime: any;
  TodayDate: any;

  constructor(private appointmentsService: GetAppointmentsService,
    private toastr: ToastrService) {
    $(".waitingRoomNav").removeClass("active");
    $("#PatientViewData").addClass("active");
  }

  ngOnInit(): void {
    let localThis = this;
    this.callAppointmentsAPI(new Date().toLocaleDateString())
    setInterval(function () {
      localThis.callAppointmentsAPI(new Date().toLocaleDateString());
    }, 30000);
  }
  callAppointmentsAPI(Date1) {
    //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    /**************************************** */
    var generalConsultation = []
    var dermatology = [];
    var orthopaedics = [];
    var COVID = [];
    var gynaecology = [];
    var others = [];

    this.appointmentsService.getAppointmentsByEmail(environment.LoginUserEmail, Date1).subscribe((res) => {
      let app_List = res;
      if (res != null) {
        $.each(app_List, function (key1, appointmentData) {

          var obj = {
            Id: appointmentData.id,
            title: appointmentData.doctor_name,
            start: new Date(appointmentData.startTime),
            end: new Date(appointmentData.endTime),
            bcrm_doctorstatus: appointmentData.bcrm_doctorstatus,
            bcrm_doctorstatusformatted: appointmentData.bcrm_doctorstatusformatted,
            serviceName: appointmentData.serviceName,
            teams_URL: appointmentData.teams_URL,
            contact_name: appointmentData.contact_name,
            subject: appointmentData.subject,
            bcrm_ispatientready: appointmentData.bcrm_ispatientready
          };
          if (obj.serviceName == "General Consultation") {
            generalConsultation.push(obj)
          }
          else if (obj.serviceName == "Dermatology") {
            dermatology.push(obj);
          }
          else if (obj.serviceName == "Orthopaedics") {
            orthopaedics.push(obj);
          }
          else if (obj.serviceName == "COVID-19 Consultation") {
            COVID.push(obj);
          }
          else if (obj.serviceName == "Gynaecology") {
            gynaecology.push(obj);
          }
          else {
            others.push(obj);
          }

        });
        this.showCalenderView(generalConsultation, dermatology, orthopaedics, COVID, gynaecology, others)
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }
  showCalenderView(generalConsultationList, dermatologyList, orthopaedisList, COVIDList, gynaecologyList, othersList) {
    var localThis = this;
    // sample calendar events data
    // Calendar Event Source
    var generalConsultationEvents = {
      id: 1,
      backgroundColor: 'rgba(91,71,251,.2)',
      borderColor: '#5b47fb',
      events: generalConsultationList
    };
    // Birthday Events Source
    var dermatologyEvent = {
      id: 2,
      backgroundColor: 'rgba(253,126,20,.25)',
      borderColor: '#fd7e14',
      events: dermatologyList
    };
    var orthopaedisEvents = {
      id: 3,
      backgroundColor: 'rgba(241,0,117,.25)',
      borderColor: '#f10075',
      events: orthopaedisList
    };
    var COVIDEvents = {
      id: 4,
      backgroundColor: 'rgba(0,204,204,.25)',
      borderColor: '#00cccc',
      events: COVIDList
    };
    var gynaecologyEvents = {
      id: 5,
      backgroundColor: 'rgba(16,183,89,.2)',
      borderColor: '#10b759',
      events: gynaecologyList
    };
    var otherEvents = {
      id: 6,
      backgroundColor: 'rgba(1,104,250,.15)',
      borderColor: '#0168fa',
      events: othersList
    };
    $('#fullcalendar').fullCalendar('destroy');
    // initialize the calendar
    $('#fullcalendar').fullCalendar({
      header: {
        left: 'prev,today,next',
        center: 'title',
        right: 'month,agendaWeek,listMonth'
      },
      nowIndicator: true,
      editable: true,
      droppable: true, // this allows things to be dropped onto the calendar
      dragRevertDuration: 0,
      defaultView: 'month',
      eventLimit: true, // allow "more" link when too many events
      eventSources: [generalConsultationEvents, dermatologyEvent, orthopaedisEvents, COVIDEvents, gynaecologyEvents, otherEvents],
      viewRender: (function () {
        return function (view) {
          var view = $('#fullcalendar').fullCalendar('getView');
          if (view.name == "agendaDay") {
          }
        }
      })(),
      eventClick: function (event, jsEvent, view) {
        /************on the Click of Appointments****** */

        localThis.CurrentAppointmentData = event;
      },
      dayClick: function (date, jsEvent, view) {
        /************on the Click of day****** */
      },
      eventDragStop: function (event, jsEvent, ui, view) {
      }
    });

  }
  setPatientStatus(appointmentsData): void {
    
    //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    this.appointmentsService.updatePatientStatus(appointmentsData.Id, true).subscribe((res) => {

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      window.location.reload();
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }
  openTeamsMeeting(meetingURL: string): void {
    window.open(meetingURL);
  }
}
