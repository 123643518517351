import { Component, OnInit } from '@angular/core';
import axios from 'axios';
declare var $: any;

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.css']
})
export class ConditionsComponent implements OnInit {

  constructor() {
     /******Active Current Component Nav****** */
     $(".nav-item").removeClass("active");
     $("#nav-item-NHS").addClass("active");
     /******End:Active Current Component Nav****** */
   }

  ngOnInit(): void {
    $(document).ready(function () {

      $("#condit").select2({
        //data: country
      });
    });
    $('body').on('change', '#condit', function (e) {
      
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /**************************************** */
      var Condition = document.getElementById("condit");
      var conditionVal = e.target.value;
      //   var conditionVal = document.getElementById("condition").value;

      $("#conditionHeader").empty();
      $("#hasPart").empty();
      $("#Relatedlink").empty();
      $("#mainContent").empty();
      $("#CondDescription").empty();
      e.preventDefault();
      e.stopPropagation();



      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };
      if (conditionVal != null && conditionVal != "" && conditionVal != undefined) {
        axios.get(`https://api.nhs.uk/conditions/` + conditionVal,
          config)
          .then((response) => {

            
            if (response != null && response != undefined) {
              $('#conditionHeader').append(response.data.about.name);
              if (response.data.description != "") {
                $('#CondDescription').append(response.data.description);

              }
              if (response.data.hasPart.length > 0) {
                for (var i = 0; i < response.data.hasPart.length; i++) {
                  $('#hasPart').append("<h3><a href=" + response.data.hasPart[i].url + ">" + response.data.hasPart[i].name + "</a></h3><p>" + response.data.hasPart[i].description + "</p><p>" + response.data.hasPart[i].text + "</p>");
                  $('#hasPart a').addClass('CondLinkedUrl');
                }
                for (var j = 0; j < response.data.relatedLink.length; j++) {
                  $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[j].url + "'>" + response.data.relatedLink[j].name + "</a><br>");
                  $('#Relatedlink a').addClass('CondLinkedUrl');
                }

                for (var k = 0; k < response.data.mainEntityOfPage.length; k++) {
                  for (var l = 0; l < response.data.mainEntityOfPage[k].mainEntityOfPage.length; l++) {
                    if (response.data.mainEntityOfPage[k].headline != "" && response.data.mainEntityOfPage[k].text != "") {
                      $('#mainContent').append("<h2> " + response.data.mainEntityOfPage[k].headline + "</h2> <p>" + response.data.mainEntityOfPage[k].text + "</p>");
                      $('#mainContent a').addClass('CondLinkedUrl');
                    }
                    $('#mainContent').append("<p> " + response.data.mainEntityOfPage[k].mainEntityOfPage[l].text + "</p >");
                    $('#mainContent a').addClass('CondLinkedUrl');
                  }
                }
              }
              //*************Show / hide loader *******************/
              $('#ftco-loader').removeClass('show');
              /**************************************** */

            }
          })
      }

    });
    $(document).on("click", ".CondLinkedUrl", function (e) {
      
      e.preventDefault();
      e.stopPropagation();
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /**************************************** */
      var ahref = ($(this).attr('href'));

      $("#conditionHeader").empty();
      $("#hasPart").empty();
      $("#Relatedlink").empty();
      $("#mainContent").empty();
      $("#CondDescription").empty();


      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };

      axios.get(ahref,
        config)
        .then((response) => {

          
          if (response != null && response != undefined) {
            $('#conditionHeader').append(response.data.about.name);
            for (var j = 0; j < response.data.relatedLink.length; j++) {
              $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[j].url + "'>" + response.data.relatedLink[j].name + "</a><br>");
              $('#Relatedlink a').addClass('CondLinkedUrl');

            }
            if (response.data.hasPart.length > 0) {
              for (var i = 0; i < response.data.hasPart.length; i++) {
                $('#hasPart').append("<h3><a href=" + response.data.hasPart[i].url + ">" + response.data.hasPart[i].name + "</a></h3><p>" + response.data.hasPart[i].description + "</p><p>" + response.data.hasPart[i].text + "</p>");
                $('#hasPart a').addClass('CondLinkedUrl');

              }
            }

            for (var k = 0; k < response.data.mainEntityOfPage.length; k++) {
              for (var l = 0; l < response.data.mainEntityOfPage[k].mainEntityOfPage.length; l++) {
                $('#mainContent').append("<p>" + response.data.mainEntityOfPage[0].text + "</p><p>" + response.data.mainEntityOfPage[k].mainEntityOfPage[l].text + "</p>");
                $('#mainContent a').addClass('CondLinkedUrl');

              }
            }
            //*************Show / hide loader *******************/
            $('#ftco-loader').removeClass('show');
            /**************************************** */

          }
          else {
            alert("Condition Not Found");
          }
        })

    });
  }
}

