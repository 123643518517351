import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetAppointmentsService } from 'src/app/ApiServices/Booking/get-appointments.service';
import {apiConfig} from 'src/app/app-config';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { AppComponent } from 'src/app/app.component';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
declare var $: any;
declare const require: any;
declare function startChat(): any;
@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.css']
})
export class WaitingRoomComponent implements OnInit {
  Token: string;
  date: Date;
  listOfAppointments: object;
  CurrentAppointmentData: any
  CurretTime: any;
  TodayDate: any;
  isLoggedIn: boolean = false;
  constructor(private appointmentsService: GetAppointmentsService,
    private toastr: ToastrService,
    private authService: MsalService,
    private router: Router,
    private appComponent: AppComponent,
    private headerComponent: HeaderComponent) {
    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-WaitingRoom").addClass("active");
    /******End:Active Current Component Nav****** */
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    
    this.loadScript('../../../../assets/js/AlfredBot.js');
  }
  
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public acquireTokenSilentAndStartBot()
  {
    debugger;
    var request = {
      scopes : apiConfig.b2cScopes
    };

    this.authService.acquireTokenSilent(request).then(tokenResponse => {
      debugger;
      localStorage.setItem("accessToken",tokenResponse.accessToken);
      startChat();
  }).catch(error => {
  });
  }
  
}
