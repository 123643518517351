<div class="bradcam_area breadcam_bg bradcam_overlay">
  <div class="container containerpadding">
    <div class="row" style="justify-content: center;">
      <p class="hcolor">Doctors Profile</p>

    </div>
  </div>
</div>

<!-- New Profile Demo -->

<section class="ftco-section bg-light">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title"><span style="color:#22A6E7;">{{doctorSpDetails.name}}</span><button type="button" href="#"
              class="btn btn-primary pull-right" [routerLink]="['/bookAppointment', doctorSpDetails.UserName ]"
              skipLocationChange> Book Now &nbsp;</button>
          </h3>

          <hr>
          <div class="row">
            <div class="col-lg-3 col-md-3"> 
              <div class="card-avatar">
              <a href="#pablo">
                <img class="img" src="{{profileImage}}" class="rounded-circle">
              </a>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <h5>Basic Details and Education</h5>
            <p>GMC Number: <span>{{doctorSpDetails.gmcNumber}}</span></p> 
            <p>Email: <span>{{doctorSpDetails.email}}</span></p>
            <p>Home Phone:: <span>{{doctorSpDetails.mobilePhone}}</span></p>
            <p>Work Phone: <span>{{doctorSpDetails.mobilePhone}}</span></p>
            <p>School: <span>{{doctorSpDetails.clinicName}}</span></p>
           </div>
     
           <div class="col-lg-4 col-md-4">

            <h5>Skills and Projects</h5>
            <p>Specialisation: <span>{{doctorSpDetails.Skills}}</span></p> 
            <p>Qualifications: <span>{{doctorSpDetails.LetterQualifications}}</span></p> 
            <p>Projects: <span>{{doctorSpDetails.PastProjects}}</span></p> 
           </div>

          </div>
          <div class="row">
          <div class="col-lg-3 col-md-3"></div>    
          <div class="col-lg-9 col-md-9 col-sm-9">
            <h4>About Me</h4>
            {{doctorSpDetails.AboutMe}}
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</section>





