import { Component, OnInit } from '@angular/core';
import axios from 'axios';
declare var $: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  constructor() {
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-NHS").addClass("active");
    /******End:Active Current Component Nav****** */
  }


  ngOnInit(): void {
    const config = {

      headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
    };
    axios.get(`https://api.nhs.uk/video`,
      config)
      .then((response) => {


        if (response != null && response != undefined) {
          $('#VideoHeader').append(response.data.name);
          $('#description').append(response.data.description);
          for (var i = 0; i < response.data.relatedLink.length; i++) {
            $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[i].url + "'>" + response.data.relatedLink[i].name + "</a><br>");
            $('#Relatedlink a').addClass('LinkedUrl');
          }
          for (var j = 0; j < response.data.video.length; j++) {
            $('#mainContent').append("<br><h4> " + response.data.video[j].name + "</h4>");
            $('#mainContent').append("<p style='font-size:20px;text-align:center'><a target='_blank' href='https:" + response.data.video[j].embedUrl + "'>" + "Play the Video" + "</a></p>");
            $('#mainContent').append("<p>" + response.data.video[j].description + "</p>");
          }
          $(".my_Loader").fadeOut("slow");

        }

      })

    $(document).on("click", ".LinkedUrl", function (e) {
      
      e.preventDefault();
      e.stopPropagation();
      var ahref = ($(this).attr('href'));
      $("#VideoHeader").empty();
      $("#description").empty();
      $("#Relatedlink").empty();
      $("#mainContent").empty();



      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };

      axios.get(ahref,
        config)
        .then((response) => {

          
          if (response != null && response != undefined) {
            $('#VideoHeader').append(response.data.name);
            $('#description').append(response.data.description);
            for (var i = 0; i < response.data.relatedLink.length; i++) {
              $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[i].url + "'>" + response.data.relatedLink[i].name + "</a><br>");
              $('#Relatedlink a').addClass('LinkedUrl');
            }
            for (var j = 0; j < response.data.video.length; j++) {
              $('#mainContent').append("<br><h4> " + response.data.video[j].name + "</h4>");
              $('#mainContent').append("<p style='font-size:20px;text-align:center'><a target='_blank' href='https:" + response.data.video[j].embedUrl + "'>" + "Play the Video" + "</a></p>");
              $('#mainContent').append("<p>" + response.data.video[j].description + "</p>");
            }
            $(".my_Loader").fadeOut("slow");

          }
        })
    });
  }
}
