<!------ Do not add anything above this tag ---------->
<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container containerpadding">
        <div class="row" style="justify-content: center;">

            <p class="hcolor">{{'ProfileTabs.profile'|translate}}</p>
            <!-- <p><a href="index.html">Home /</a> Profile</p> -->

        </div>
    </div>
</div>
<div class="headingsection">
    <div class="container">
        <p class="h1color">{{'Header.editYourProfile'|translate}}</p>
        <ul class="ulstyle">
            <li class="liststyle" routerLink="./profiledetail" id="profile" skipLocationChange><img src="../../../assets/images/Asset 2.png" height="80" id="your-image">
                <p><a class="link">{{'ProfileTabs.profile'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./allergies" id="allergy" skipLocationChange><img src="../../../assets/images/Asset 3.png" height="80" id="your-image1">
                <p><a class="link">{{'MedicalHistory.Tabs.allergies'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newconditions" id="newconditions" skipLocationChange><img src="../../../assets/images/Asset 4.png" height="80" id="your-image2">
                <p> <a class="link">{{'ProfileTabs.condition'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newinvoices" id="newinvoices" skipLocationChange><img src="../../../assets/images/Asset 5.png" height="80" id="your-image3">
                <p><a class="link">{{'ProfileTabs.invoice'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newobservations" id="newobservations" skipLocationChange><img src="../../../assets/images/Asset 6.png" height="80" id="your-image4">
                <p><a class="link">{{'ProfileTabs.TestResults'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newobservationOOB" skipLocationChange id="newobservationOOB"><img src="../../../assets/images/Asset 7.png" height="80" id="your-image5">
                <p><a class="link">{{'ProfileTabs.Observations'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newprescriptions" skipLocationChange id="newprescriptions"><img src="../../../assets/images/Asset 8.png" height="80" id="your-image6">
                <p><a class="link">{{'MedicalHistory.Tabs.prescription'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newcimar" skipLocationChange id="newcimar"><img src="../../../assets/images/Asset 9.png" height="80" id="your-image7">
                <p><a class="link">{{'ProfileTabs.DigitalImagery'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./newdocument" skipLocationChange id="newdocument"><img src="../../../assets/images/Asset 10.png" height="80" id="your-image8">
                <p><a class="link">{{'ProfileTabs.document'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./consent" skipLocationChange id="newConsent"><img src="../../../assets/images/Asset 10.png" height="80" id="your-image9">
                <p><a class="link">{{'ProfileTabs.Consent'|translate}}</a></p>
            </li>
            <li class="liststyle" routerLink="./case" skipLocationChange id="case"><img src="../../../assets/images/Asset 10.png" height="80" id="your-image10">
                <p><a class="link">{{'ProfileTabs.OnlineConsultation'|translate}}</a></p>
            </li>

            <li class="liststyle" routerLink="./ChatHistory" skipLocationChange id="ChatHistory"><img src="../../../assets/images/Asset 10.png" height="80" id="your-image12">

                <p><a class="link">Chat History</a></p>

            </li>

            <li class="liststyle" routerLink="./SickNotes" skipLocationChange id="SickNotes"><img src="../../../assets/images/Asset 3.png" height="80" id="your-image11">
                <p><a class="link">Sick Notes</a></p>
            </li>

        </ul>

    </div>

</div>

<router-outlet></router-outlet>
<!------ Include everything you have below this---------->