<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="page-content">

      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <h6 class="card-title">Procedures</h6> -->
              <!-- <p class="card-description">Read the <a href="https://datatables.net/" target="_blank"> Official DataTables Documentation </a>for a full list of instructions and other options.</p> -->
              <div class="row heading">
                <div class="col">

                  <h5> <i data-feather="file-text"> </i> Prescription <span style="color: gray;"></span></h5>
                </div>

                <div class="col ">&nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                    (click)="RefreshPage()"><i class="fa fa-refresh ref" aria-hidden="true"></i></button>
                  &nbsp; <button type="submit" routerLink='../reqPrescript' skipLocationChange
                    class="btn btn-primary float-right mr-2 mb-2 mb-md-0">Request Repeat Prescription</button></div>

              </div>

              <hr>
              <div class="" style="max-width: 100%; overflow: auto;">
                <table class="table" id="dataTableExample">
                  <thead>
                    <tr>
                      <th style="display: none;">S/No</th>
                      <!-- <th>{{'MedicalHistory.Tabs.prescription'|translate}}</th> -->
                      <th>{{'MedicalHistory.Prescription.Drug'|translate}}</th>
                      <th>{{'PrecriptionDetail.Dosage'|translate}}</th>
                      <th>{{'MedicalHistory.Prescription.DosageUnit'|translate}}</th>
                      <th>{{'MedicalHistory.Prescription.Directions'|translate}}</th>
                      <!-- <th>{{'MedicalHistory.Prescription.StartDate'|translate}}</th>
                      <th>{{'MedicalHistory.Prescription.EndDate'|translate}}</th>
                      <th>{{'MedicalHistory.Prescription.ExpiryDate'|translate}}</th> -->
                      <th>{{'Common.comments'|translate}}</th>
                      <!-- <th>{{'MedicalHistory.StoredSample.type'|translate}}</th> -->
                      <th>{{'Common.Status'|translate}}</th>
                      <th>Requested On</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of prescriptiondrugs; index as i">
                      <td style="display: none;">{{i + 1}}</td>
                      <!-- <td>{{data.bcrm_prescriptionHeaderName}}</td> -->
                      <td>{{data.bcrm_drug}}</td>
                      <td>{{data.bcrm_dosage}}</td>
                      <td>{{data.bcrm_dosageunit}}</td>
                      <td>{{data.bcrm_directions}}</td>
                      <!-- <td><span *ngIf="data.bcrm_drugstartdate!= '0001-01-01T00:00:00'">{{data.bcrm_drugstartdate | date:'dd/MM/yyyy'}}</span>
                                          </td>
                                          <td><span *ngIf="data.bcrm_drugenddate!= '0001-01-01T00:00:00'">{{data.bcrm_drugenddate | date:'dd/MM/yyyy'}}</span>
                                          </td>
                                          <td><span *ngIf="data.bcrm_expirydate!= '0001-01-01T00:00:00'">{{data.bcrm_expirydate | date:'dd/MM/yyyy'}}</span>
                                          </td> -->
                      <td>{{data.bcrm_startcomment}}</td>
                      <!-- <td>{{data.bcrm_type}}</td> -->
                      <td>{{data.prescriptionStatus}}</td>
                      <td>{{data.createdOn | date:'dd-MMM-yyyy'}}</td>
                      <td>{{data.bcrm_type}}</td>
                      <td> <span><a style="color:#009FE3 ;font-size:14px"></a><i
                            *ngIf="data.prescriptionStatus == 'Approved'" role="button" data-toggle="modal"
                            (click)="getData(data)" href="#PreviewModal" data-toggle="modal" title="Preview" data
                            class="fa fa-eye" aria-hidden="true">
                          </i></span></td>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div id="PreviewModal" class="modal fade">
  <!-- class modal and fade -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <!-- modal header -->
        <div><button class="btn btn-primary" (click)="printDiv()">Download</button></div>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-hidden="true">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div id="GFG">
          <div class="card">
            <div class="card-body bg-white border p-4">

              <!-- ngIf: PrescriptDataView?.bcrm_status == '3' -->

              <div class="row ">
                <div class="col-md-6 ">
                  <div class="row ">
                    <div class="col-md-6 border  p-2">
                      <label>
                        <b>Pharmasy Stamp</b>
                      </label>
                    </div>
                    <div class="col-md-6 border  p-2">
                      <label>
                        <b>Age:</b>{{Age}}
                        <br>
                      </label>
                    </div>
                    <br><br>
                  </div>
                  <div class="row ">
                    <div class="col-md-10 border p-2">
                      Number of day's treatment <br> N.B. Ensure dose is stated
                    </div>
                    <div class="col-md-2 border">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 border">
                  <div class="row">
                    <div class="col-md-12 p-2">
                      <b>Title , Forename ,Surname & Address:</b>
                      <div class="ml-2">
                        {{fullName}} <br>
                        {{Address}}
                      </div>
                    </div>
                  </div>
                  {{PrescriptDataView?.msemr_patient}}
                </div>
              </div>
              <div class="row p-2 border mt-2">
                <div>
                  <b>Endorsement:</b>
                </div>
                <div>
                  <br>
                  <b>Dose:</b> {{PrescriptDataView?.bcrm_dosage}} <br>
                  <b>Drug:</b> {{PrescriptDataView?.bcrm_drug}}
                </div>
              </div>
              <div class="row" style="padding-top: 10px;">
                <div class="col-md-4 border p-2">
                  <label>
                    <b>Patient comments:</b><br>{{PrescriptDataView?.bcrm_doctorscomment}}<br>
                    <b></b><br>

                  </label>
                </div>
                <div class="col-md-4 border p-2">
                  <b>Internal comments:</b><br>{{PrescriptDataView?.bcrm_internalcomment}}
                </div>
                <div class="col-md-4 border p-2">
                  <b>Pharmacy comments:</b><br>{{PrescriptDataView?.bcrm_pharmacycomments}}<br>
                </div>
              </div>
              
              <div class="row" style="padding-top: 10px;">
                <div class="col-md-7 border p-2">
                  <label>
                    <b>Signature of prescriber:</b><br> <img src={{PrescriptDataView?.bcrm_signer}} alt=""
                      width="150"><br>
                  </label>
                </div>
                <div class="col-md-5 border p-2">
                  <b>Date:</b><br>{{PrescriptDataView?.createdOn | date:'dd-MMM-yyyy'}}
                </div>
              </div>


              <div class="row" style="padding-top: 10px;height: 100px;">
                <div class="col-md-3 border p-2">
                  For despenser No of Prescns on form
                </div>
                <div class="col-md-9 p-2 border">
                  221B Baker Street
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / .modal-content -->

  </div>
  <!-- / .modal-dialog -->

</div>