import { Component, OnInit } from '@angular/core';
import { AllergyService } from 'src/app/ApiServices/Allergies/allergy.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
declare var $: any;
import axios from 'axios';

declare var $: any;

@Component({
  selector: 'app-allergy',
  templateUrl: './allergy.component.html',
  styleUrls: ['./allergy.component.css']
})
export class AllergyComponent implements OnInit {
  allergies: object;
  allergy: any = {};
  Token: string;
  isLoggedIn: boolean = false;
  selectDropdown: any;
  findByQueryUrl: any;
  Snomedcode: string;
  query1: string = "";
  SnomedName: string;
  allergyObj: any;
  snomedjson: any;
  QueryResult: any = [];
  codeList: any;
  dropdownList;
  public codeValue: string;
  EditSnomadedata: any;
  constructor(private allergyservice: AllergyService,
    private router: Router,
    private toastr: ToastrService,
    private headerComponent: HeaderComponent) {
    this.Token = "";//"";

    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail=data.userEamil;
     environment.LoginUserId= data.userId;
    this.getAllergies();
  }
RefreshPage(){
  this.ngOnInit()
}
  getAllergies(): void {
    $('.AllergyTable').DataTable().destroy();
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.allergies = null;

    this.allergyservice.getAllergy(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.allergies = res;
      }
      setTimeout(() => {
        $('#dataTableExample').DataTable().destroy();
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function () {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
      }, 1000);

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
  // Find by query and set the scrollable raw json result

  // Find by query and set the scrollable raw json result
  findByQuery(query, text) {
    let LocalThis = this
    LocalThis.QueryResult = [];

    if (text == 'add') {
      if (query.value == "") {
        return
      }
    }
    else if (text == 'update') {
      if (query == "") {
        return
      }
    }

    var baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
    var edition = "MAIN";
    var version = "2019-07-31";
    // if condition for add and update
    if (text == 'add') {
      query = query.value;
    } else if (text == 'update') {
      query = query;
      LocalThis.QueryResult.push({
        ID: LocalThis.EditSnomadedata.bcrm_snomedcode,
        Name: LocalThis.EditSnomadedata.Name,
      });
    }
    // Make the HTTP Call
    axios.get(`${baseUrl}/${edition}/${version}/concepts?term=${encodeURIComponent(query)}&activeFilter=true&offset=0&limit=20`).then(
      (response) => {
        LocalThis.dropdownList = response.data['items'];
        LocalThis.dropdownList.forEach(function (item) {
          var data = {
            ID: item.conceptId,
            Name: item.pt.term
          }
          LocalThis.QueryResult.push(data);
        });


      }, (err) => {
        this.toastr.error("No data found");
      });

  }
  getdata(value) {
    debugger;
    var snomedDetail = this.QueryResult.find(x => x['ID'] == value)
    this.Snomedcode = snomedDetail["ID"];
    this.SnomedName = snomedDetail["Name"];
    (<HTMLInputElement>document.getElementById("bcrm_snomedcode")).value = this.Snomedcode;
    (<HTMLInputElement>document.getElementById("bcrm_snomedcode1")).value = this.Snomedcode;
  }


  onSubmit(it: NgForm) {
    debugger;
    it.value.bcrm_snomedcode = this.Snomedcode
    it.value.name = this.SnomedName;
    if (it.value.name == null || it.value.bcrm_snomedcode == null) {
      return this.toastr.error("Please fill mandatory fields");
    }
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    this.allergyObj = it.value;
    this.allergyObj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
      this.allergyservice.createAllergy(this.allergyObj, environment.LoginUserId).subscribe((res) => {

        if (res == true) {
          this.toastr.success("Allergy successfully created");
          $('#Modal1').modal('hide');
          this.ReloadComponent();
          $('#Modal1 form')[0].reset();
          $("#Critically")[0].selectedIndex = -1;
          $("#Type")[0].selectedIndex = -1;
          $("#Verification")[0].selectedIndex = -1;
        }
        else {
          this.toastr.error("Error while creating allergy");
        }
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });
  }

  editallergy(data) {
    debugger;
    this.EditSnomadedata = data;
    this.QueryResult = [];
    var data1 = {
      ID: data.bcrm_snomedcode,
      Name: data.Name,
    }
    this.QueryResult.push(data1);
    this.allergy = data;
    this.allergy.Id = data.Id;
    this.allergy.query1 = "";
    (<HTMLInputElement>document.getElementById("bcrm_snomedcode1")).value = data.bcrm_snomedcode
  }

  showConfirm(allergyid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.value) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /**************************************** */
        this.allergyservice.deleteAllergy(allergyid).subscribe((res) => {

          if (res == true) {
            this.toastr.info("Deleted Successfully");
            this.ReloadComponent();
          }
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error : ");
          this.router.navigate(['/']);
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        );
      }
    });

  }

  onUpdate(EditAllergy) {
    debugger;
    this.allergy.Name = this.SnomedName;
    this.allergy.bcrm_snomedcode = this.Snomedcode;
    if (this.allergy.Name == null || this.allergy.bcrm_snomedcode == null) {
      return this.toastr.error("Please fill mandatory fields");
    }
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.allergyservice.updateAllergy(this.allergy, environment.LoginUserId).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Allergy updated sucessfully.");
        $('#Modal2').modal('hide');
        this.ReloadComponent();
      }
      else {
        this.toastr.error("Allergy not updated");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
  clearAllergy(){
    $("#searchallergy").val("");
    $("#bcrm_snomedcode").val("");
    $("#browser").val("");
    $("#mild").prop('checked', false);
    $("#moderate").prop('checked', false);
    $("#severe").prop('checked', false);
    $("#Allergy").prop('checked', false);
    $("#Intolerance").prop('checked', false);
    $("#Verification").prop('checked', false);
    $("#Confirmed").prop('checked', false);
    $("#Refuted").prop('checked', false);
    $("#Entered").prop('checked', false);
  }
}
