import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
import { environment } from 'src/environments/environment';
import {ChathistoryService} from '../../../ApiServices/chathistory/chathistory.service';
//import { ConsentService } from '../../../ApiServices/consent.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

declare var $;
@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.css']
})
export class ChatHistoryComponent implements OnInit {
  consentdata: any;
  proceduredata: any;
  procedureTime: any;


  constructor(private profileservice: ProfileService, private ChathistoryService: ChathistoryService, private router: Router, private toastr: ToastrService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.GetAllProcedure();
  }

  GetAllProcedure(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.consentdata = null;

    this.ChathistoryService.getAllProcedure(environment.LoginUserId).subscribe((res) => {debugger
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
      if (res != null) {debugger
        this.consentdata = res;
debugger;
        this.proceduredata = res[0];
        console.log(this.proceduredata)
        var datestarttime = this.proceduredata.performedStartDate.toString("dd-MMMM-yyyy").split('T');
        this.procedureTime = datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[0] + ":" + datestarttime[1].substring(0, datestarttime[1].length - 1).split(":")[1];

      }

      $("#your-image9").attr("src", "../../../assets/images/Documents.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });

  }

}
