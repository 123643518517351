import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { BookAppointmentsServiesService } from 'src/app/ApiServices/Booking/book-appointments-servies.service';
import { DepartmentsService } from 'src/app/ApiServices/Department/departments.service';
import { RequestAppService } from 'src/app/ApiServices/Booking/request-app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-requestappointmentnew',
  templateUrl: './requestappointmentnew.component.html',
  styleUrls: ['./requestappointmentnew.component.css']
})

export class RequestappointmentnewComponent implements OnInit {
  /******************************Variables************************************** */
  allServices: any; bcrm_serviceid: string; allDoctors: any; bcrm_servicename: string; Questions: any; emailaddress1: string = environment.LoginUserEmail;
  UserPhoneNumber: string = environment.LoginUserMobile; FullName: string = environment.LoginUserfullName; RequestAppointmentsData: object; patient_Name: any; patient_Email: any; Subject: any;
  Comment: any; ServiceName: any; DoctorFullName: any; patient_MobilePhone: any; bcrm_anythingnew: any; ServiceDuration: number = 30; dialcode: string; PhoneNumber: string; inputObj: any; requestDate: Date; reuestTime: String;
  bcrm_modifiedby:string;
  preferredcontactmethod: any;
  NhsValue :any;

  /***************************Constructor********************************** */
  constructor(
    public BookServies: BookAppointmentsServiesService,
    public departmentService: DepartmentsService,
    public requestserive: RequestAppService,
    private toastr: ToastrService,
    private router: Router) {
    /**************** Active Current Tab ********************* */
    $(".nav-item").removeClass("active");
    $("#nav-item-lobby").addClass("active");
    /**************** Active Current Tab End********************* */
  }
  ngOnInit(): void {
    this.NhsValue = "2";
    this.getAllServices();
    $("#patientEmail").prop('disabled', true);
    $(document).ready(function () {


      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      // 
   
      // 
      $("#Personalque1").click(function () {
        $("#your-image2").attr("src", "../../../assets/images/question.png");
        $("#your-image").attr("src", "../../../assets/images/serviceactive.png");
      })
      $("#Personalque").click(function () {
        $("#your-image2").attr("src", "../../../assets/images/questionfinal.png");
        $("#your-image3").attr("src", "../../../assets/images/detailactive.png");
      })
      $("#Questions1").click(function () {
        $("#your-image2").attr("src", "../../../assets/images/questionactive.png");
        $("#your-image3").attr("src", "../../../assets/images/details.png");
      })
      $("#Details").click(function () {
        $("#your-image3").attr("src", "../../../assets/images/detailfinal.png");
        $("#your-image4").attr("src", "../../../assets/images/summaryactive.png");
      })
      $("#Details1").click(function () {
        $("#your-image3").attr("src", "../../../assets/images/details.png");
        $("#your-image2").attr("src", "../../../assets/images/questionactive.png");
      })
      //#endregion
      $(".next").click(function () {


        current_fs = $(this).parent();
        next_fs = $(this).parent().next();
        if (next_fs != null && !validateForm()) return false;
        //Add Class Active
        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            next_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });

      $(".previous").click(function () {

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            previous_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });
      $('.radio-group .radio').click(function () {

        $(this).parent().find('.radio').removeClass('selected');
        $(this).addClass('selected');
      });
      var currentTab = 0;
      function validateForm() {
        $("#Servicess").click(function () {
          debugger;
          if($("#subject").val()==''){
           return false
          }else{
            debugger;
            $("#your-image").attr("src", "../../../assets/images/servicefinal.png");
            $("#your-image2").attr("src", "../../../assets/images/questionactive.png");
    
          }
        })
        // This function deals with validation of the form fields
        var x, y, i, valid = true;
        x = document.getElementsByClassName("form-card");
        y = x[currentTab].getElementsByTagName("input");
        // A loop that checks every input field in the current tab:
        for (i = 0; i < y.length; i++) {
          // If a field is empty...
          if (y[i].value == "") {
            // add an "invalid" class to the field:
            y[i].className += " invalid";
            // and set the current valid status to false
            valid = false;
            
          }
        }     
        return valid; // return the valid status
      }
    
      $(".submit").click(function () {

        return false;
      })

    });

  }
  /*---------------------function for set flag in phonecode ------------------*/

  telInputObject(obj, val) {

    this.inputObj = obj;
    if (val) {
      obj.setNumber(val);
    }
  }
  /*---------------------function for get dialcode from phonecode ------------------*/

  onCountryChange($event) {
    this.dialcode = $event.dialCode;
  }
  /***************************Get All Service From Back End ********************************** */
  getAllServices(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.BookServies.getAllServices().subscribe((res) => {
      let ServicesDataList = res;
      /**Formate The Time Hour and Min */
      // if(ServicesDataList != null){
      //   ServicesDataList.forEach(function (value) {

      //     var duration = value.defaultDuration;
      //     var defaultDuration = duration.match(/\d+/g).join(".");
  
      //     if (duration.match(/[a-zA-Z]+/g)[1] === "M") {
      //       defaultDuration = defaultDuration + " Mins";
      //     }
      //     else if (duration.match(/[a-zA-Z]+/g)[1] === "H") {
      //       defaultDuration = defaultDuration + " Hour";
      //     }
      //     value.defaultDuration = defaultDuration;
      //   });
      // }
     
      this.allServices = ServicesDataList;
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
    });
  }
  RefreshPage(){
    this.ngOnInit()
  }
  /***************************on Change service Data ********************************** */
  onServiceChange(serviceId) {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.allDoctors = [];
    let serviceData = this.allServices.find(x => x['id'] == serviceId)
    this.bcrm_servicename = serviceData.name;
    this.bcrm_serviceid = serviceData.id;
    this.ServiceDuration = serviceData.defaultDuration;


    // let spiltTime = serviceData.defaultDuration.split(" ");
    // if (spiltTime.length > 0) {

    //   this.ServiceDuration = spiltTime[1] == "Mins" ? Number(spiltTime[0]) : Number(spiltTime[0]) * 60;
    // }


    /**Call Functions */
    this.getDoctorOnServiceChange(serviceData['id']);

  }
  /***************************on Change Doctor Data ********************************** */
  getDoctorOnServiceChange(serviceId): void {
    this.BookServies.getDoctorsByServicId(serviceId).subscribe((res) => {
      this.allDoctors=res;
      // var Doc_filter_list = []
      // var SelectedDateFilter = new Date();
      // var selectedDay = SelectedDateFilter.getDay() == 0 ? 6 : SelectedDateFilter.getDay() - 1;
      // $.each(res, function (index, value) {
      //   if (value.WorkingHours[selectedDay].TimeSlots.length > 0) {
      //     Doc_filter_list.push(value);
      //   }
      // });
      // if (Doc_filter_list.length > 0) {
      //   this.allDoctors = Doc_filter_list;
      // }
      // else {
      //   this.toastr.info("No Doctors Available for this Date");
      // }

      //Call Get Question Funtion
      this.onServiceQuestions(serviceId);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
    });
  }
  /***************************on Change service Data ********************************** */
  onServiceQuestions(serviceId) {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.Questions = [];
    this.requestserive.getQuestionsByServiceId(serviceId).subscribe((res) => {

      this.Questions = res;
      $("#PhoneNumber").val(this.UserPhoneNumber)
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }

   // preferred contact
   getpreferrredContact(value){
    debugger;
    this.preferredcontactmethod=value
  }
  /***************************Crating Appointment EMR ********************************** */
  onSubmit(lobby: NgForm): void {
debugger;
    let requestObj = lobby.value;
    let selectedService = this.allServices.find(x => x['id'] == requestObj['bcrm_servicename']);
    let selectedDoctor = this.allDoctors.find(x => x['Id'] == requestObj['doctorfullname']);
    /**PArsing Questions */
    let Questions_AnsList = [];
    $.each(this.Questions, function (index, value) {

      let dataToPush = {
        id: value["Id"],
        question: value["Name"],
        answer: $("#" + value["Id"]).val()
      }
      Questions_AnsList.push(dataToPush);
    });
    var phone = $("#PhoneNumber").val();;
    if (phone != "") {
      this.PhoneNumber = phone;
    }

    let CurrentDate = new Date();
    debugger;
    let RequestAppointmentsDataToPost = {
      /**Basic Details */
      contactid: environment.LoginUserId,
      contactName: requestObj.patientName,
      contactemail: requestObj.patientEmail,
      contactPhone: this.PhoneNumber,
      subject: requestObj.subject,
      comments: requestObj.msemr_comment,
      priority: Number(requestObj.Priority),
      anyThingNewSinceYourLastAppointment: requestObj.bcrm_anythingnewsinceyourlastappointment,
      funded: Number(requestObj.isFunded),
      participantStatus: "935000002",
      /**Service Details */
      serviceName: selectedService != undefined ? selectedService['name'] : "",
      serviceId: selectedService != undefined ? selectedService['id'] : "",
      serviceDuration: this.ServiceDuration,
      startDate: CurrentDate.toISOString(),
      endDate: new Date(CurrentDate.setMinutes(this.ServiceDuration)).toISOString(),
      /**Staff  Details */
      staffName: selectedDoctor != undefined ? selectedDoctor['name'] : "",
      staffEmailAddress: selectedDoctor != undefined ? selectedDoctor['email'] : "",
      staffId: selectedDoctor != undefined ? selectedDoctor['Id'] : "",
      preferredContactMethod:this.preferredcontactmethod,
      /**Question Json */
      customerResponse: JSON.stringify(Questions_AnsList),
      appointmentType: "101",//Single Request 
      relatedPersonCollection: [{
        id: environment.LoginUserId,
        Name: requestObj.patientName,
        Email: requestObj.patientEmail,
        Phone: this.PhoneNumber
      }]
    };
    this.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.RequestAppointmentsData = RequestAppointmentsDataToPost;
    this.ServiceName = RequestAppointmentsDataToPost.serviceName;
    this.DoctorFullName = RequestAppointmentsDataToPost.staffName;

    this.patient_Name = RequestAppointmentsDataToPost.contactName;
    this.patient_Email = RequestAppointmentsDataToPost.contactemail;
    this.patient_MobilePhone = RequestAppointmentsDataToPost.contactPhone;

    this.Subject = RequestAppointmentsDataToPost.subject;
    this.Comment = RequestAppointmentsDataToPost.comments;
    this.bcrm_anythingnew = RequestAppointmentsDataToPost.anyThingNewSinceYourLastAppointment
    this.requestDate = new Date();
  
  }


  CheckPatientConsent(AppointmentData) {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    /**---------- null the Object------------- */

    this.BookServies.CheckPatientConsentStatus(environment.LoginUserId).subscribe((res) => {

      if (res) {
        this.createAppointmentEMR(AppointmentData);
      }
      else {
        this.toastr.warning("", "We do not have your signed consent. These are normally reviewed and approved daily. If you think your consents should have already been processed please email contact@oxvc.health");
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
      }
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.info(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }


  createAppointmentEMR(DataOfEMR): void {
debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    this.requestserive.addAppiontmentERM(DataOfEMR).subscribe((res) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      debugger;
      if (res) {
        this.toastr.success("Request Appointment Successfully");
        $('#Modal1').modal('hide');
        // window.location.reload();
        this.router.navigate(['/root'], { skipLocationChange: true });
      }
      else {
        this.toastr.info(res.toString(), "Info");
      }
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  //****************** Generic Fuction to filter the Service on the basis of the hidden from Customers ********************** */
  filterServiceOfType(condition) {
   // return this.allServices.filter(x => x.isHiddenFromCustomers == condition);
  }
}
