<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="page-content">

      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <h6 class="card-title">Procedures</h6> -->
              <!-- <p class="card-description">Read the <a href="https://datatables.net/" target="_blank"> Official DataTables Documentation </a>for a full list of instructions and other options.</p> -->
              <div class="row heading">
                <div class="col-md-4">

                  <h5>  Invoices <span style="color: gray;"></span></h5>
                </div>

                <div class="col-md-8">&nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                  (click)="RefreshPage()"><i class="fa fa-refresh ref"
                      aria-hidden="true"></i></button>
                      &nbsp; </div>

              </div>
              <hr>
              <div class="table-responsive">
                <table class="table" id="dataTableExample">
                  <thead>
                    <tr>
                      <th>Invoice Name</th>
                      <th>Invoice Id</th>
                      <th>Total Amount</th>
                      <th>Currency </th>
                      <th> Status</th>
                      <th>Created On</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let invoice of invoiceList">
                        <td>{{invoice.name}}</td>
                        <td>{{invoice.invoicenumber}}</td>
                        <td>{{invoice.totalamount}}</td>
                        <td>{{invoice.transactioncurrencyidName}}</td>
                        <td>{{invoice.statuscode}}</td>
                        <td>{{invoice.createdon | date:'dd-MMM-yyyy'}}</td>
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>