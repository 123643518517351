<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                        <div class="row heading">
                            <div class="col">
                                <h5> Waiting Room <span style="color: gray;"></span>
                                </h5>
                            </div>

                            <!-- <p><a href="index.html">Home /</a> Profile</p> -->

                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="headingsection">
                            <div class="container containerspacing1">
                                <ul class="ulstyle">
                                    <li class="liststyle"><img src="../../../assets/images/serviceactive.png" height="80" id="your-image">
                                        <p><a class="link">Our Services</a></p>
                                    </li>
                                    <li class="liststyle"><img src="../../../assets/images/question.png" height="80" id="your-image2">
                                        <p> <a class="link">Questions</a></p>
                                    </li>
                                    <li class="liststyle"><img src="../../../assets/images/details.png" height="80" id="your-image3">
                                        <p><a class="link">Details</a></p>
                                    </li>
                                    <li class="liststyle"><img src="../../../assets/images/summary.png" height="80" id="your-image4">
                                        <p><a class="link">Summary</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- MultiStep Form -->
                        <div class="container-fluid">
                            <div class="row justify-content-center mt-0">
                                <div class="col-md-12 mx-0">
                                    <form id="msform" #lobby="ngForm" (ngSubmit)="onSubmit(lobby)" #f="ngForm" autocomplete="off">
                                        <fieldset>
                                            <div class="form-card mb-3">
                                                <p class="request"><b>Service Information</b></p>
                                              
                                                <div class="row">
                                                    <div class="col-md-6">
                                                      <!-- Service  -->
                                                    <select class="list-dt required form-control" id="Service" name="bcrm_servicename" ngModel #bcrm_servicename="ngModel" #name="ngModel" required (change)="onServiceChange($event.target.value)">
                                                        <option value="" selected disabled>Select Service</option>
                                                        <option *ngFor="let services of allServices" value="{{services.id}}">
                                                            {{services.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                      <!-- Doctors  -->
                                                <select class="list-dt required form-control" id="Doctors" name="doctorfullname" ngModel #doctorfullname="ngModel" #name="ngModel" required>
                                                    <option value="" selected disabled>Select Doctor</option>
                                                    <option value="anyOne">Any One</option>
                                                    <option *ngFor="let Doctors of allDoctors" value="{{Doctors.Id}}">
                                                        {{Doctors.name}}</option>
                                                </select>
                                                </div></div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                      <!-- Priority  -->
                                                <select class="list-dt required form-control" id="Priority" name="Priority" ngModel #Priority="ngModel" #name="ngModel" required>
                                                    <option value="" selected disabled>Select Priority</option>
                                                    <option value="2">High</option>
                                                    <option value="1">Normal</option>
                                                    <option value="0">Low</option>
                                                </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <!-- Subject  -->
                                                <input type="text" name="subject" id="subject" placeholder="Enter Subject" ngModel class="form-control" #subject="ngModel" #name="ngModel" required />
                                            
                                                </div></div>
                                              </div>
                                            <input type="button" name="next" class="next action-button" value="Next Step" id="Servicess" />
                                        </fieldset>
                                        <!-- fieldsets quesnation Answare -->
                                        <fieldset>
                                            <div class="form-card mb-3">
                                                <p class="request"><b>Personal Questions</b></p>
                                                <div *ngFor="let quesnationList of Questions; let i = index;" style="text-align: left; margin-left: 2%;">
                                                    <label for="Questions">Qns {{i+1}}: {{quesnationList.Name}}</label>
                                                    <input class="form-control" type="text" id="{{quesnationList.Id}}" name="quesnation1" placeholder="Answer" />
                                                </div>
                                            </div>
                                            <input type="button" name="previous" class="previous action-button-previous" value="Previous" id="Personalque1" /> <input type="button" name="next" class="next action-button" value="Next Step" id="Personalque" />
                                        </fieldset>
                                        <!-- fieldsets Basic Details section -->
                                        <fieldset>
                                            <div class="form-card mb-3">
                                                <p class="request"><b>Basic Information</b></p>
                                                <div class="row">
                                                    <div class="col-md-6 step3">
                                                        <input type="text" name="uname" placeholder="Full Name" class="form-control" [(ngModel)]="FullName" name="patientName" id="patientName" ngModel #patientName="ngModel" #name="ngModel" required />
                                                        <br>
                                                        <input type="email" [(ngModel)]="emailaddress1" name="patientEmail" class="form-control" id="patientEmail" placeholder="someone@example.com" ngModel #patientEmail="ngModel" #name="ngModel" required />
                                                    </div>
                                                    <div class="col-md-6 step3">
                                                        <input type="text" name="phone" placeholder="phone Number" [(ngModel)]="UserPhoneNumber" name="PhoneNumber" id="PhoneNumber" ngModel #PhoneNumber="ngModel" class="form-control phonecode" #name="ngModel" ng2TelInput (countryChange)="onCountryChange($event)"
                                                            (intlTelInputObject)="telInputObject($event,UserPhoneNumber)" />
                                                        <br>
                                                        <select class="form-control required" id="isFunded" name="isFunded" [(ngModel)]="NhsValue" ngModel #isFunded="ngModel" #name="ngModel" selected>
                                                            <!-- <option value="" selected disabled>Funded Type</option>
                                                            <option value="1">Private</option> -->
                                                            <option value="2" selected="selected"> NHS</option>
                                                            <!-- <option value="3">Insurance</option> -->
                                                        </select>
                                                    </div>
                        
                                                </div>
                                                <br>
                                                <div class="row col-md-6">
                                                    <select class="list-dt iscontact required
                                                    " id="MethodofContact" name="preferredcontactmethod" ngModel #preferredcontactmethod="ngModel" #name="ngModel" class="form-control" (change)="getpreferrredContact($event.target.value)" required>
                                                    <option value="" selected disabled>Preferred Method Of Contact</option>
                                                    <option value="1">Telephone</option>
                                                    <option value="2">Video</option>
                                                    <option value="3">FaceToFace</option>
                                                    </select>
                                                </div>
                                                <br>
                                                <label class="control-label" style="float: left;" for="#">Has anything changed since you last
                                                    saw a doctor?</label>
                                                <div>
                                                    <textarea name="bcrm_anythingnewsinceyourlastappointment" class="form-control" id="bcrm_anythingnewsinceyourlastappointment" cols="30" rows="2" placeholder="Notes/comments." ngModel #bcrm_anythingnewsinceyourlastappointment="ngModel" #name="ngModel"></textarea>
                                                </div>
                        
                                                <label class="control-label" style="float: left;" for="#">Notes</label>
                                                <div>
                                                    <textarea name="msemr_comment" class="form-control" id="msemr_comment" cols="30" rows="2" placeholder="Notes/comments for Doctor." ngModel #msemr_comment="ngModel" #name="ngModel"></textarea>
                                                </div>
                                            </div>
                                            <input type="button" name="previous" class="previous action-button-previous" value="Previous" id="Details1" />
                                            <input type="submit" name="make_payment" class="next action-button" value="Next Step" id="Details" />
                                        </fieldset>
                                        <!-- fieldsets Final Summary Section  -->
                                        <fieldset>
                                            <div class="form-card mb-3">
                                                <p class="request text-center"><b>Confirm </b></p>
                                                <p class="details">{{ServiceName}} booked with {{DoctorFullName}} on {{requestDate | date:'dd-MMM-yyyy'}} at {{ requestDate | date:'HH:mm' }} for {{patient_Email}} {{patient_MobilePhone}} </p>
                                            </div>
                                            <input type="button" name="previous" class="previous action-button-previous" value="Previous" />
                                            <input type="button" name="make_payment" (click)="CheckPatientConsent(RequestAppointmentsData)" [disabled]="!lobby.valid" class="action-button" value="Submit" id="Summary" />
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>