<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container containerspacing">
        <div class="row" style="justify-content: center;">
            <p class="hcolor">Book an Appointment</p>
        </div>
    </div>
</div>
<!--  -->
<!-- changes -->
<div class="headingsection">
    <div class="container containerspacing">
        <ul class="ulstyle">
            <li class="liststyle"><img src="../../../assets/images/serviceactive.png" height="80" id="your-image">
                <p><a class="link">Our Services</a></p>
            </li>
            <li class="liststyle"><img src="../../../assets/images/doctor.png" height="80" id="your-image1">
                <p><a class="link">Available Doctors</a></p>
            </li>
            <li class="liststyle"><img src="../../../assets/images/question.png" height="80" id="your-image2">
                <p> <a class="link">Questions</a></p>
            </li>
            <li class="liststyle"><img src="../../../assets/images/details.png" height="80" id="your-image3">
                <p><a class="link">Details</a></p>
            </li>
            <li class="liststyle"><img src="../../../assets/images/summary.png" height="80" id="your-image4">
                <p><a class="link">Summary</a></p>
            </li>
        </ul>
    </div>
</div>
<!--  -->
<!-- <br> -->
<!-- MultiStep Form -->
<div class="container">
    <div class="row justify-content-center mt-0">
        <div class="row">
            <div class="col-md-12">
                <form id="msform" #appointment="ngForm" (ngSubmit)="onSubmit(appointment)" #f="ngForm">
                    <!-- progressbar -->

                    <fieldset>
                        <div class="row">
                            <form class="navbar-form col-md-12" autocomplete="off">

                                <div class="form-group row bmd-form-group">
                                    <span class="bmd-form-group">
                                        <div class="input-group no-border">
                                            <h5>Search Services</h5>
                                        </div>
                                    </span>
                                    <div class="col-lg-3">
                                        <div class="search">
                                            <i class="fa fa-search"></i>
                                            <input type="text" class="form-control" placeholder="Search....." name="search" [(ngModel)]="searchText">
                                        </div>
                                        <!-- <input class="form-control1 round" type="text" name="search" [(ngModel)]="searchText"> -->

                                    </div>
                                    <!-- <div class="col-lg-1" style="margin: 1% 0% 0% -11%;"><img src="../../../assets/images/Search.png" style="height: 25px;"></div> -->

                                    <!-- <div class="col-lg-3">
                                        <div class="form-group row bmd-form-group">
                                            <div class="col-sm-1">
                                                <input type="checkbox" id="togBtn" name="Guardian" [(ngModel)]="GuardianValue"
                                                (change)="ChecktheGuardianValue($event)">
                                            </div>
                                            <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-2">
                                        <select name="bcrm_patientguardian" [(ngModel)]="bcrm_patientguardianvalue" id="bcrm_patientguardian" (change)="ChecktheGuardianValue($event)" ngModel #bcrm_patientguardian="ngModel" class="form-control">
                                            <option value="" disabled>Select Guardian</option>
                                            <option value={{userMainId}}>{{userMainname}} (Me)</option>
                                            <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                                            </option>
                                          </select>
                                    </div>

                                    <div class="col-lg-3" id="GuardianProxy" *ngIf="GuardianValue == true">
                                        <div class="form-group row bmd-form-group">
                                            <!-- <div class="col-sm-1">
                                                <input type="checkbox" id="togBtn" name="Guardian" [(ngModel)]="GuardianValue" disabled>
                                            </div> -->
                                            <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                                        </div>
                                    </div>

                                </div>


                            </form>
                            <hr>
                        </div>
                        <div class="row scroll" style="height:350px;overflow: auto; padding: 2%;">
                            <div class="col-md-4" style="width: 800px;" *ngFor="let services of allServices |filter:searchText">
                                <div class="card" id='{{services.id}}_card' style="width: 390px; max-width: 800px;">
                                    <div class="card-body">
                                        <!-- <h5 class="pull-right"><i class="fa fa-info-circle" aria-hidden="true" (click)="showInfoInPopUp(services)"></i>
                                        </h5> -->
                                        <div class="card-title" (click)="getDoctorsListByService(services)">
                                            <h5 class="pull-left" id="coloractive">{{services.name}} </h5>
                                        </div>
                                        <table class="desktop_view">
                                            <!-- <tr>
                                                <td style="text-align: left;"> <span>price: </span> £{{services.defaultPrice}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span>Duration:</span> {{services.defaultDuration}}
                                                </td>
                                            </tr> -->
                                        </table>
                                        <button class="btn btn-primary selectbtn" id='{{services.id}}_selectbtn' style="float: right;" id="btn" (click)="getDoctorsListByService(services)">Select</button>
                                        <p (click)="getDoctorsListByService(services)"> <span class="pull-right"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="button" name="next" class="next1 action-button" value="Next Step" id="Services" />


                    </fieldset>

                    <!-- fieldsets Doctors and Time Slots Answare -->
                    <fieldset style=" background-color: #F0F3F8;
                            border: 1px solid #ADC1D0;width: 1100px;max-width: 1100px;">
                        <div class="row" style="margin-right: 0px; margin-top: 2%;">
                            <div class="col-md-6">
                                <div class="text p-4 text-center">
                                    <div id="datepicker"></div>
                                    <input type="hidden" id="my_hidden_input">
                                </div>
                            </div>
                            <div class="col-md-6" style="text-align: initial; font-weight: 600;font-size: 16px;margin-bottom: 2%;">
                                <p>
                                    Select The Doctor to See Available Times
                                </p>
                                <select class="list-dt" (change)="onDoctorChange($event.target.value)" name="Dom_staffId" [(ngModel)]="Dom_staffId" class="form-control round" required>
                                    <option value="">Select Doctor</option>
                                    <option *ngFor="let Doctors of selectedServiceDoctors" value="{{Doctors.Id}}">
                                        {{Doctors.name}}</option>
                                </select>
                                <p *ngIf="IsTimeSlotArray" style="margin-top: 3%;">
                                    <span class="TimeSlot" *ngFor="let TimeSlot of TimeSlotArray" (click)="onTimeSelect($event,TimeSlot)">{{TimeSlot | date:'HH:mm'}}</span>
                                </p>

                            </div>
                        </div>
                        <input type="button" name="previous" class="previous action-button-previous mr-2" value="Previous" id="Services1" />
                        <input type="button" name="next" class="next1 action-button" value="Next Step" id="Doctors" />
                    </fieldset>

                    <!-- fieldsets quesnation Answare -->
                    <fieldset style="background-color: #F0F3F8;
                            border: 1px solid #ADC1D0;width: 1000px; max-width: 1000px;">
                        <h3 class="request ml-2"><b>Personal Questions</b></h3>
                        <div *ngFor="let quesnationList of Questions; let i = index;" class="mb-3" style="text-align: left; margin-left: 2%;">
                            <label>Qns {{i+1}}: {{quesnationList.Name}}</label> <br>
                            <div class="row">
                                <div class="col-md-10"><input type="text" id="{{quesnationList.Id}}" name="quesnation1" placeholder="Answer" class="form-control round" />
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>

                        <input type="button" name="previous" class="previous action-button-previous mr-2" value="Previous" id="Doctors1" />
                        <input type="button" name="next" class="next1 action-button" value="Next Step" id="Questions" />
                    </fieldset>

                    <!-- fieldsets Basic Details section -->
                    <fieldset style="background-color: #F0F3F8;
                            border: 1px solid #ADC1D0;width: 1000px;max-width: 1000px;padding: 1%;">
                        <div class="basic-information">
                            <h3 class="request"><b>Basic Information</b></h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" name="uname" placeholder="Full Name" [(ngModel)]="userEmail" name="Email" id="Email" placeholder="Email" ngModel #Email="ngModel" #name="ngModel" class="form-control round" required disabled />
                                    <br>

                                    <input type="text" name="subject" [(ngModel)]="Subject" id="subject" placeholder="Subject" ngModel #subject="ngModel" #name="ngModel" required class="form-control round" />
                                    <br>

                                    <select class="list-dt iscontact required
                                    " id="MethodofContact" name="preferredcontactmethod" ngModel #preferredcontactmethod="ngModel" #name="ngModel" required class="form-control round" (change)="getpreferrredContact($event.target.value)">
                                    <option value="" selected disabled>Preferred Method Of Contact</option>
                                    <option value="1">Telephone</option>
                                    <option value="2">Video</option>
                                    <option value="3">FaceToFace</option>
                                    </select>
                                    <br>



                                </div>

                                <div class="col-md-6">
                                    <input type="text" name="phone" id="Phone" placeholder="Phone" ngModel #phone="ngModel" [(ngModel)]="userMobile" #name="ngModel" ng2TelInput (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event,userMobile)" class="form-control round"
                                    />
                                    <br>

                                    <div>
                                        <label class="control-label" for="#" style="float: left;color: black;">&nbsp;&nbsp;Anything new since your last
                                            appointment?</label>
                                        <textarea name="lastAppointmentComment" rows="3" class="form-control" id="lastAppointmentComments" cols="30" [(ngModel)]="lastAppointmentComments" placeholder="Notes/Comments" class="form-control round" required></textarea>
                                    </div>
                                    <!-- <select class="list-dt funded required
                                            " id="isFunded" name="isFunded" ngModel #isFunded="ngModel" #name="ngModel" required class="form-control round">
                                        <option value="" selected disabled>Funded Type</option>
                                        <option value="1">Private</option>
                                        <option value="2">NHS</option>
                                        <option value="3">Insurance</option>
                                    </select>
                                    <br> -->

                                    <div>
                                        <!-- <label class="control-label" for="#" style="float: left;color: black;">&nbsp;&nbsp;Notes</label> -->
                                        <!-- <textarea name="message" rows="3" class="form-control" id="message" cols="30" [(ngModel)]="Notes" placeholder="Doctors Notes" class="form-control round" required></textarea> -->
                                    </div>
                                </div>
                            </div>


                        </div>
                        <input type="button" name="previous" class="previous action-button-previous mr-2" value="Previous" id="Questions1" />
                        <input type="submit" name="make_payment" class="next1 action-button" value="Next Step" id="Details" [disabled]='!appointment.valid' />
                    </fieldset>

                    <!-- fieldsets Final Summary Section  -->
                    <fieldset style="background-color: #F0F3F8;
                            border: 1px solid #ADC1D0;width: 1000px;max-width: 1000px;padding: 1%;">
                        <div class="">
                            <p class="request ml-1"><b>Confirm </b></p>
                            <div class="table-responsive" style="overflow-x: hidden;">
                                <!-- <table class="table">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <tr>
                                                <th>Service Name</th>
                                                <th>:</th>
                                                <td>{{ServiceName}}</td>
                                            </tr>
                                            <tr>
                                                <th>Doctor Name</th>
                                                <th>:</th>
                                                <td>{{DoctorFullName}}</td>
                                            </tr>
                                            <tr>
                                                <th>Subject</th>
                                                <th>:</th>
                                                <td>{{Subject}}</td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <th>:</th>
                                                <td>{{patient_Name}}</td>
                                            </tr>
                                        </div>
                                        <div class="col-md-6">
                                            <tr>
                                                <th>Email</th>
                                                <th>:</th>
                                                <td>{{patient_Email}}</td>
                                            </tr>
                                            <tr>
                                                <th>MobilePhone</th>
                                                <th>:</th>
                                                <td>{{patient_MobilePhone}}</td>
                                            </tr>
                                            <tr>
                                                <th>Anything new since your last appointment?</th>
                                                <th>:</th>
                                                <td>{{bcrm_anythingnew}}</td>
                                            </tr>
                                        </div>

                                </div>


                                </table> -->
                                <p class="details">{{ServiceName}} booked with {{DoctorFullName}} on {{startDateTime | date:'dd-MMM-yyyy'}} at {{ startDateTime | date:'HH:mm' }} for {{patient_Email}} {{patient_MobilePhone}} </p>

                                <hr>
                                       
                                        <div class="row">
                                            <div class="col-6 mb-2" *ngFor="let qna of QNA">
                                                <th class="col-static"> {{qna.question}}  : <a class="text-muted" style="font-weight: 400;"> {{qna.answer}}</a></th>
                                            </div>
                                        </div> 
                            </div>
                        </div>

                        <input type="button" name="previous" class="previous action-button-previous mr-2 mt-2" value="Previous" id="Details1" />
                        <input type="button" name="make_payment" (click)="Submit(RequestAppointmentsData)" class="action-button mt-2" value="Submit" id="Summary" />
                    </fieldset>
                    <br>
                </form>
            </div>
        </div>
    </div>


</div>


<div class="modal fade" id="showServiceDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header  text-center">
                <h5 class="modal-title text-center" id="servicedisplayName"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div id="servicedescription"></div>
                <br>
                <p> price : £ <span id="servicesdefaultPrice"></span><span class="pull-right" id="servicesdefaultDuration"></span>
                </p>
                <p id="servicesstaffMemberIdslength"> </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ok</button>
            </div>
        </div>
    </div>
</div>