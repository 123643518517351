import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class BookAppointmentsServiesService {

  constructor(private http: HttpClient) { }

  getAllServices() {
    return this.http.get(`${environment.apiUrl}/api/OXDHService/GetService`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  getAllServicesByEmail(email) {
    return this.http.get(`${environment.apiUrl}/api/OXDHService/GetServiceByStaffEmail?email=${email}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  CheckPatientConsentStatus(userId:string) {
    return this.http.get(`${environment.apiUrl}/api/CrmUser/CheckConsent?userGuid=${userId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  getDoctorsByServicId(ServiceId) {
    return this.http.get(`${environment.apiUrl}/api/OXDHStaff/GetStaffByServiceId?Id=${ServiceId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  getStaffAvailableTime(RequestObject) {
    return this.http.post(`${environment.apiUrl}/api/OXDHAppointments/GetSchedule`, RequestObject).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  paymentProcess(amount, stripeToken) {
    return this.http.post(`${environment.apiUrl}/api/Payment/Create`, { email: environment.LoginUserEmail, totalAmount: amount, token: stripeToken.id }).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  createAppointment(Appointments) {
    return this.http.post(`${environment.apiUrl}/api/OXDHAppointments/CreateConfirmAppointment`, Appointments).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  
  handleError(error) {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessageUi = error.error.message
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}
