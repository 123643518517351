import { Component, OnInit } from '@angular/core';
import { PrescriptionsService } from 'src/app/ApiServices/Prescription/prescriptions.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { identifierModuleUrl } from '@angular/compiler';

declare var $: any;

@Component({
  selector: 'app-newprescriptions',
  templateUrl: './newprescriptions.component.html',
  styleUrls: ['./newprescriptions.component.css']
})
export class NewprescriptionsComponent implements OnInit {
  prescriptions :object;
  Obj:any;
  drugs;
  drugList;
  prescriptionName : any;
  prescriptiondrugs:object;
  prescriptiondrugsdropdown:any;
  prescriptiondruglist=[];
  drugData:any;
  items = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Repeat' },
  ];
  selectedQuantity = "1";
  constructor(private prescription: PrescriptionsService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
   
    let date_ob = new Date();
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // current year
    let year = date_ob.getFullYear();
    // current hours
    let hours = date_ob.getHours();
    // current minutes
    let minutes = date_ob.getMinutes();
    // current seconds
    let seconds = date_ob.getSeconds();
    var d = date + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
    this.prescriptionName = "Prescription_"+ d +"";
    $("#Prescription").hide();
    // $('.js-example-basic-multiple').select2();
    this.getPrescriptionDrugs(environment.LoginUserId);
    this.getAlldrugs();
    this.getPrescriptionsDrugsByPatientId();
  }
  getPrescriptionsDrugsByPatientId(): void {
    
    this.Obj = null;
    //*************Show / hide loader *******************/
   // $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.getPrescriptionsDrugsByPatientId(environment.LoginUserId).subscribe((res) => {
      if(res != null){
        
        this.prescriptiondrugsdropdown = res;
      }
      $("#your-image6").attr("src", "../../../assets/images/Prescriptions.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
    
  }
  getAlldrugs(): void {
   
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.getAlldrugs().subscribe((res) => {
      if(res != null){
        
        this.drugs = res;
        var option = '';
        for (var i = 0; i < this.drugs.length; i++) {
             var optionvalue = this.drugs[i];
               option += "<option value='" + optionvalue.id + "'>" + optionvalue.name + "</option>";
          }
          $('.js-example-basic-multiple').html(option);
          $('.js-example-basic-multiple').select2();
      };
     
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
    
  }

  onType(event): void {
    
    if (event == "1") {
      $("#drugs").show();
      $("#Prescription").hide();
    }
    else{
      $("#Prescription").show();
      $("#drugs").hide();
    }
  }
  addDrugIntoList():void{
    debugger
    let id = $("#drugid").val();
    let comment = $("#comments").val();
    let currentDrug = this.prescriptiondrugsdropdown.find(x => x['Id'] === id);

    let AddIntoPrescription = {
        Id: currentDrug.id,
        bcrm_name: currentDrug.name,
        bcrm_startcomment: comment,
        bcrm_dosageunit:currentDrug.bcrm_dosageunit,
        bcrm_frequency:currentDrug.bcrm_frequency,
        bcrm_dosage:currentDrug.bcrm_dosage,
    };
    this.prescriptiondruglist.push(AddIntoPrescription);
    debugger;
    $("#drugid").val("");
    $("#comments").val("");
  }
  removedrugs(drugid):void{
    
    let localThis=this;
    if (drugid !== null && drugid !== undefined) {
      let refreshList = this.prescriptiondruglist;
      localThis.prescriptiondruglist = [];
      refreshList.forEach(function (item) {
        debugger;
          if (drugid !== item.Id) {
            localThis.prescriptiondruglist.push(item);
          }
      });
  }
  }

  onSubmit(it: NgForm) {
    
    $("#Modal1").modal("toggle");
    $('#ftco-loader').addClass('show');
    this.Obj = it.value;
    var drugListArray = [];
    this.drugList = $("#drugList").val();
    for (var i = 0; i < this.drugList.length; i++) {
      let dataToPush={
        Id:this.drugList[i]
      };
      drugListArray.push(dataToPush);
    }
    this.Obj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.Obj.Prescrip_drug = drugListArray;
    this.Obj.prescriptiondrugrepeat = this.prescriptiondruglist;
    this.prescription.CreateNewPrescription(this.Obj, environment.LoginUserId).subscribe((res) => {
      
      if (res == "Success") {
        this.toastr.success("Created New Successfully");        
        this.getPrescriptionDrugs(environment.LoginUserId);
        $("#bcrm_reason").val("");
        $('#drugList').val(null).trigger('change');
       
       // $('#Modal1 bcrm_reason')[0].reset();
       // window.location.reload();
      }
      else if(res == "Expiry Date Older than Today's Date"){
        this.toastr.warning("Prescription you want to repeat is of expired date");
      }
      else {
        this.toastr.error("Error while creating prescription");
      }
      $('#ftco-loader').removeClass('show');
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  getPrescriptionDrugs(id): void {
    
   // $(".hide-table-padding").show();
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.getPrescriptionDrugs(id).subscribe((res) => {
      
      if(res != null){
        this.prescriptiondrugs = res;
      }
      setTimeout(function () {
        $('#prescriptionRecord').DataTable();
    }, 50);
  
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

}
