<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row pt-4">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row heading">
                                <div class="col">
                                    <h5> Waiting Room <span style="color: gray;"></span>
                                    </h5>
                                </div>

                                <!-- <p><a href="index.html">Home /</a> Profile</p> -->

                            </div>
                        <div class="headingsection mb-4">
                            <div class="containerpadding">
                                <div class="row pt-2">
                                    <div class="col-md-6 col-lg-3 col-xs-12 col-sm-12">
                                        <p class="h1color">Find a Clinician</p>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <form class="navbar-form" autocomplete="off">
                                            <span class="bmd-form-group">
                                                <div class="input-group no-border">
                                                    <h5 class="responsive">Search Departments in list</h5>
                                                </div>
                                            </span>
                                        </form>
                                    </div>
                                    <div class="col-lg-3 col-md-6 doctorow">
                                        <select class="js-example-basic-single round" placeholder="Enter name"
                                            id="mySelect2" (change)="allDoctorsDataByDept($event.target.value)">
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-md-5">
                                        <h5 class="responsive">Search Clinicians in list</h5>
                                    </div>
                                    <div class="col-lg-3 col-md-6 ">
                                        <form class="navbar-form" autocomplete="off">
                                            <span class="bmd-form-group">
                                                <div class="input-group no-border">
                                                    <input class="form-control round" type="text" name="search"
                                                        [(ngModel)]="searchText" placeholder="Search Doctors in list">
                                                        <button class='btn btn-custom round'><i class="fa fa-search"
                                                            aria-hidden="true"></i></button>
                                                </div>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <section class="ftco-section bg-light"> -->
                        <section class="m-4 bg-light">
                            <div class="container-fluid px-md-0">
                                <div class="row">
                                    <div class="col-md-12" *ngIf="allDeparments">
                                        <div class="">
                                            <div class="">
                                                <div class="row"
                                                    style=" height:700px;overflow: auto;background-color: #fff;">
                                                    <div class="col-md-4 col-lg-3 col-sm-6 col-xs-12 large"
                                                        *ngFor="let doctors of allDoctors | filter:searchText">
                                                        <div class="card testimonial-card">
                                                            <div class="avatar mx-auto white">
                                                                <!-- <img *ngIf="!doctors.ProfilePhoto" src="./../assets/images/img_avatar2.png" class="rounded-circle" alt="user avatar" style="width: 150px; height: 150px;"> -->
                                                                <img *ngIf="doctors.ProfilePhoto"
                                                                    src="data:image/jpeg;base64,{{doctors.ProfilePhoto}}"
                                                                    class="rounded-circle" alt="woman avatar"
                                                                    style="width: 150px; height: 150px;">
                                                            </div>
                                                            <div class="card-body">
                                                                <div><a class="displayname"
                                                                        [routerLink]="['/doctors', doctors.Mail ]"
                                                                        skipLocationChange>{{doctors.name}}</a></div>
                                                                <div class="displaydetails"><b>GMC Number: </b>
                                                                    <p>&nbsp; 6028541{{doctors.GMCNumber}}</p>
                                                                </div>
                                                                <div class="displaydetails"><b>Speciality: </b>
                                                                    <p>&nbsp;{{doctors.SpecialityRecognition}}</p>
                                                                </div>
                                                                <div class="displaydetails"><b>Qualification: </b>
                                                                    <p class="a">&nbsp; {{doctors.LetterQualifications}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer">
                                                                <div class="btn-group special" role="group"
                                                                    aria-label="..." *ngIf="this.isLoggedIn">
                                                                    <button type="button"
                                                                        class="btn btn-primary buttons singlebutton"
                                                                        [routerLink]="['/allDoctor', doctors.Id ]"
                                                                        skipLocationChange><strong>View</strong></button>
                                                                    <button type="button"
                                                                        class="btn  btn-primary buttons p-1"
                                                                        [routerLink]="['/bookNewApt', doctors.email ]"
                                                                        skipLocationChange><strong>Book
                                                                            Now</strong></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>