import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaseService } from 'src/app/ApiServices/Case/case.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  SendNotesobj: any = {};
  caseId: any;
  msg: any;
  file: any;
  LoginUserfullName: any;
  consultationnotesName:any;
  CaseType :any;
  CreatedOn :any;
  StatusOnlineConsultation:any;
  constructor(private caseService: CaseService, private toastr: ToastrService, private router: Router) { }


  ngOnInit(): void {
    debugger;
    this.consultationnotesName = localStorage.ConsultationnotesName;
    this.CaseType = localStorage.CaseType;
    this.CreatedOn = localStorage.createdOn;
    this.StatusOnlineConsultation = localStorage.StatusOnlineConsultation;
    this.getNotes();

  }
  IncomingFile(event) {

    this.file = event[0];
  }


  getNotes(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.msg = null;
    this.caseService.getNotes(localStorage.CaseID).subscribe((res) => {
      debugger;
      if (res != null) {
        this.msg = res;
        this.LoginUserfullName = JSON.parse(localStorage.userProfileDataClaims)
        // for (let i = 0; i < this.msg.length; i++) {
        //   if (this.msg[i].FileName != "") {
        //     this.msg[i].extension = this.msg[i].FileName.split('.').pop();
        //   }
        // }
        $('#ftco-loader').removeClass('show');
        return this.msg;

      }

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/'], { skipLocationChange: true });
    });
  }

  sendNotes(SMS: NgForm) {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('description', SMS.value.description);
    formData.append('subject', SMS.value.subject);
    formData.append('caseId', localStorage.CaseID);
    formData.append('file', this.file);
    formData.append('langid', "1");
    this.caseService.createNotes(formData).subscribe((res) => {
      debugger;
      if (res) {
        // localStorage.removeItem('CaseID');
        $("#myModalcomment .close").click();
        $('#ftco-loader').removeClass('show');
        this.toastr.success("Comment added successfully");
        this.ReloadComponent();
      }
    });

  }
  backtogrid() {
    debugger
    this.router.navigate(['profile/case'], { skipLocationChange: true })

  }

  getDocument(Msg) {debugger
    var a = document.createElement("a"); //Create <a>
    a.href = "data:application/octet-stream;base64," + Msg.documentbody; //Image Base64 Goes here
    a.download = Msg.FileName //File name Here
    a.click(); //Downloaded file
  }


  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
}
