<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row heading">
                                <div class="col-md-4">
                                    <h5>  Documents <span style="color: gray;"></span>
                                    </h5>
                                </div>
                                <div class="col-md-6">&nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                    (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                        aria-hidden="true"></i></button>
                                        &nbsp; </div>
                            </div>
                            <hr>

                            <div class="card-body">
                                <div class="list-group">
                                    <a class="list-group-item active"
                                        style="color: rgba(0, 0, 0, 0.8);font-size:20px;background-color: #fff;border-color: gray;">{{'Documents.Files'|translate}} <span
                                            style="float:right;cursor:pointer;">
                                            <button type="button" class="btn btn-primary btn-sm" href="#W2P1"
                                                data-toggle="modal" >
                                                {{'Documents.createFolder'|translate}}</button>
                                        </span></a>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item active"
                                            *ngFor="let p of path ;let indexOfelement=index;" (click)="back(p)">
                                            <span *ngIf="indexOfelement==0; else elseTemplate">
                                                <a>{{'Header.home'|translate}}</a>
                                            </span>
                                            <ng-template #elseTemplate>
                                                <a>{{p}}</a>
                                            </ng-template>
                                        </li>
                                    </ol>
                                    <form class="navbar-form" autocomplete="off">
                                        <span class="bmd-form-group">
                                            <div class="input-group no-border">
                                                <input class="form-control" type="text" name="search"
                                                    [(ngModel)]="searchText"
                                                    placeholder="{{'Documents.GlobalSearchForAllDocuments'|translate}}">
                                            </div>
                                        </span>
                                    </form>
                                    <hr />
                                    <div class="row" style="padding: 1%;">
                                        <div class="col-md-7">
                                            <div class="row pt-1" style="background-color:#636566;color:white;height:35px;">
                                                <div class="col-md-6" style="font-size: 18px; font-weight: 700;">
                                                    {{'Common.name'|translate}}</div>
                                                <div class="col-md-6" style="font-size: 18px; font-weight: 700;">
                                                    {{'Documents.uploadedDate'|translate}} </div>
                                            </div>
                                            <div class="ScrollDocumnentdata">
                                                <div class="row"
                                                    *ngFor="let tdata of tableData['children'] | filter: searchText ">
                                                    <div class="col-md-6" (click)="DownloadIntoLocal(tdata)"
                                                        style="cursor:pointer;">
                                                        <i class="fa fa-{{tdata.type}}"></i> {{tdata.label}}
                                                    </div>
                                                    <div class="col-md-6">
                                                        {{tdata.date | date:'medium'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <p>{{'Documents.acceptedfile'|translate}} :-<br> (jpg, jpeg, png, xlsx,
                                                word, pdf, mp4, mp3, docx, doc, txt)</p>
                                            <div class="text-center">
                                                <input type="file" (change)="onUploadFile($event)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <br />
                            <div id="push"></div>
                            <div class="modal fade" id="W2P1" role="dialog">
                                <form class="form" #it="ngForm" (ngSubmit)="onUploadFolder(it)" #f="ngForm"
                                    autocomplete="off">
                                    <div class="modal-dialog modal-lg">
                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">{{'Documents.Information'|translate}}</h5>
                                                <!-- modal header -->
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-hidden="true" (click)="clearForm()">
                                                    ×
                                                </button>
                                            </div>
                                            <div class="modal-body">

                                                <div class="form-group">
                                                    <label
                                                        class="control-label">{{'Documents.FolderName'|translate}}<span
                                                            class="required-asterisk text-danger">*</span> :</label>
                                                    <div class="">
                                                        <input type="text" name="name" class="form-control" ngModel
                                                            required #name="ngModel"
                                                            placeholder="{{'Documents.FolderName'|translate}}"
                                                            id="foldername">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="onUploadFolder(it)" data-dismiss="modal"
                                                    [disabled]="!it.valid">{{'Documents.Submit'|translate}}</button>
                                                <!-- <input type="submit" class="btn btn-primary" value="Submit" (click)="onUploadFolder(it)" data-dismiss="modal" [disabled]="!it.valid"> -->
                                                <button type="button" class="btn btn-default"
                                                    data-dismiss="modal">{{'Common.Close'|translate}}</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>