import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SickNotesService } from 'src/app/ApiServices/Sick/sick-notes.service';
import { Router } from '@angular/router';
declare var $: any;
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
import { DomSanitizer } from '@angular/platform-browser';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-sick-note-new',
  templateUrl: './sick-note-new.component.html',
  styleUrls: ['./sick-note-new.component.css']
})
export class SickNoteNewComponent implements OnInit {


  SickData: object;
  SickDatanotmain: any;
  ProxyData: any;
  Token: string;
  isLoggedIn: boolean = false;
  UpdateSickNotes: any = {};
  allergyObj: any;
  CheckValuetrue: boolean = false;
  CheckValuetruerequest: boolean = false;
  GuardianValue: boolean = false;
  GuardianValueUpdate: boolean = false;
  userMainname: any;
  userMainId: any;
  bcrm_patientguardianvalue: any;
  Dataofthepatient: any;
  bcrm_patientguardianvalueAll: any;
  Alldatasharepoint: any;
  Urlmain: string;
  URLlink: any;
  SickNotesDataView: any;
  constructor(private sickNotesService: SickNotesService, private sanitizer: DomSanitizer,
    private router: Router,
    private toastr: ToastrService,
    private headerComponent: HeaderComponent) {
    this.Token = "";//"";

    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    debugger;
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail = data.userEamil;
    environment.LoginUserId = data.userId;
    environment.LoginUserfullName = data.fullName;
    this.userMainname = environment.LoginUserfullName;
    this.userMainId = environment.LoginUserId;
    this.bcrm_patientguardianvalue = environment.LoginUserId;
    this.bcrm_patientguardianvalueAll = environment.LoginUserId;
    this.getSickNotesAll();
    this.getAllProxy();
  }

  getSickNotesAll(): void {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getSickNotes(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.SickDatanotmain = res;
        this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardianName == environment.LoginUserfullName || !x.bcrm_patientguardianName);
      }
      //   setTimeout(function () {
      //     $('#SickNotesRecord').DataTable();
      // }, 50);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting sick notes");
      // this.router.navigate(['/']);
    });
  }


  getAllProxy() {


    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
      }

      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting proxy");
      // this.router.navigate(['/']);
    });
  }


  editSickNotes(data) {
    this.UpdateSickNotes = data;
    if (this.UpdateSickNotes['bcrm_fromdate'] && this.UpdateSickNotes['bcrm_fromdate'].length > 0)
      this.UpdateSickNotes['bcrm_fromdate'] = this.UpdateSickNotes['bcrm_fromdate'].substring(0, 10);
  }

  onSubmit(it) {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    this.allergyObj = it.value;
    this.allergyObj.bcrm_isproxy = this.GuardianValue;
    this.Dataofthepatient = it.value.bcrm_patientguardian;
    this.allergyObj.bcrm_status = '1';
    //this.allergyObj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.sickNotesService.createSickNotes(this.allergyObj, environment.LoginUserId).subscribe((res) => {

      if (res == true) {
        this.toastr.success("Sick notes successfully created");
        //   $('#SickNotes Record').DataTable().clear().destroy();
        this.SickData = null;
        this.bcrm_patientguardianvalueAll = this.Dataofthepatient;
        this.getSickNotesAllAftersubmit(this.Dataofthepatient);

        //   setTimeout(function () {
        //     $('#SickNotesRecord').DataTable();
        // }, 50);
        $('#Modal1').modal('hide');
        // this.ReloadComponent();   
        $('#Modal1 form')[0].reset();
      }
      else {
        this.toastr.error("Error while creating sick notes");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while creating sick notes");
      // this.router.navigate(['/']);
    });
  }

  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }

  showConfirm(allergyid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.value) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /**************************************** */
        this.sickNotesService.deleteSickNotes(allergyid).subscribe((res) => {

          if (res == true) {
            this.toastr.info("Deleted successfully");
            this.ReloadComponent();
          }
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error while deleting sick notes");
          //this.router.navigate(['/']);
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        );
      }
    });

  }


  onUpdate(EditSickNotesdata) {

    var datatopost = EditSickNotesdata;
    datatopost.bcrm_isproxy = this.UpdateSickNotes.bcrm_isproxy;
    this.Dataofthepatient = datatopost.bcrm_patientguardian;

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.sickNotesService.updateSickNotes(datatopost, environment.LoginUserId).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Sick notes update successfully");
        $('#ModalAll').modal('hide');
        //this.ReloadComponent();   
        this.bcrm_patientguardianvalueAll = this.Dataofthepatient;
        this.getSickNotesAllAftersubmit(this.Dataofthepatient);
      }
      else {
        this.toastr.error("Sick Notes not updated");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while updating sick notes");
      //this.router.navigate(['/']);
    });
  }


  Makethefeildempty(Event) {

    this.CheckValuetrue = Event.currentTarget.checked;
    if (this.CheckValuetrue == false) {
      $("#bcrm_pleaseprovidedetailsofwhyyouarenotfittow").val("");
      $("#purpose").val("");
      $("#purposeedit").val("");
      $("#purpose").prop('disabled', true);
      $("#purposeedit").prop('disabled', true);
    } else {
      $("#purpose").prop('disabled', false);
      $("#purposeedit").val(this.UpdateSickNotes.bcrm_doyouthinkyouwouldbenefitfromanyofthefol);
      $("#purposeedit").prop('disabled', false);
    }
  }

  Makethefeildemptyrequest(Event) {

    this.CheckValuetruerequest = Event.currentTarget.checked;
    if (this.CheckValuetruerequest == false) {
      $("#bcrm_medicalcondition").val(""); $("#purpose").prop('disabled', true);
    }
  }

  ChecktheGuardianValue(Event) {

    var DataofEvent = Event.currentTarget.value;
    if (DataofEvent != environment.LoginUserId) {
      this.GuardianValue = true
    }
    else {
      this.GuardianValue = false;
    }
  }

  ChecktheGuardianValueUpdate(Event) {

    var Updateguardianvaliue = Event.currentTarget.value;
    if (Updateguardianvaliue != environment.LoginUserId) {
      this.UpdateSickNotes.bcrm_isproxy = true
    }
    else {
      this.UpdateSickNotes.bcrm_isproxy = false;
    }
  }

  ChecktheValueofProxyandFilterdata(Event) {

    this.SickData = null;
    //   $('#SickNotesRecord').DataTable().clear().destroy();
    var Dataofproxy = Event.currentTarget.value
    this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardian == Dataofproxy);
    //   setTimeout(function () {
    //     $('#SickNotesRecord').DataTable();
    // }, 50);
  }
  printDiv() {
    // debugger
    // var printContents = document.getElementById('GFG').innerHTML;
    // var originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
  
  
    // window.print();
    // document.body.innerHTML = originalContents;
    // location.reload();
    var data = document.getElementById('GFG');
    html2canvas(data).then(canvas => {
      var imgWidth = 190;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); 

      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight)
      pdf.save(this.SickNotesDataView.msemr_patient +" Sick Note "+ this.SickNotesDataView.bcrm_assessedcaseon+".pdf"); 
    });
  }
  RefreshPage(){
    this.ngOnInit()
  }

  getPopUpData(data) {
    debugger;

    $("#PreviewModal").modal('show');
   
    this.SickNotesDataView = data;
    this.SickNotesDataView.bcrm_assessedcaseon=data.bcrm_assessedcaseon.slice(0,10);
    this.SickNotesDataView.bcrm_fromdate=data.bcrm_fromdate.slice(0,10);
    this.SickNotesDataView.bcrm_todate=data.bcrm_todate.slice(0,10);
    this.SickNotesDataView.bcrm_dateofstatement=data.bcrm_dateofstatement.slice(0,10);
  }

  Getdownloadssahrepointdocument(fullURL) {


    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.Alldatasharepoint = null;

    var URL = fullURL.split("bcrm_sicknote");

    this.sickNotesService.Getsharepointpdf(URL[1]).subscribe((results) => {
      try {
        $("#PreviewModal").modal('show');
        let file = new Blob([results], { type: 'application/pdf' });
        this.URLlink = window.URL.createObjectURL(file);
        this.URLlink = this.sanitizer.bypassSecurityTrustResourceUrl(this.URLlink);
      } catch (ex) {
        //console.log(ex);
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while getting file");
      // this.router.navigate(['/']);
    });
  }


  getSickNotesAllAftersubmit(value): void {
    // $('#SickNotesRecord').DataTable().clear().destroy();

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getSickNotes(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.SickDatanotmain = res;
        //  this.SickData =  this.SickDatanotmain;

        this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardian == value);
      }
      //   setTimeout(function () {
      //     $('#SickNotesRecord').DataTable();
      // }, 50);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting sick notes");
      // this.router.navigate(['/']);
    });
  }

  clearSickNotes(){
    $("#bcrm_fromdate").val("");
    $("#bcrm_whatarethesymptomsyouareexperiencing").val("");
    $("#purpose").val("");
    $("#bcrm_reasonforsickleave").val("");
  }

}
