import { Component, OnInit } from '@angular/core';
import { PrescriptionsService } from 'src/app/ApiServices/Prescription/prescriptions.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SickNotesService } from 'src/app/ApiServices/Sick/sick-notes.service';
declare var $: any;
@Component({
  selector: 'app-request-prescription-new',
  templateUrl: './request-prescription-new.component.html',
  styleUrls: ['./request-prescription-new.component.css']
})
export class RequestPrescriptionNewComponent implements OnInit {

  prescriptiondrugsdropdown:any;
  prescriptionName : any;
  Obj:any;
  ProxyData: any;
  bcrm_patientguardianvalueAll: any;
  bcrm_patientguardianvalue: any;
  GuardianValue: boolean = false;
  userMainname: any;
  userMainId: any;
  appointments:object;
  repeatPrescriptionDrug : any;
  constructor(private prescription: PrescriptionsService,
    private router: Router,
    private toastr: ToastrService,private sickNotesService: SickNotesService) { }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail=data.userEamil;
     environment.LoginUserId= data.userId;
     environment.LoginUserfullName=data.fullName;
    this.bcrm_patientguardianvalueAll = environment.LoginUserId;
    this.userMainname = environment.LoginUserfullName;
    this.userMainId = environment.LoginUserId;
    this.bcrm_patientguardianvalue = environment.LoginUserId;
    debugger
    let date_ob = new Date();
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // current year
    let year = date_ob.getFullYear();
    // current hours
    let hours = date_ob.getHours();
    // current minutes
    let minutes = date_ob.getMinutes();
    // current seconds
    let seconds = date_ob.getSeconds();
    var d = date + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
    this.prescriptionName = "Prescription_"+ d +"";
    $("#bcrm_commentfornewdrug").hide();
    this.getPrescriptionsDrugsByPatientId();
    this.GetAppointmentByPatientId();
    this.getAllProxy();
  }
  getPrescriptionsDrugsByPatientId(): void {debugger
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.getPrescriptionsDrugsByPatientId(environment.LoginUserId).subscribe((res) => {
      if(res != null){
        debugger
        this.prescriptiondrugsdropdown = res;
        this.RepeatPrescriptionUniqueData(this.prescriptiondrugsdropdown);
      }
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
    
  }


  RepeatPrescriptionUniqueData(Data): void {
    debugger;
    let UniqueData = [];
    this.repeatPrescriptionDrug = [];
    let CurrentDate = new Date().toISOString().split("T")[0];
    for (const element of Data) {
      //let ExpiredData = Data.filter(x=>x.bcrm_expirydate < CurrentDate);
      if (!(UniqueData.find(x => x.Name == element.Name))) {
        UniqueData.push(element)
        var ExpiredData = Data.filter(x => x.bcrm_expirydate < CurrentDate &&  x.Name==element.Name);
        var LastestData = Data.filter(x => x.bcrm_expirydate >= CurrentDate &&  x.Name==element.Name);
        if(ExpiredData && ExpiredData.length>0){
        var expirymaxdate = new Date(Math.max(...ExpiredData.map(e => new Date(e.bcrm_expirydate)))).toISOString();
        }
        if(LastestData && LastestData.length>0){
        var lastestmaxdate = new Date(Math.max(...LastestData.map(e => new Date(e.bcrm_expirydate)))).toISOString();
        }
        if (expirymaxdate) {
          var expiryobjectdata=Data.find(x => x.bcrm_expirydate.split("T")[0] == expirymaxdate.split("T")[0] && x.Name==element.Name);
          if(expiryobjectdata){
          expiryobjectdata.IsReasonshow=true;
          this.repeatPrescriptionDrug.push(expiryobjectdata);
          }
        }
        if (lastestmaxdate) {
          var lastestdataobject=Data.find(x => x.bcrm_expirydate.split("T")[0] == lastestmaxdate.split("T")[0] && x.Name==element.Name);
          if(lastestdataobject){
          lastestdataobject.IsReasonshow=false;
          this.repeatPrescriptionDrug.push(lastestdataobject);
          }
        }
        // if (CurrentDate) {
        //   var currentdataobject=Data.find(x => x.bcrm_expirydate.split("T")[0] ==CurrentDate && x.Name==element.Name);
        //   if(currentdataobject){
        //   currentdataobject.IsReasonshow=false;
        //   this.repeatPrescriptionDrug.push(currentdataobject);
        //   }
        // }
      }
    }

  }



  GetAppointmentByPatientId(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.prescription.GetAppointmentByPatientId(environment.LoginUserId).subscribe((res) => {
      if(res != null){
        
        this.appointments = res;
      }
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
    
  }
  RefreshPage(){
    this.ngOnInit()
  }
  getAllProxy() {

    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ProxyData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting proxy");
      // this.router.navigate(['/']);
    });
  }


  requestPrescrition(requestPrescrition): void{
    requestPrescrition.forEach(function (item) {
      debugger
        if ($("#"+item.id+"_checkbox").is(":checked")) {
          let AddIntoPrescription = {
            Id: item.id,
            bcrm_name: item.name,
            bcrm_startcomment: $("#"+item.id+"_comment").val(),
            bcrm_dosageunit:item.bcrm_dosageunit,
            bcrm_frequency:item.bcrm_frequency,
            bcrm_dosage:item.bcrm_dosage,
            
        };
        this.requestListArray.push(AddIntoPrescription);
        }
    });
  }

  onSubmit(it: NgForm) {
    debugger;
    let requestListArray = [];
    $('#ftco-loader').addClass('show');
    this.Obj = it.value;
    this.prescriptiondrugsdropdown.forEach(function (item) {
        if ($("#"+item.Id+"_checkbox").is(":checked")) {
          let AddIntoPrescription = {
            Id: item.Id,
            bcrm_name: item.Name,
            bcrm_startcomment: $("#"+item.Id+"_comment").val(),
            bcrm_dosageunit:item.bcrm_dosageunit,
            bcrm_frequency:item.bcrm_frequency,
            bcrm_dosage:item.bcrm_dosage,
            
        };
        requestListArray.push(AddIntoPrescription);
        }
    });
    // var drugListArray = [];
    // this.drugList = $("#drugList").val();
    // for (var i = 0; i < this.drugList.length; i++) {
    //   let dataToPush={
    //     Id:this.drugList[i]
    //   };
    //   drugListArray.push(dataToPush);
    // }
    this.Obj.Name=this.prescriptionName
    this.Obj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.Obj.bcrm_type = 2;
    this.Obj.bcrm_isproxy = this.GuardianValue;
    //Type Repeat 
   // this.Obj.Prescrip_drug = drugListArray;
    this.Obj.prescriptiondrugrepeat = requestListArray;
    this.prescription.CreateNewPrescription(this.Obj, environment.LoginUserId).subscribe((res) => {
      
      if (res) {
        this.toastr.success("Created New Successfully");    
        $("#bcrm_reason").val("");
        this.router.navigate(['/prescript'], { skipLocationChange: true });
        // this.router.navigate(['../newprescriptions'], { skipLocationChange: true });
       // $('#Modal1 bcrm_reason')[0].reset();
       // window.location.reload();
      }
      else if(res == "Expiry Date Older than Today's Date"){
        this.toastr.warning("Prescription you want to repeat is of expired date");
      }
      else {
        this.toastr.error("Error while creating prescription");
      }
      $('#ftco-loader').removeClass('show');
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }
  showComment(): void {
    
   if ($("#defaultUnchecked").is(":checked")) {
     
     $("#bcrm_commentfornewdrug").show();
   }
   else{
    $("#bcrm_commentfornewdrug").hide();
   }
  }

  ChecktheGuardianValue(Event) {
    debugger
    var DataofEvent = Event.currentTarget.value;
    if (DataofEvent != environment.LoginUserId) {
      this.GuardianValue = true
    }
    else {
      this.GuardianValue = false;
    }
  }
}
