import { Component, OnInit } from '@angular/core';
import { ObservationsService } from 'src/app/ApiServices/Observation/observations.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import axios from 'axios';
declare var $: any;

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.css']
})
export class ObservationsComponent implements OnInit {
  ObservationsList: object;
  Observation: any = {};
  snomedCodeList: any;
  snomedjson: any;
  constructor(private observationService: ObservationsService,
    private router: Router,
    private toastr: ToastrService) { }
  QueryResult: any = [];
  ngOnInit(): void {
    var data: any = JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail = data.userEamil;
    environment.LoginUserId = data.userId;
    let lt_this = this;
    this.getAllObservationByPatient(environment.LoginUserId);
    //***********************get Patient on hte keyUp of input feilds*********************************************************
    $('.getSnomed').keyup(function () {
      if ($('.getSnomed').val) {
        if ($('.getSnomed').val().length >= 3) {
          lt_this.getSnomadByName($('.getSnomed').val());
        }
      }
    });

    $("#msemr_valuerangehighlimit").hide();
    $("#msemr_valuerangelowlimit").hide();
    $("#bcrm_bloodsugarbeforemeals").hide();
    $("#bcrm_bloodsugaraftermeal").hide();
    $("#bcrm_weightinkilograms").hide();
    $("#bcrm_heightinmetres").hide();
    $("#bcrm_bmicalculation").hide();
    $("#msemr_description").hide();
    $("#Snomed").hide();
    $("#SnomedCode").hide();
  }

  RefreshPage(){
    this.ngOnInit()
  }
  ////#region Calculate Weight Observation
  weightConverter(calculateBy) {

    let Value = parseFloat($("#" + calculateBy).val());
    let valNum1 = 0;
    let valNum2 = 0;
    if (Value) {
      switch (calculateBy) {
        case "bcrm_weight_kg":
          // Weight In KG To Pound
          valNum1 = Value * 2.2046;
          $('#bcrm_weight_pound').val(valNum1.toFixed(2));
          // Weight In KG To Gram
          valNum2 = Value * 1000;
          $('#bcrm_weight_gram').val(valNum2.toFixed(2));
          break;

        case "bcrm_weight_gram":
          // Weight In Gram To Pound
          valNum1 = Value / 453.59237;
          $('#bcrm_weight_pound').val(valNum1.toFixed(2));
          // Weight In Gram To KG
          valNum2 = Value / 1000;
          $('#bcrm_weight_kg').val(valNum2.toFixed(2));
          break;

        case "bcrm_weight_pound":
          // Weight In Pound To KG
          valNum1 = Value * 0.45359237;
          $('#bcrm_weight_kg').val(valNum1.toFixed(2));
          // Weight In Pound To Gram
          valNum2 = Value * 453.59237;
          $('#bcrm_weight_gram').val(valNum2.toFixed(2));
          break;
      }
    }
    else {
      $('#bcrm_weight_pound').val(null);
      $('#bcrm_weight_gram').val(null);
      $('#bcrm_weight_kg').val(null);
    }

  }
  ////#endregion



  // Calculate Height Observation//
  LengthConverter(calculateBy) {

    let Value = parseFloat($("#" + calculateBy).val());
    let valNum1 = 0; let valNum2 = 0;
    if (Value) {
      switch (calculateBy) {
        case "bcrm_height_meter":
          //Height In Meter To Feet
          valNum1 = Value * 3.2808
          $('#bcrm_height_feet').val(valNum1.toFixed(2));
          //Height In Meter To CM
          valNum2 = Value / 0.01;
          $('#bcrm_height_cm').val(valNum2.toFixed(2));
          break;

        case "bcrm_height_cm":
          //Height In CM To Feet
          valNum1 = Value / 30.48;
          $('#bcrm_height_feet').val(valNum1.toFixed(2));
          //Height In CM To Meter
          valNum2 = Value / 100;
          $('#bcrm_height_meter').val(valNum2.toFixed(2));
          break;

        case "bcrm_height_feet":
          //Height In Feet To Meter
          valNum1 = Value * 0.3048
          $('#bcrm_height_meter').val(valNum1.toFixed(2));
          //Height In Feet To CM
          valNum2 = Value * 30.48;
          $('#bcrm_height_cm').val(valNum2.toFixed(2));
          break;
      }
    }
    else {
      $('#bcrm_height_feet').val(null);
      $('#bcrm_height_cm').val(null);
      $('#bcrm_heightinmetres').val(null);
    }
    // if($('#bcrm_height_inch').val()!='' || $('#bcrm_weight_kg').val()!='' ){

    //   this.calculateBmi()


    //  }
  }
  getAllObservationByPatient(patientId: string): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ObservationsList = null;

    this.observationService.getAllObservationListByPatientId(patientId).subscribe((res) => {
      debugger;
      if (res != null) {
        this.ObservationsList = res;
      }

      setTimeout(function () {
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function () {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
      }, 50);

      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });

  }

  getSnomadByName(startWith: string): void {

    //*************Show / hide loader *******************/
    //$('#ftco-loader').addClass('show');
    /**************************************** */
    this.QueryResult = [];
    let baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
    let edition = "MAIN";
    let version = "2019-07-31";

    let findByQueryUrl = baseUrl + '/' + edition + '/' + version + '/concepts?term=' + encodeURIComponent(startWith) + '&activeFilter=true&offset=0&limit=20';

    axios.get(findByQueryUrl)
      .then((response) => {
        if (response != null && response != undefined) {
          let dropdownList = response.data.items
          this.QueryResult = dropdownList
          this.snomedjson = {
            Id: this.QueryResult[0].id, conceptId: this.QueryResult[0].conceptId, active: this.QueryResult[0].active, definitionStatus: this.QueryResult[0].definitionStatus,
            moduleId: this.QueryResult[0].moduleId, effectiveTime: this.QueryResult[0].effectiveTime, fsn_term: this.QueryResult[0].fsn.term, fsn_lang: this.QueryResult[0].fsn.lang,
            pt_term: this.QueryResult[0].pt.term, pt_lang: this.QueryResult[0].pt.lang
          };
          // let filterDataObject = [];
          dropdownList.forEach(function (item) {
            if (startWith == item.pt.term) {
              (<HTMLInputElement>document.getElementById("snomedname")).value = startWith;
              (<HTMLInputElement>document.getElementById("bcrm_conceptid")).value = item.conceptId;
              (<HTMLInputElement>document.getElementById("msemr_code")).value = item.conceptId;
            }

          });
        }
      });

  }

  onSubmit(it: NgForm) {


    var ObservationData = it.value;
    if (ObservationData.bcrm_readingcategories == '') {
      return this.toastr.error("Please fill  mandatory feilds")
    }
    var newcategory;
    switch (ObservationData.bcrm_readingcategories) {
      case '101':
        newcategory = 'Blood Pressure'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.msemr_valuerangehighlimit == '' || ObservationData.msemr_valuerangelowlimit == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '102':
        newcategory = 'Diabetes'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_bloodsugarbeforemeals == '' || ObservationData.bcrm_bloodsugaraftermeal == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '103':
        newcategory = 'Height'
        if (ObservationData.bcrm_heightinmetres == '') {
          ObservationData.bcrm_heightinmetres = $('#bcrm_height_meter').val();
        }
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_heightinmetres == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '104':
        if (ObservationData.bcrm_weightinkilograms == '') {
          ObservationData.bcrm_weightinkilograms = $('#bcrm_weight_kg').val();
        }
        newcategory = 'Weight'
        if (ObservationData.bcrm_readingcategories == '' || ObservationData.bcrm_weightinkilograms == '') {
          return this.toastr.error("Please fill  mandatory feilds")
        }
        break;
      case '105':
        newcategory = 'Other'
        break;
    }
    // 
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let dd1, mm1;
    if (dd < 10)
      dd1 = '0' + dd;
    else
      dd1 = dd;
    if (mm < 10)
      mm1 = '0' + mm;
    else
      mm1 = mm;
    //set description//
    var todayDate = dd1 + '/' + mm1 + '/' + yyyy;
    var time = today.getHours() + ":" + today.getMinutes();
    ObservationData.msemr_description = newcategory + "_" + todayDate + " " + time;

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    let observationToBeSend = {
      bcrm_concept: JSON.stringify(this.snomedjson),
      bcrm_snomedname: (<HTMLInputElement>document.getElementById("snomedname")).value,
      msemr_description: ObservationData.msemr_description,
      bcrm_weightinkilograms: ObservationData.bcrm_weightinkilograms,
      bcrm_heightinmetres: ObservationData.bcrm_heightinmetres,
      bcrm_bloodsugarbeforemeals: ObservationData.bcrm_bloodsugarbeforemeals,
      bcrm_bloodsugaraftermeal: ObservationData.bcrm_bloodsugaraftermeal,
      msemr_valuerangehighlimit: ObservationData.msemr_valuerangehighlimit,
      msemr_valuerangelowlimit: ObservationData.msemr_valuerangelowlimit,
      msemr_valuetypequantityvalue: ObservationData.msemr_valuetypequantityvalue,
      msemr_valueboolean: true,
      bcrm_readingcategories: ObservationData.bcrm_readingcategories,
      msemr_comment: ObservationData.msemr_comment,
      bcrm_modifiedby: "Patient_" + environment.LoginUserEmail + "",
      bcrm_observationcreatedby: 102
    }


    this.observationService.createNewObservationInDynamics(environment.LoginUserId, observationToBeSend).subscribe((res) => {

      if (res == true) {
        this.toastr.success("Observation successfully created");
        $('#CreateObservationModel').modal('hide');
        $("#CreateObservation")[0].reset();
        this.ReloadComponent();
      }
      else {
        this.toastr.error("Error while creating observation");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
  editObservation(data) {
    this.Observation = data;
    let SnomedCode = [{
      conceptId: this.Observation.bcrm_conceptid,
      code: this.Observation.bcrm_snomedname
    }]
    this.snomedCodeList = SnomedCode;
  }

  onUpdate(EditDetails: NgForm) {

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.observationService.updateObservationInDynamics(environment.LoginUserId, this.Observation).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Observation Updated");
        $('#UpdateObservationModel').modal('hide');
        this.getAllObservationByPatient(environment.LoginUserId);
      }
      else {
        this.toastr.error("Observation Not Update Due to Some Error");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  showConfirm(ObservationId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.value) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /**************************************** */
        this.observationService.deleteObservationById(ObservationId).subscribe((res) => {

          if (res == true) {
            this.toastr.info("Deleted Successfully");
            this.getAllObservationByPatient(environment.LoginUserId);
          }
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error : ");
          this.router.navigate(['/']);
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        );
      }
    });

  }

  onCategory(event): void {

    var newcategory;
    switch (event) {
      case '101':
        newcategory = 'Blood Pressure'
        break;
      case '102':
        newcategory = 'Diabetes'
        break;
      case '103':
        newcategory = 'Height'
        break;
      case '104':
        newcategory = 'Weight'
        break;
      case '105':
        newcategory = 'Other'
        break;
    }
    // 
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let dd1, mm1;
    if (dd < 10)
      dd1 = '0' + dd;
    else
      dd1 = dd;
    if (mm < 10)
      mm1 = '0' + mm;
    else
      mm1 = mm;
    //set description//
    var todayDate = dd1 + '/' + mm1 + '/' + yyyy;
    $('#msemr_description1').val(newcategory + "_" + todayDate);

    if (event == "101") {
      $("#msemr_valuerangehighlimit").show();
      $("#msemr_valuerangelowlimit").show();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();

      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Blood pressure';
      this.getSnomadByName(snomeddata);
    }

    else if (event == "102") {
      $("#bcrm_bloodsugarbeforemeals").show();
      $("#bcrm_bloodsugaraftermeal").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").show();
      $("#snomedsearch_input").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Diabetes type';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "103") {
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").show();
      $("#bcrm_bmicalculation").show();
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Body height measure';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "104") {
      $("#bcrm_weightinkilograms").show();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").show();
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#snomedsearch_input").hide();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = 'Weight';
      this.getSnomadByName(snomeddata);
    }
    else if (event == "105") {
      $("#msemr_description").show();
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#snomedsearch_input").show();
      $("#Snomed").show();
      $("#SnomedCode").show();
      var snomeddata = (<HTMLInputElement>document.getElementById("browser1")).value = '';
    }
    else {
      $("#msemr_valuerangehighlimit").hide();
      $("#msemr_valuerangelowlimit").hide();
      $("#bcrm_bloodsugarbeforemeals").hide();
      $("#bcrm_bloodsugaraftermeal").hide();
      $("#bcrm_weightinkilograms").hide();
      $("#bcrm_heightinmetres").hide();
      $("#bcrm_bmicalculation").hide();
      $("#msemr_description").hide();
      $("#snomedsearch_input").show();
      $("#Snomed").hide();
      $("#SnomedCode").hide();
    }
  }
  calculateBmi(): void {

    var weight = $("#bcrm_weight_kg").val();
    var height = $("#bcrm_height_meter").val();
    if (weight > 0 && height > 0) {
      var finalBmi = weight / (height * height)
      $("#bcrm_bmi").val(finalBmi.toFixed(2));
    }
  }
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
  clearObservation(){
    $("#purpose").val("");
    $("#msemr_description1").val("");
    $("#DiastolicPressure").val("");
    $("#SystolicPressure").val("");
    $("#BeforeMeals").val("");
    $("#AfterMeals").val("");
    $("#bcrm_weight_kg").val("");
    $("#bcrm_weight_gram").val("");
    $("#bcrm_weight_pound").val("");
    $("#bcrm_height_meter").val("");
    $("#bcrm_height_feet").val("");
    $("#bcrm_height_cm").val("");
    $("#comment").val("");
  }
}
