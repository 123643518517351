<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container containerpadding">
        <div class="row" style="justify-content: center;">
            <p class="hcolor">Waiting Room</p>
            <!-- <p><a href="index.html">Home /</a> Profile</p> -->
       
    </div>
    </div>
</div>
<br>

<div class="row min-height">
    <div class="col-md-1"></div>
    <div class="col-md-10">
                <router-outlet></router-outlet>
    </div>
    <div class="col-md-1"></div>
</div>
<br>
<!-- Chat Bot Section -->

<header id="botContainerHeader" style="display:none">
    <span id="closeBot" onclick="closeChat()">Close</span>
</header>

<div id="botContainer" style="display:none;">
   
</div>

<div id="Bot-circle" class="btn btn-raised">
   
    <img id="bot-loader" src="../../../assets/css/ajax-loader.gif" alt="" style="width:30px;display: none;">
    <i id="bot-icon" class="fa fa-comment fa-3x" (click)="acquireTokenSilentAndStartBot()"></i> 
    <p id="bot-icon" (click)="acquireTokenSilentAndStartBot()" style="margin-left: -17px; font-size: 9px;"><b>Chat Now</b></p>
</div>
<div id="floating-bot" style="display: none;">

</div>

<!-- end live-chat -->