import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {


  constructor(private http: HttpClient) { }

  // Get Doctors for this api //
  
  getDepartments() {

    return this.http.get(`${environment.apiUrl}/api/Departments/Get`).pipe(
      //  retry(1), 
      catchError(this.handleError)
    );
  }

  // Get Doctors for this api //
  getDoctorsdata() {

    return this.http.get(`${environment.apiUrl}/api/OXDHStaff/GetAllStaffMember`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );;
  }

  // Get Doctors for this api //
  getDepartmentsMembers(memberId) {
    return this.http.get(`${environment.apiUrl}/api/NoAuth/GetMembers?Id=${memberId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  // Get Doctors for this api //
  getDepartmentsMembersProfile(DoctorId) {
    return this.http.get(`${environment.apiUrl}/api/Doctor/GetById?Id=${DoctorId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }

}

