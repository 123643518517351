import { Component, OnInit } from '@angular/core';
import { CrmconditionsService } from 'src/app/ApiServices/Condition/crmconditions.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-newconditions',
  templateUrl: './newconditions.component.html',
  styleUrls: ['./newconditions.component.css']
})
export class NewconditionsComponent implements OnInit {
  conditions: object;
  constructor(private CrmconditionsService: CrmconditionsService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    debugger;
    this.getConditions()
  }
  getConditions(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
debugger;
    this.CrmconditionsService.getConditions(environment.LoginUserId).subscribe((res) => {
      
      if(res != null){
        this.conditions = res;
    
      }
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image2").attr("src", "../../../assets/images/Conditions.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      setTimeout(function () {
        $('#coditionRecord').DataTable();
        }, 50);
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    });
  }

}
