<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h6 class="card-title">Procedures</h6> -->
                            <!-- <p class="card-description">Read the <a href="https://datatables.net/" target="_blank"> Official DataTables Documentation </a>for a full list of instructions and other options.</p> -->
                            <div class="row heading">
                                <div class="col">

                                    <h5>  Online Consultation <span
                                            style="color: gray;"></span></h5>
                                </div>

                                <div class="col"> &nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                                    (click)="RefreshPage()"><i class="fa fa-refresh ref"
                                        aria-hidden="true"></i></button>
                                        &nbsp; <button type="submit" href="#Modal1"
                                        data-toggle="modal" class="btn btn-primary float-right mr-2 mb-2 mb-md-0">+ Add Online
                                        Consultation</button>
                                </div>

                            </div>

                            <hr>
                            <div class="table-responsive">
                                <table class="table" id="dataTableExample">
                                    <thead>
                                        <tr>
                                            <!-- <th>{{'Documents.OnlineConsultationNumber'|translate}}</th> -->
                                            <th style="display: none;">S/No</th>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>{{'Documents.Priority'|translate}}</th>
                                            <!-- <th>Created By</th> -->
                                            <th>Patient/Proxy</th>
                                            <th>{{'Common.createdOn'|translate}}</th>
                                           
                                            <!-- <th>{{'Documents.Customer'|translate}}</th> -->
                                            <th>{{'Common.status'|translate}}</th>
                                            <th>Number</th> 
                                            <th>Edit / Attachment</th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of CaseDeatils; index as i">
                                            <!-- <td>{{data.ticketnumber}}</td> -->
                                            <td style="display: none;">{{i + 1}}</td>
                                            <td> {{data.title}}</td>
                                            <td> {{data.casetype}}</td>
                                            <td> {{data.priority}}</td>
                                            <!-- <td>{{data.customeridname}}</td> -->
                                            <td>{{data.customeridname}} <p  *ngIf="data.guardianName">({{data.guardianName}})</p></td>
                                            <td> {{data.createdon | date:"dd-MMMM-yyyy, HH:mm"}}</td>
                                            <!-- <td> {{data.customeridname}}</td> -->
                                            <td>{{data.statuscodename}}</td>
                                            <td>{{data.ticketnumber}}</td>
                                            <th style="padding-left: 23px !important;">
                                                <span *ngIf="data.statuscodename == 'In Progress'"
                                                    href="#myModalcomment"><a href="#Modal2" data-toggle="modal"
                                                        class="fa fa-pencil" (click)="editcase(data)"
                                                        aria-hidden="true"></a></span>&nbsp;&nbsp;&nbsp;
                                                <a (click)="openSendSMS(data.Id,data)" style="color: white;"><i
                                                        class="fa fa-comments" aria-hidden="true"
                                                        style="color:#3498DB;"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <span *ngIf="data.statuscodename == 'In Progress'"
                                                    href="#myModalcomment"><a href="#Modal3" data-toggle="modal"
                                                        class="fa fa-info-circle" (click)="ViewData(data)"
                                                        aria-hidden="true"></a></span>
                                            </th>

                                        </tr>
                                    </tbody>
                                </table>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Modal1" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- modal header -->
                            <h5>Online Consultation</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearOnlineC()">
                                ×
                            </button>
                        </div>
                        <div class="modal-body  result-shade1">
                            <!-- modal body -->
                            <form class="form" #case="ngForm" (ngSubmit)="onSubmit(case)" #f="ngForm"
                                autocomplete="off">
                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Details</h4></div>

                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="">Subject</label>
                                        <input type="text" class="form-control" name="title"
                                            ngModel required #title="ngModel" id="title">                                              
                                    </div>
                                    <div class="col-6">
                                        <label class="">Description</label>
                                        <textarea placeholder="Description" class="form-control" name="description"
                                            ngModel required #description="ngModel" id="description"></textarea>                                             
                                    </div>
                                    <div class="col-6">
                                        <label class="">Name</label>
                                        <input type="text" placeholder="Enter your Name" class="form-control" name="Name"
                                        [(ngModel)]="Prefill.fullName" ngModel required #Name ="ngModel" id="Name">                                             
                                    </div>
                                    <div class="col-6 p-2">
                                        <label class="">Age</label>
                                        <input type="text" class="form-control" name="Age"
                                        [(ngModel)]="Prefill.Age" ngModel #Age ="ngModel" id="Age">                                             
                                    </div>
                                    <div class="col-6">
                                        <label class="">Phone Number</label>
                                        <input type="tel" placeholder="Enter your phone number" class="form-control" name="PhoneNumber"
                                        [(ngModel)]="Prefill.userMobile" ngModel required #PhoneNumber ="ngModel" id="PhoneNumber">                                             
                                    </div>
                                    <div class="col-6">
                                        <label class="">Email</label>
                                        <input type="email" placeholder="Enter your email address" class="form-control" name="Email"
                                        [(ngModel)]="Prefill.userEamil" ngModel required #Email ="ngModel" id="Email">                                             
                                    </div>
                                    <div class="col-6 p-2">
                                        <label class="">Enter your gender at birth</label>
                                        <select input type="text" class="form-control" name="EnterYourGenderAtBirth"
                                            ngModel #EnterYourGenderAtBirth ="ngModel" id="EnterYourGenderAtBirth">
                                            <option value="">Select</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Not stated</option>
                                            <option value="4">Not known</option>

                                        </select>                                             
                                    </div>

                                    <div class="col-6 p-2">
                                        <label class="">Enter the gender you identify as</label>
                                        <select class="form-control" name="EnterTheGenderYouIdentifyAs"
                                            ngModel #EnterTheGenderYouIdentifyAs ="ngModel" id="EnterTheGenderYouIdentifyAs">
                                        <option value="">Select</option>
                                        <option value="1">Male (including trans man)</option>
                                        <option value="2">Female (including trans woman)</option>
                                        <option value="3">Non-binary</option>
                                        <option value="4">Other</option>
                                        <option value="5">Not Stated</option>
                                        <option value="6">Not Known</option>
                                        
                                        
                                        
                                        </select>                                             
                                    </div>

                                    <div class="col-12 p-2 border border-dark">
                                        <h5 class=''>Select the partners you have sex with:</h5>
                                        <br>
                                        <label>
                                            <input type="checkbox" name="partners" value="1" [(ngModel)]="selectedPartners.oppositeSex" ngModel #OppositeSex ="ngModel" id="OppositeSex"> Opposite sex partner (men & women who have sex)
                                        </label><br>
                                        <label>
                                            <input type="checkbox" name="partners" value="2" [(ngModel)]="selectedPartners.sameSexMen" ngModel #SameSexMen ="ngModel" id="sameSexMen"> Same sex partner (men who have sex with men)
                                        </label><br>                                
                                        <label>
                                            <input type="checkbox" name="partners" value="3" [(ngModel)]="selectedPartners.sameSexWomen" ngModel #SameSexWomen ="ngModel" id="SameSexWomen"> Same sex partner (women who have sex with women)
                                        </label><br>
                                    </div>
                                </div>

                                <!-- <div class="p-2 border bg-info"><h4 class = 'text-white'>Episode Activity</h4></div>
                                 <div class="form-group row">
                                    <div class="col-6">
                                        <label class="">The source of the patient attendance</label>
                                        <select class="form-control" name="TheSourceOfThePatientAttendance"
                                            ngModel required #TheSourceOfThePatientAttendance="ngModel" id="TheSourceOfThePatientAttendance">  
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Self-referral</option>
                                            <option value="2">SRH</option>
                                            <option value="3">GUMCAD STI Surveillance System Change Specification</option>
                                            <option value="4">GP</option>
                                            <option value="5">Prison</option>
                                            <option value="6">NCSP</option>
                                            <option value="7">Online</option>
                                        </select>
                                    </div>
                            
                                  
                                    <div class="col-6">
                                        <label class="">Consultation Date</label>
                                            <input type='date' class="form-control"  name="ConsultationDate"
                                            ngModel required #ConsultationDate="ngModel" id="ConsultationDate"> 
                                        </div>
                                        
                                    <div class="col-6 mt-2">
                                        <label class="">The medium in which the consultation is conducted</label>
                                        <select class="form-control" name="TheMediumInWhichTheConsultationIsConducted"
                                            ngModel required #TheMediumInWhichTheConsultationIsConducted ="ngModel" id="TheMediumInWhichTheConsultationIsConducted">    
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Face to face communication</option>
                                            <option value="2">Telephone</option>
                                            <option value="3">Online</option>
                                        </select>
                                    </div>
                                    <div class="col-6 mt-2">
                                        <label class="">The consultation's episode type</label>
                                        <select class="form-control" name="TheConsultationsEpisodeType"
                                            ngModel required #TheConsultationsEpisodeType="ngModel" id="TheConsultationsEpisodeType">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">New (Initial)</option>
                                            <option value="2">Follow-up</option>
                                        </select>
                                    </div>
                                

                                
                                <div class="col-6 mt-2">
                                    <label class="">Speciality of the attendance</label>
                                    <select class="form-control" name="SpecialityOfTheAttendance"
                                        ngModel required #SpecialityOfTheAttendance ="ngModel" id="SpecialityOfTheAttendance">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Integrated STI/SRH care</option>
                                        <option value="2">STI care</option>
                                        <option value="3">SRH care</option>
                                        <option value="4">HIV care</option>
                                        <option value="5">Other</option>
                                    </select>
                                </div>

                                <div class="col-6 mt-2">
                                    <label class="">Is the consultation a result of partner notification</label>
                                    <select class="form-control" name="IsTheConsultationAResultOfPartnerNotification"
                                        ngModel required #IsTheConsultationAResultOfPartnerNotification ="ngModel" id="IsTheConsultationAResultOfPartnerNotification">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value='1'>Yes</option>
                                        <option value='2'>No</option>
                                    </select>
                                </div>

                                <div class="col-6 mt-2">
                                    <label class="">Is the consultation a result of a symptomatic infection</label>
                                    <select class="form-control" name="IsTheConsultationAResultOfASymptomaticInfection"
                                        ngModel required #IsTheConsultationAResultOfASymptomaticInfection ="ngModel" id="IsTheConsultationAResultOfASymptomaticInfection">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Yes - symptomatic</option>
                                        <option value="2">No - asymptomatic</option>
                                        <option value="3">Not applicable</option>
                                    </select>
                                </div>

                                
                                <div class="col-6 mt-2">
                                    <label class="">Confirmation of diagnosis</label>                                    
                                    <select class="form-control" name="ConfirmationOfDiagnosis"
                                        ngModel required #ConfirmationOfDiagnosis ="ngModel" id="ConfirmationOfDiagnosis">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Confirmed (at this service)</option>
                                        <option value="2">Confirmed elsewhere (at a different service)</option>
                                        <option value="3">Initial reactive</option>
                                        <option value="4">Not applicable</option>
                                    </select>
                                </div>
                            
                                <div class="col-6 mt-2">
                                    <label class="">Site of infection</label>                                                                        
                                    <select class="form-control" name="SiteOfInfection"
                                        ngModel required #SiteOfInfection ="ngModel" id="SiteOfInfection">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Genital</option>
                                        <option value="2">Rectal</option>
                                        <option value="3">Pharyngeal</option>
                                        <option value="4">Ocular</option>
                                        <option value="5">Other</option>
                                        <option value="6">Not applicable</option>
                                    </select>
                                    </div>

                                <div class="col-6 mt-2">
                                    <label class="">Treatment of diagnosis</label>                                                                                                                
                                    <select class="form-control" name="TreatmentOfDiagnosis"
                                        ngModel required #TreatmentOfDiagnosis ="ngModel" id="TreatmentOfDiagnosis" >                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No - not required</option>
                                        <option value="3">No - referred elsewhere</option>
                                        <option value="4">No - refused</option>
                                        <option value="5">No - patient walked out</option>
                                        <option value="6">Not applicable</option>
                                    </select>
                                </div>

                                </div> -->
                                <!-- Form Section 2 Opposite sex partners - men & women who have sex -->

                                <div class="p-2 border bg-info" *ngIf="selectedPartners.oppositeSex"><h4 class = 'text-white'>Opposite sex partners - men & women who have sex</h4></div>
                                
                                <div class="form-group row" *ngIf="selectedPartners.oppositeSex">
                                    <div class="col-6 mt-2">
                                    <label class="">How many sex partners in the last 3 months?</label>                                                                            
                                        <select class="form-control" name="OS_HowManySexPartnersInTheLast3Months"
                                            ngModel #OS_HowManySexPartnersInTheLast3Months ="ngModel" id="OS_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <!-- <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option> -->
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                    <label class="Custom-Text">Were any of these new sex partners (ie you haven't had sex with them before)</label>                                                                                    
                                        <select class="form-control" name="OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore"
                                            ngModel #OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore ="ngModel" id="OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                    <label class="">Did you have condomless penetrative sex the last time you had sex?</label>                                                                                                                            
                                        <select class="form-control" name="OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex"
                                            ngModel #OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex ="ngModel" id="OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Same sex partner – men who have sex with men -->

                                <div class="p-2 border bg-info" *ngIf="selectedPartners.sameSexMen"><h4 class = 'text-white'>Same sex partner – men who have sex with men</h4></div>
                                
                                <div class="form-group row" *ngIf="selectedPartners.sameSexMen">
                                    <div class="col-6 mt-2">
                                    <label class="">How many sex partners in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="SSMM_HowManySexPartnersInTheLast3Months"
                                            ngModel #SSMM_HowManySexPartnersInTheLast3Months ="ngModel" id="SSMM_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <!-- <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option> -->
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">Have you had anal (receptive or insertive) sex with a known HIV positive partner in the last three months?</label>                                        
                                        <select class="form-control" name="SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths"
                                            ngModel #SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths ="ngModel" id="SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Have you had condomless penetrative sex in the last 3 months?</label>                                        
                                        <select class="form-control" name="SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months"
                                            ngModel #SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months ="ngModel" id="SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Have you had receptive condomless penetrative sex in the last 3 months?</label>
                                        <select class="form-control" name="SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months"
                                            ngModel #SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months ="ngModel" id="SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Same sex partners – women who have sex with women -->

                                <div class="p-2 border bg-info" *ngIf="selectedPartners.sameSexWomen" ><h4 class = 'text-white'>Same sex partners – women who have sex with women</h4></div>
                                
                                <div class="form-group row" *ngIf="selectedPartners.sameSexWomen">
                                    <div class="col-6 mt-2">
                                        <label class="">How many sex partners in the last 3 months?</label>
                                        <select class="form-control" name="SSWW_HowManySexPartnersInTheLast3Months"
                                            ngModel required #SSWW_HowManySexPartnersInTheLast3Months ="ngModel" id="SSWW_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <!-- <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option> -->
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">Were any of these new sex partners (ie you haven't had sex with them before?)</label>
                                        <select class="form-control" name="SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore"
                                            ngModel required #SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore ="ngModel" id="SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Partner Notification -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Partner Notification</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">Date of initial Partner Notification discussion</label>
                                        <input type='date' class="form-control"  name="DateOfInitialPartnerNotificationDiscussion"
                                            ngModel #DateOfInitialPartnerNotificationDiscussion ="ngModel" id="DateOfInitialPartnerNotificationDiscussion">                                         
                                    </div>

                                    
                                    <!-- <div class="col-6 mt-2">
                                        <label class="">How many partners were reported for the STI(s) diagnosed?</label>
                                        <input type="number" placeholder="Enter any number from 0-99(0 if not applicable)" min="0" max="99" class="form-control" name="HowManyPartnersWereReportedForTheSTIsDiagnosed"
                                            ngModel #HowManyPartnersWereReportedForTheSTIsDiagnosed ="ngModel" id="HowManyPartnersWereReportedForTheSTIsDiagnosed" >                                                                                   
                                        
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">How many partners were contactable?</label>
                                        <input type="number" placeholder="Enter any number from 0-99(0 if not applicable)" min="0" max="99" class="form-control" name="HowManyPartnersWereContactable"
                                            ngModel #HowManyPartnersWereContactable ="ngModel" id="HowManyPartnersWereContactable" >                                                                           
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">How many partners are reported to have attended a sexual health service within 4 weeks of the initial PN discussion?</label>
                                        <input type="number" placeholder="Enter any number from 0-99(0 if not applicable)" min="0" max="99" class="form-control" name="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion"
                                            ngModel #HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion ="ngModel" id="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion" >                                        
                                    </div> -->

                                    <div class="col-6 mt-2">
                                        <label class="">How many partners were reported for the STI(s) diagnosed?</label>
                                        <input  class="form-control" name="HowManyPartnersWereReportedForTheSTIsDiagnosed"
                                        ngModel  #HowManyPartnersWereReportedForTheSTIsDiagnosed ="ngModel" id="HowManyPartnersWereReportedForTheSTIsDiagnosed" type="number" placeholder="Enter number(0-99)" min="0" max="99">

                                        <!-- <div class="d-flex">
                                        <select class="form-control col-md-4" name="HowManyPartnersWereReportedForTheSTIsDiagnosed"
                                            ngModel  #HowManyPartnersWereReportedForTheSTIsDiagnosed ="ngModel" id="HowManyPartnersWereReportedForTheSTIsDiagnosed" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                        <input *ngIf="HowManyPartnersWereReportedForTheSTIsDiagnosed.value==1" class="form-control col-md-4 ml-2" type="number" placeholder="Enter number(0-99)" min="0" max="99">
                                        </div> -->
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">How many partners were contactable?</label>
                                        <input class="form-control" name="HowManyPartnersWereContactable"
                                        ngModel #HowManyPartnersWereContactable ="ngModel" id="HowManyPartnersWereContactable" type="number" placeholder="Enter number(0-99)" min="0" max="99"> 
                                        <!-- <div class="d-flex">
                                        <select class="form-control col-md-4" name="HowManyPartnersWereContactable"
                                            ngModel #HowManyPartnersWereContactable ="ngModel" id="HowManyPartnersWereContactable" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                        <input *ngIf="HowManyPartnersWereContactable.value==1" class="form-control col-md-4 ml-2" type="number" placeholder="Enter number(0-99)" min="0" max="99">                                        
                                    </div> -->
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">How many partners are reported to have attended a sexual health service within 4 weeks of the initial PN discussion?</label>
                                        <input class="form-control" type="number" placeholder="Enter number(0-99)" min="0" max="99">
                                        <!-- <div class="d-flex">
                                        <select class="form-control col-4" name="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion"
                                            ngModel #HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion ="ngModel" id="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                        <input *ngIf="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion.value==1" class="form-control col-md-4 ml-2" type="number" placeholder="Enter number(0-99)" min="0" max="99">
                                        </div> -->
                                    </div>

                                    

                                </div>

                                <!-- PrEp -->

                                <!-- <div class="p-2 border bg-info"><h4 class='text-white'>PrEP</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">The patient's eligibility criteria for accessing PrEP</label>
                                        <select class="form-control"  name="ThePatientsEligibilityCriteriaForAccessingPrEP"
                                            ngModel required #ThePatientsEligibilityCriteriaForAccessingPrEP ="ngModel" id="ThePatientsEligibilityCriteriaForAccessingPrEP">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">MSM/Transgender woman</option>
                                            <option value="2">HIV positive partner</option>
                                            <option value="3">Other high risk</option>
                                            <option value="4">Not applicable</option>
                                        </select>

                                    </div>
                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">How many partners are verified to have attended a sexual health service within 4 weeks of the initial PN discussion?</label>                                       
                                            <select type="text" class="form-control" name="ConsultationReferral"
                                            ngModel required #ConsultationReferral ="ngModel" id="ConsultationReferral">
                                                  <option value="">Select</option>  
                                                  <option value="1">Any number 0-99</option>
                                                  <option value="2">Not applicable</option>
                                                  </select>                                        
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">The outcome of the PrEP offer</label>
                                        <select class="form-control" name="TheOutcomeOfThePrEPOffer"
                                            ngModel required #TheOutcomeOfThePrEPOffer ="ngModel" id="TheOutcomeOfThePrEPOffer" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Accepted</option>
                                            <option value="2">Declined - patient refused PrEP</option>
                                            <option value="3">Declined - patient is obtaining PrEP elsewhere</option>
                                            <option value="4">Not applicable</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">PrEP regimen recommended</label>
                                        <select class="form-control" name="PrEPRegimenRecommended"
                                            ngModel required #PrEPRegimenRecommended ="ngModel" id="PrEPRegimenRecommended" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Daily (or nearly daily)</option>
                                            <option value="2">Event based (coital)</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">The number of PrEP tablets prescribed</label>
                                        <select class="form-control" name="TheNumberOfPrEPTabletsPrescribed"
                                            ngModel required #TheNumberOfPrEPTabletsPrescribed ="ngModel" id="TheNumberOfPrEPTabletsPrescribed" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">30 tablets</option>
                                            <option value="2">60 tablets</option>
                                            <option value="3">90 tablets</option>
                                            <option value="4">Other amount</option>
                                            <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option>
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">If PrEP has been stopped, what was the reason?</label>                                       
                                        <select class="form-control" name="IfPrEPHasBeenStoppedWhatWasTheReason"
                                            ngModel required #IfPrEPHasBeenStoppedWhatWasTheReason ="ngModel" id="IfPrEPHasBeenStoppedWhatWasTheReason" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Adverse event</option>
                                            <option value="2">HIV acquisition</option>
                                            <option value="3">Patient choice</option>
                                            <option value="4">No longer eligible</option>
                                            <option value="5">Other</option>
                                            <option value="6">Not applicable</option>
                                            <option value="7">Not asked</option>
                                            <option value="8">Not known/Not stated</option>
                                        </select>                                        
                                    </div>

                                </div> -->

                                <!-- Behaviour -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Behaviour</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">Did you use alcohol?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WasAlcoholUseAssessed"
                                            ngModel #WasAlcoholUseAssessed ="ngModel" id="WasAlcoholUseAssessed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Do you have a problem with alcohol?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WasAlcoholUseDocumentedAsProblematic"
                                            ngModel #WasAlcoholUseDocumentedAsProblematic ="ngModel" id="WasAlcoholUseDocumentedAsProblematic">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Have you used recreational drugs in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="HaveYouUsedRecreationalDrugsInTheLast3Months"
                                            ngModel #HaveYouUsedRecreationalDrugsInTheLast3Months ="ngModel" id="HaveYouUsedRecreationalDrugsInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Amphetamine/Speed</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeAmphetamineOrSpeed"
                                            ngModel #DidYouTakeAmphetamineOrSpeed ="ngModel" id="DidYouTakeAmphetamineOrSpeed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Benzodiazepines (non-prescribed)</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeBenzodiazepinesNonPrescribed"
                                            ngModel #DidYouTakeBenzodiazepinesNonPrescribed ="ngModel" id="DidYouTakeBenzodiazepinesNonPrescribed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Cannabis</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCannabis"
                                            ngModel #DidYouTakeCannabis ="ngModel" id="DidYouTakeCannabis">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Cocaine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCocaine"
                                            ngModel #DidYouTakeCocaine ="ngModel" id="DidYouTakeCocaine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Crack</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCrack"
                                            ngModel #DidYouTakeCrack ="ngModel" id="DidYouTakeCrack">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Crystal Meth/Methamphetamine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCrystalMethMethamphetamine"
                                            ngModel #DidYouTakeCrystalMethMethamphetamine ="ngModel" id="DidYouTakeCrystalMethMethamphetamine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Ecstasy (E)/MDMA</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeEcstasy_E_MDMA"
                                            ngModel #DidYouTakeEcstasy_E_MDMA ="ngModel" id="DidYouTakeEcstasy_E_MDMA">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take GHB/GBL</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeGHBOrGBL"
                                            ngModel #DidYouTakeGHBOrGBL ="ngModel" id="DidYouTakeGHBOrGBL">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Heroin</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeHeroin"
                                            ngModel #DidYouTakeHeroin ="ngModel" id="DidYouTakeHeroin">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Ketamine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeKetamine"
                                            ngModel #DidYouTakeKetamine ="ngModel" id="DidYouTakeKetamine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take novel psychoactive substances</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeNovelPsychoactiveSubstances"
                                            ngModel #DidYouTakeNovelPsychoactiveSubstances ="ngModel" id="DidYouTakeNovelPsychoactiveSubstances">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Mephedrone (M-Cat)</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeMephedroneM_Cat"
                                            ngModel #DidYouTakeMephedroneM_Cat ="ngModel" id="DidYouTakeMephedroneM_Cat">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Methadone</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeMethadone"
                                            ngModel #DidYouTakeMethadone ="ngModel" id="DidYouTakeMethadone">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Poppers</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakePoppers"
                                            ngModel #DidYouTakePoppers ="ngModel" id="DidYouTakePoppers">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Solvents/Glue</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeSolventsGlue"
                                            ngModel #DidYouTakeSolventsGlue ="ngModel" id="DidYouTakeSolventsGlue">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take any other recreational drug (not listed)?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeAnyOtherRecreationalDrugNotListed"
                                            ngModel #DidYouTakeAnyOtherRecreationalDrugNotListed ="ngModel" id="DidYouTakeAnyOtherRecreationalDrugNotListed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you inject any recreational drug in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouInjectAnyRecreationalDrugInTheLast3Months"
                                            ngModel #DidYouInjectAnyRecreationalDrugInTheLast3Months ="ngModel" id="DidYouInjectAnyRecreationalDrugInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you share equipment with anyone when injecting drugs?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouShareEquipmentWithAnyoneWhenInjectingDrugs"
                                            ngModel #DidYouShareEquipmentWithAnyoneWhenInjectingDrugs ="ngModel" id="DidYouShareEquipmentWithAnyoneWhenInjectingDrugs">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Were you under the influence of recreational drugs the last tme you had sex?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex"
                                            ngModel #WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex ="ngModel" id="WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                </div>



                                <!-- <div class="form-group row">
                                    <label class="control-label col-sm-3 required">{{'Common.title'|translate}}</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" placeholder="Enter title" name="title"
                                            ngModel required #title="ngModel" id="title">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">Patient/Proxy</label>
                                    <div class="col-sm-9">
                                    <select class="list-dt  form-control" id="bcrm_patientguardian" name="prioritycode"
                                    #bcrm_patientguardian="ngModel" [(ngModel)]="bcrm_patientguardianvalue"  (change)="ChecktheGuardianValue($event)">
                                            <option value="" disabled>Select Guardian
                                            </option>
                                            <option value={{userMainId}}>{{userMainname}}
                                                (Me)</option>
                                            <option [value]="data.relatedPersonId"
                                                *ngFor="let data of ProxyData"
                                                class="form-control">{{data.fullName}}
                                            </option>
                                        </select>
                                </div>
                            </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 ">{{'Documents.Priority'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="prioritycode" name="prioritycode"
                                            ngModel #prioritycode="ngModel" #prioritycode="ngModel">
                                            <option value="" selected disabled>Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Normal</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 ">{{'Documents.OnlineConsultationType'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="casetypecode" name="casetypecode"
                                            ngModel #casetypecode="ngModel" #casetypecode="ngModel">
                                            <option value="" selected disabled>Select Type</option>
                                            <option value="1">Question</option>
                                            <option value="2">Problem</option>
                                            <option value="3">Request</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea type="text" class="form-control" placeholder="Enter Description"
                                            name="description" ngModel required #description="ngModel" id="description"
                                            rows="10"></textarea>
                                    </div>
                                </div> -->

                                <!-- <div class="form-group row">
                        <label class="control-label col-sm-3 required">Upload file</label>
                        <div class="col-sm-9">
                            <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                            <input type="file" (change)="IncomingFile($event.target.files);"/>
                        </div>
                    </div> -->
                                <input type="submit" class="btn btn-primary pull-right" value="Submit"
                                    [disabled]="!case.valid">
                            </form>
                        </div>
                    </div>
                    <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

            </div>

            <div id="Modal2" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- modal header -->
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                ×
                            </button>
                        </div>
                        <div class="modal-body  result-shade1">
                            <!-- modal body -->
                            <form class="form" #editCase="ngForm" (ngSubmit)="onUpdate(Case)" #f="ngForm"
                                autocomplete="off">
                                <div class="form-group row">
                                    <label class="control-label col-sm-3">Subject</label>
                                    <div class="col-6">
                                        <input type="text" class="form-control" name="title"
                                            ngModel [(ngModel)]="Case.title" #title="ngModel" id="title">                                              
                                    </div>
                           </div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-3">{{'Documents.Priority'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="prioritycode" ngModel
                                            name="prioritycode" #name="ngModel" [(ngModel)]="Case.prioritycode">
                                            <option value=" " selected >Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Normal</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label
                                        class="control-label col-sm-3">{{'Documents.OnlineConsultationType'|translate}}</label>
                                    <div class="col-sm-9 ">
                                        <select class="list-dt  form-control" id="casetypecode" ngModel #name="ngModel"
                                            [(ngModel)]="Case.casetypecode" ngModel name="casetypecode">
                                            <option value=" " selected>Select Type</option>
                                            <option value="1">Question</option>
                                            <option value="2">Problem</option>
                                            <option value="3">Request</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        class="control-label col-sm-3 required">{{'Common.description'|translate}}</label>
                                    <div class="col-sm-9 ">
                                        <textarea type="text" class="form-control" #name="ngModel" name="description"
                                            [(ngModel)]="Case.description" ngModel id="description" rows="10"
                                            required></textarea>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="control-label col-sm-3 required">Upload file</label>
                                    <div class="col-sm-9">
                                        <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                                        <input type="file" (change)="IncomingFile($event.target.files);" />
                                    </div>
                                </div>

                                <input type="submit" class="btn btn-primary pull-right" value="Update">
                            </form>
                        </div>
                    </div>
                    <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->




            </div>

            <div id="Modal3" class="modal fade">
                <!-- class modal and fade -->
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- modal header -->
                            <h5>View Record(Not-Editable)</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                ×
                            </button>
                            
                        </div>
                        <div class="modal-body  result-shade1">
                            <!-- modal body -->
                            <form class="form" #editCase="ngForm" (ngSubmit)="onUpdate(Case)" #f="ngForm"
                                autocomplete="off">

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Details</h4></div>

                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="">Subject</label>
                                        <input type="text" class="form-control" name="title"
                                            ngModel [(ngModel)]="Case.title" disabled #title="ngModel" id="title">                                              
                                    </div>
                                    <div class="col-6 ">
                                    <label
                                        class="control-label col-3 required">{{'Common.description'|translate}}</label>
                                    
                                        <textarea type="text" class="form-control" disabled #name="ngModel" name="description"
                                            [(ngModel)]="Case.description" ngModel id="description" rows="10"
                                            ></textarea>
                                    </div>
                                    
                                    <div class="col-6">
                                        <label class="">Name</label>
                                        <input type="text" class="form-control" name="Name"
                                            ngModel #Name ="ngModel" id="Name" disabled [(ngModel)]="Case.prefill.fullName">                                             
                                    </div>
                                    <div class="col-6 p-2">
                                        <label class="">Age</label>
                                        <input type="text" class="form-control" name="Age"
                                        [(ngModel)]="Case.prefill.Age" ngModel disabled #Age ="ngModel" id="Age">                                             
                                    </div>
                                    <div class="col-6">
                                        <label class="">Phone Number</label>
                                        <input type="tel" class="form-control" name="PhoneNumber"
                                            ngModel disabled #PhoneNumber ="ngModel" id="PhoneNumber" [(ngModel)]="Case.prefill.userMobile">                                             
                                    </div>
                                    <div class="col-6">
                                        <label class="">Email</label>
                                        <input type="email" class="form-control" name="Email"
                                            ngModel disabled #Email ="ngModel" id="Email" [(ngModel)]="Case.prefill.userEamil">                                             
                                    </div>
                                    <div class="col-6 p-2">
                                        <label class="">Enter your gender at birth</label>
                                        <input type="text" class="form-control" name="EnterYourGenderAtBirth"
                                            ngModel disabled #EnterYourGenderAtBirth ="ngModel" id="EnterYourGenderAtBirth" [(ngModel)]="Case.EnterYourGenderAtBirth">                                             
                                    </div>

                                    <div class="col-6 p-2">
                                        <label class="">Enter the gender you identify as</label>
                                        <input type="text" class="form-control" name="EnterTheGenderYouIdentifyAs"
                                            ngModel disabled #EnterTheGenderYouIdentifyAs ="ngModel" id="EnterTheGenderYouIdentifyAs" [(ngModel)]="Case.EnterTheGenderYouIdentifyAs">                                             
                                    </div>

                                    <div class="col-12 p-2 border border-dark">
                                        <h5 class=''>Select the partners you have sex with:</h5>
                                        <br>
                                        <label>
                                            <input type="checkbox" disabled name="partners" value="1" [(ngModel)]="Case.oppositeSex" ngModel #OppositeSex ="ngModel" id="OppositeSex"> Opposite sex partner (men & women who have sex)
                                        </label><br>
                                        <label>
                                            <input type="checkbox" disabled name="partners" value="2" [(ngModel)]="Case.sameSexMen" ngModel #SameSexMen ="ngModel" id="sameSexMen"> Same sex partner (men who have sex with men)
                                        </label><br>                                
                                        <label>
                                            <input type="checkbox" disabled name="partners" value="3" [(ngModel)]="Case.sameSexWomen" ngModel #SameSexWomen ="ngModel" id="SameSexWomen"> Same sex partner (women who have sex with women)
                                        </label><br>
                                    </div>

                                </div>

                                <!-- <div class="p-2 border bg-info"><h4 class = 'text-white'>Episode Activity</h4></div>
                                 <div class="form-group row">
                                    <div class="col-6">
                                        <label class="">The source of the patient attendance</label>
                                        <select class="form-control" name="TheSourceOfThePatientAttendance"
                                            ngModel [(ngModel)]="Case.TheSourceOfThePatientAttendance" disabled #TheSourceOfThePatientAttendance="ngModel" id="TheSourceOfThePatientAttendance">  
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Self-referral</option>
                                            <option value="2">SRH</option>
                                            <option value="3">GUMCAD STI Surveillance System Change Specification</option>
                                            <option value="4">GP</option>
                                            <option value="5">Prison</option>
                                            <option value="6">NCSP</option>
                                            <option value="7">Online</option>
                                        </select>
                                    </div>
                                    
                                  
                                    <div class="col-6">
                                        <label class="">Consultation Date</label>
                                            <input type='date' class="form-control"  name="ConsultationDate"
                                            ngModel value="{{ this.Case.ConsultationDate | date: 'yyyy-MM-dd' }}" disabled #ConsultationDate="ngModel" id="ConsultationDate"> 
                                            
                                        </div>
                                        
                                    <div class="col-6 mt-2">
                                        <label class="">The medium in which the consultation is conducted</label>
                                        <select class="form-control" name="TheMediumInWhichTheConsultationIsConducted"
                                            ngModel [(ngModel)]="Case.TheMediumInWhichTheConsultationIsConducted" disabled #TheMediumInWhichTheConsultationIsConducted ="ngModel" id="TheMediumInWhichTheConsultationIsConducted">    
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Face to face communication</option>
                                            <option value="2">Telephone</option>
                                            <option value="3">Online</option>
                                        </select>
                                    </div>
                                    <div class="col-6 mt-2">
                                        <label class="">The consultation's episode type</label>
                                        <select class="form-control" name="TheConsultationsEpisodeType"
                                            ngModel [(ngModel)]="Case.TheConsultationsEpisodeType" disabled #TheConsultationsEpisodeType="ngModel" id="TheConsultationsEpisodeType">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">New (Initial)</option>
                                            <option value="2">Follow-up</option>
                                        </select>
                                    </div>
                                

                                
                                <div class="col-6 mt-2">
                                    <label class="">Speciality of the attendance</label>
                                    <select class="form-control" name="SpecialityOfTheAttendance"
                                        ngModel [(ngModel)]="Case.SpecialityOfTheAttendance" disabled #SpecialityOfTheAttendance ="ngModel" id="SpecialityOfTheAttendance">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Integrated STI/SRH care</option>
                                        <option value="2">STI care</option>
                                        <option value="3">SRH care</option>
                                        <option value="4">HIV care</option>
                                        <option value="5">Other</option>
                                    </select>
                                </div>

                                <div class="col-6 mt-2">
                                    <label class="">Is the consultation a result of partner notification</label>
                                    <select class="form-control" name="IsTheConsultationAResultOfPartnerNotification"
                                        ngModel [(ngModel)]="Case.IsTheConsultationAResultOfPartnerNotification" disabled #IsTheConsultationAResultOfPartnerNotification ="ngModel" id="IsTheConsultationAResultOfPartnerNotification">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value=true >Yes</option>
                                        <option value=false >No</option>
                                    </select>
                                </div>

                                <div class="col-6 mt-2">
                                    <label class="">Is the consultation a result of a symptomatic infection</label>
                                    <select class="form-control" name="IsTheConsultationAResultOfASymptomaticInfection"
                                        ngModel [(ngModel)]="Case.IsTheConsultationAResultOfASymptomaticInfection" disabled #IsTheConsultationAResultOfASymptomaticInfection ="ngModel" id="IsTheConsultationAResultOfASymptomaticInfection">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Yes - symptomatic</option>
                                        <option value="2">No - asymptomatic</option>
                                        <option value="3">Not applicable</option>
                                    </select>
                                </div>

                                
                                <div class="col-6 mt-2">
                                    <label class="">Confirmation of diagnosis</label>                                    
                                    <select class="form-control" name="ConfirmationOfDiagnosis"
                                        ngModel [(ngModel)]="Case.ConfirmationOfDiagnosis" disabled #ConfirmationOfDiagnosis ="ngModel" id="ConfirmationOfDiagnosis">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Confirmed (at this service)</option>
                                        <option value="2">Confirmed elsewhere (at a different service)</option>
                                        <option value="3">Initial reactive</option>
                                        <option value="4">Not applicable</option>
                                    </select>
                                </div>
                            
                                <div class="col-6 mt-2">
                                    <label class="">Site of infection</label>                                                                        
                                    <select class="form-control" name="SiteOfInfection"
                                        ngModel [(ngModel)]="Case.SiteOfInfection" disabled #SiteOfInfection ="ngModel" id="SiteOfInfection">                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Genital</option>
                                        <option value="2">Rectal</option>
                                        <option value="3">Pharyngeal</option>
                                        <option value="4">Ocular</option>
                                        <option value="5">Other</option>
                                        <option value="6">Not applicable</option>
                                    </select>
                                    </div>

                                <div class="col-6 mt-2">
                                    <label class="">Treatment of diagnosis</label>                                                                                                                
                                    <select class="form-control" name="TreatmentOfDiagnosis"
                                        ngModel [(ngModel)]="Case.TreatmentOfDiagnosis" disabled #TreatmentOfDiagnosis ="ngModel" id="TreatmentOfDiagnosis" >                                         
                                        <option value="" [defaultSelected]="true">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No - not required</option>
                                        <option value="3">No - referred elsewhere</option>
                                        <option value="4">No - refused</option>
                                        <option value="5">No - patient walked out</option>
                                        <option value="6">Not applicable</option>
                                    </select>
                                </div>

                                </div> -->
                                <!-- Form Section 2 Opposite sex partners - men & women who have sex -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Opposite sex partners - men & women who have sex</h4></div>
                    
                                <div class="form-group row" >
                                    <div class="col-6 mt-2">
                                    <label class="">How many sex partners in the last 3 months?</label>                                                                            
                                        <select class="form-control" name="OS_HowManySexPartnersInTheLast3Months"
                                            ngModel [(ngModel)]="Case.OS_HowManySexPartnersInTheLast3Months" disabled #OS_HowManySexPartnersInTheLast3Months ="ngModel" id="OS_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option>
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                    <label class="Custom-Text">Were any of these new sex partners (ie you haven't had sex with them before)</label>                                                                                    
                                        <select class="form-control" name="OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore"
                                            ngModel [(ngModel)]="Case.OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" disabled #OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore ="ngModel" id="OS_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                    <label class="">Did you have condomless penetrative sex the last time you had sex?</label>                                                                                                                            
                                        <select class="form-control" name="OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex"
                                            ngModel [(ngModel)]="Case.OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex" disabled #OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex ="ngModel" id="OS_DidYouHaveCondomlessPenetrativeSexTheLastTimeYouHadSex" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Same sex partner – men who have sex with men -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Same sex partner – men who have sex with men</h4></div>
                                
                                <div class="form-group row" >
                                    <div class="col-6 mt-2">
                                    <label class="">How many sex partners in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="SSMM_HowManySexPartnersInTheLast3Months"
                                            ngModel [(ngModel)]="Case.SSMM_HowManySexPartnersInTheLast3Months" disabled #SSMM_HowManySexPartnersInTheLast3Months ="ngModel" id="SSMM_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option>
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">Have you had anal (receptive or insertive) sex with a known HIV positive partner in the last three months?</label>                                        
                                        <select class="form-control" name="SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths"
                                            ngModel [(ngModel)]="Case.SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths" disabled #SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths ="ngModel" id="SSMM_HaveYouHadAnalReceptiveOrInsertiveSexWithAKnownHIVPositivePartnerInTheLastThreeMonths" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">Have you had condomless penetrative sex in the last 3 months?</label>                                        
                                        <select class="form-control" name="SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months"
                                            ngModel [(ngModel)]="Case.SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months" disabled #SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months ="ngModel" id="SSMM_HaveYouHadCondomlessPenetrativeSexInTheLast3Months" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Have you had receptive condomless penetrative sex in the last 3 months?</label>
                                        <select class="form-control" name="SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months"
                                            ngModel [(ngModel)]="Case.SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months" disabled #SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months ="ngModel" id="SSMM_HaveYouHadReceptiveCondomlessPenetrativeSexInTheLast3Months" >                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="2">Not applicable</option>
                                            <option value="3">Not asked</option> -->
                                            <option value="3">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Same sex partners – women who have sex with women -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Same sex partners – women who have sex with women</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">How many sex partners in the last 3 months?</label>
                                        <select class="form-control" name="SSWW_HowManySexPartnersInTheLast3Months"
                                            ngModel [(ngModel)]="Case.SSWW_HowManySexPartnersInTheLast3Months" disabled #SSWW_HowManySexPartnersInTheLast3Months ="ngModel" id="SSWW_HowManySexPartnersInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">0</option>
                                            <option value="2">1</option>
                                            <option value="3">2-4</option>
                                            <option value="4">5+</option>
                                            <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option>
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">Were any of these new sex partners (ie you haven't had sex with them before?)</label>
                                        <select class="form-control" name="SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore"
                                            ngModel [(ngModel)]="Case.SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" disabled #SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore ="ngModel" id="SSWW_WereAnyOfTheseNewSexPartnersIEYouHaventHadSexWithThemBefore" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Partner Notification -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Partner Notification</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">Date of initial Partner Notification discussion</label>
                                        <input type='date' class="form-control"  name="DateOfInitialPartnerNotificationDiscussion"
                                            ngModel value="{{ this.Case.DateOfInitialPartnerNotificationDiscussion | date: 'yyyy-MM-dd' }}" disabled #DateOfInitialPartnerNotificationDiscussion ="ngModel" id="DateOfInitialPartnerNotificationDiscussion">                                         
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label class="">How many partners were reported for the STI(s) diagnosed?</label>
                                        <select class="form-control" name="HowManyPartnersWereReportedForTheSTIsDiagnosed"
                                            ngModel [(ngModel)]="Case.HowManyPartnersWereReportedForTheSTIsDiagnosed" disabled #HowManyPartnersWereReportedForTheSTIsDiagnosed ="ngModel" id="HowManyPartnersWereReportedForTheSTIsDiagnosed" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">How many partners were contactable?</label>
                                        <select class="form-control" name="HowManyPartnersWereContactable"
                                            ngModel [(ngModel)]="Case.HowManyPartnersWereContactable" disabled #HowManyPartnersWereContactable ="ngModel" id="HowManyPartnersWereContactable" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">How many partners are reported to have attended a sexual health service within 4 weeks of the initial PN discussion?</label>
                                        <select class="form-control" name="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion"
                                            ngModel [(ngModel)]="Case.HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion" disabled #HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion ="ngModel" id="HowManyPartnersAreReportedToHaveAttendedASexualHealthServiceWithin4WeeksOfTheInitialPNDiscussion" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Any number 0-99</option>
                                            <option value="2">Not applicable</option>
                                        </select>
                                    </div>

                                    

                                </div>

                                <!-- PrEp -->

                                <!-- <div class="p-2 border bg-info"><h4 class='text-white'>PrEP</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">The patient's eligibility criteria for accessing PrEP</label>
                                        <select class="form-control"  name="ThePatientsEligibilityCriteriaForAccessingPrEP"
                                            ngModel [(ngModel)]="Case.ThePatientsEligibilityCriteriaForAccessingPrEP" disabled #ThePatientsEligibilityCriteriaForAccessingPrEP ="ngModel" id="ThePatientsEligibilityCriteriaForAccessingPrEP">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">MSM/Transgender woman</option>
                                            <option value="2">HIV positive partner</option>
                                            <option value="3">Other high risk</option>
                                            <option value="4">Not applicable</option>
                                        </select>

                                    </div>
                                    
                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">How many partners are verified to have attended a sexual health service within 4 weeks of the initial PN discussion?</label>                                       
                                            <select type="text" class="form-control" name="ConsultationReferral"
                                            ngModel [(ngModel)]="Case.ConsultationReferral" disabled #ConsultationReferral ="ngModel" id="ConsultationReferral">
                                                  <option value="">Select</option>  
                                                  <option value="1">Any number 0-99</option>
                                                  <option value="2">Not applicable</option>
                                                  </select>                                        
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">The outcome of the PrEP offer</label>
                                        <select class="form-control" name="TheOutcomeOfThePrEPOffer"
                                            ngModel [(ngModel)]="Case.TheOutcomeOfThePrEPOffer" disabled #TheOutcomeOfThePrEPOffer ="ngModel" id="TheOutcomeOfThePrEPOffer" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Accepted</option>
                                            <option value="2">Declined - patient refused PrEP</option>
                                            <option value="3">Declined - patient is obtaining PrEP elsewhere</option>
                                            <option value="4">Not applicable</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">PrEP regimen recommended</label>
                                        <select class="form-control" name="PrEPRegimenRecommended"
                                            ngModel [(ngModel)]="Case.PrEPRegimenRecommended" disabled #PrEPRegimenRecommended ="ngModel" id="PrEPRegimenRecommended" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Daily (or nearly daily)</option>
                                            <option value="2">Event based (coital)</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">The number of PrEP tablets prescribed</label>
                                        <select class="form-control" name="TheNumberOfPrEPTabletsPrescribed"
                                            ngModel [(ngModel)]="Case.TheNumberOfPrEPTabletsPrescribed" disabled #TheNumberOfPrEPTabletsPrescribed ="ngModel" id="TheNumberOfPrEPTabletsPrescribed" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">30 tablets</option>
                                            <option value="2">60 tablets</option>
                                            <option value="3">90 tablets</option>
                                            <option value="4">Other amount</option>
                                            <option value="5">Not applicable</option>
                                            <option value="6">Not asked</option>
                                            <option value="7">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="Custom-Text">If PrEP has been stopped, what was the reason?</label>                                       
                                        <select class="form-control" name="IfPrEPHasBeenStoppedWhatWasTheReason"
                                            ngModel [(ngModel)]="Case.IfPrEPHasBeenStoppedWhatWasTheReason" disabled #IfPrEPHasBeenStoppedWhatWasTheReason ="ngModel" id="IfPrEPHasBeenStoppedWhatWasTheReason" >                                       
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Adverse event</option>
                                            <option value="2">HIV acquisition</option>
                                            <option value="3">Patient choice</option>
                                            <option value="4">No longer eligible</option>
                                            <option value="5">Other</option>
                                            <option value="6">Not applicable</option>
                                            <option value="7">Not asked</option>
                                            <option value="8">Not known/Not stated</option>
                                        </select>                                        
                                    </div>

                                </div> -->

                                <!-- Behaviour -->

                                <div class="p-2 border bg-info"><h4 class = 'text-white'>Behaviour</h4></div>
                                
                                <div class="form-group row">
                                    <div class="col-6 mt-2">
                                        <label class="">Was alcohol use assessed?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WasAlcoholUseAssessed"
                                            ngModel [(ngModel)]="Case.WasAlcoholUseAssessed" disabled #WasAlcoholUseAssessed ="ngModel" id="WasAlcoholUseAssessed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Was alcohol use documented as problematic?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WasAlcoholUseDocumentedAsProblematic"
                                            ngModel [(ngModel)]="Case.WasAlcoholUseDocumentedAsProblematic" disabled #WasAlcoholUseDocumentedAsProblematic ="ngModel" id="WasAlcoholUseDocumentedAsProblematic">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Have you used recreational drugs in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="HaveYouUsedRecreationalDrugsInTheLast3Months"
                                            ngModel [(ngModel)]="Case.HaveYouUsedRecreationalDrugsInTheLast3Months" disabled #HaveYouUsedRecreationalDrugsInTheLast3Months ="ngModel" id="HaveYouUsedRecreationalDrugsInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Amphetamine/Speed</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeAmphetamineOrSpeed"
                                            ngModel [(ngModel)]="Case.DidYouTakeAmphetamineOrSpeed" disabled #DidYouTakeAmphetamineOrSpeed ="ngModel" id="DidYouTakeAmphetamineOrSpeed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Benzodiazepines (non-prescribed)</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeBenzodiazepinesNonPrescribed"
                                            ngModel [(ngModel)]="Case.DidYouTakeBenzodiazepinesNonPrescribed" disabled #DidYouTakeBenzodiazepinesNonPrescribed ="ngModel" id="DidYouTakeBenzodiazepinesNonPrescribed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Cannabis</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCannabis"
                                            ngModel [(ngModel)]="Case.DidYouTakeCannabis" disabled #DidYouTakeCannabis ="ngModel" id="DidYouTakeCannabis">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Cocaine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCocaine"
                                            ngModel [(ngModel)]="Case.DidYouTakeCocaine" disabled #DidYouTakeCocaine ="ngModel" id="DidYouTakeCocaine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <!-- <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option> -->
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Crack</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCrack"
                                            ngModel [(ngModel)]="Case.DidYouTakeCrack" disabled #DidYouTakeCrack ="ngModel" id="DidYouTakeCrack">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Crystal Meth/Methamphetamine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeCrystalMethMethamphetamine"
                                            ngModel [(ngModel)]="Case.DidYouTakeCrystalMethMethamphetamine" disabled #DidYouTakeCrystalMethMethamphetamine ="ngModel" id="DidYouTakeCrystalMethMethamphetamine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Ecstasy (E)/MDMA</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeEcstasy_E_MDMA"
                                            ngModel [(ngModel)]="Case.DidYouTakeEcstasy_E_MDMA" disabled #DidYouTakeEcstasy_E_MDMA ="ngModel" id="DidYouTakeEcstasy_E_MDMA">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take GHB/GBL</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeGHBOrGBL"
                                            ngModel [(ngModel)]="Case.DidYouTakeGHBOrGBL" disabled #DidYouTakeGHBOrGBL ="ngModel" id="DidYouTakeGHBOrGBL">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Heroin</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeHeroin"
                                            ngModel [(ngModel)]="Case.DidYouTakeHeroin" disabled #DidYouTakeHeroin ="ngModel" id="DidYouTakeHeroin">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Ketamine</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeKetamine"
                                            ngModel [(ngModel)]="Case.DidYouTakeKetamine" disabled #DidYouTakeKetamine ="ngModel" id="DidYouTakeKetamine">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take novel psychoactive substances</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeNovelPsychoactiveSubstances"
                                            ngModel [(ngModel)]="Case.DidYouTakeNovelPsychoactiveSubstances" disabled #DidYouTakeNovelPsychoactiveSubstances ="ngModel" id="DidYouTakeNovelPsychoactiveSubstances">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Mephedrone (M-Cat)</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeMephedroneM_Cat"
                                            ngModel [(ngModel)]="Case.DidYouTakeMephedroneM_Cat" disabled #DidYouTakeMephedroneM_Cat ="ngModel" id="DidYouTakeMephedroneM_Cat">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Methadone</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeMethadone"
                                            ngModel [(ngModel)]="Case.DidYouTakeMethadone" disabled #DidYouTakeMethadone ="ngModel" id="DidYouTakeMethadone">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Poppers</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakePoppers"
                                            ngModel [(ngModel)]="Case.DidYouTakePoppers" disabled #DidYouTakePoppers ="ngModel" id="DidYouTakePoppers">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take Solvents/Glue</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeSolventsGlue"
                                            ngModel [(ngModel)]="Case.DidYouTakeSolventsGlue" disabled #DidYouTakeSolventsGlue ="ngModel" id="DidYouTakeSolventsGlue">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you take any other recreational drug (not listed)?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouTakeAnyOtherRecreationalDrugNotListed"
                                            ngModel [(ngModel)]="Case.DidYouTakeAnyOtherRecreationalDrugNotListed" disabled #DidYouTakeAnyOtherRecreationalDrugNotListed ="ngModel" id="DidYouTakeAnyOtherRecreationalDrugNotListed">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you inject any recreational drug in the last 3 months?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouInjectAnyRecreationalDrugInTheLast3Months"
                                            ngModel  [(ngModel)]="Case.DidYouInjectAnyRecreationalDrugInTheLast3Months" disabled #DidYouInjectAnyRecreationalDrugInTheLast3Months ="ngModel" id="DidYouInjectAnyRecreationalDrugInTheLast3Months">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Did you share equipment with anyone when injecting drugs?</label>                                                                                                                                                                    
                                        <select class="form-control" name="DidYouShareEquipmentWithAnyoneWhenInjectingDrugs"
                                            ngModel [(ngModel)]="Case.DidYouShareEquipmentWithAnyoneWhenInjectingDrugs" disabled #DidYouShareEquipmentWithAnyoneWhenInjectingDrugs ="ngModel" id="DidYouShareEquipmentWithAnyoneWhenInjectingDrugs">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                    <div class="col-6 mt-2">
                                        <label class="">Were you under the influence of recreational drugs the last tme you had sex?</label>                                                                                                                                                                    
                                        <select class="form-control" name="WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex"
                                            ngModel [(ngModel)]="Case.WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex" disabled #WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex ="ngModel" id="WereYouUnderTheInfluenceOfRecreationalDrugsTheLastTimeYouHadSex">                                         
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Not applicable</option>
                                            <option value="4">Not asked</option>
                                            <option value="5">Not known/Not stated</option>
                                        </select>
                                    </div>

                                </div>
                                <!-- <div class="form-group row">
                                    <div class="col-6">
                                        <label class="">The source of the patient attendance</label>
                                            <select  class="form-control" ngModel name="TheNumberOfPrEPTabletsPrescribed"
                                            [(ngModel)]="Case.TheNumberOfPrEPTabletsPrescribed" #TheNumberOfPrEPTabletsPrescribed ="ngModel">  
                                            <option value="" [defaultSelected]="true">Select</option>
                                            <option value="1">Self-referral</option>
                                            <option value="2">SRH</option>
                                            <option value="3">GUMCAD STI Surveillance System Change Specification</option>
                                            <option value="4">GP</option>
                                            <option value="5">Prison</option>
                                            <option value="6">NCSP</option>
                                            <option value="7">Online</option>
                                        </select>
                                    
                                        </div>
                                </div> -->
                                <!-- <div class="form-group row">
                                    <label class="control-label col-sm-3">{{'Documents.Priority'|translate}}</label>
                                    <div class="col-sm-9">
                                        <select class="list-dt  form-control" id="prioritycode" ngModel
                                            name="prioritycode" #name="ngModel" [(ngModel)]="Case.prioritycode">
                                            <option value=" " selected disabled>Select Priority</option>
                                            <option value="1">High</option>
                                            <option value="2">Normal</option>
                                            <option value="3">Low</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div class="form-group row ">
                                    <label
                                        class="control-label col-sm-3">{{'Documents.OnlineConsultationType'|translate}}</label>
                                    <div class="col-sm-9 ">
                                        <select class="list-dt  form-control" id="casetypecode" ngModel #name="ngModel"
                                            [(ngModel)]="Case.casetypecode" ngModel name="casetypecode">
                                            <option value=" " selected disabled>Select Type</option>
                                            <option value="1">Question</option>
                                            <option value="2">Problem</option>
                                            <option value="3">Request</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    
                                </div>

                                <!-- <div class="form-group row">
                                    <label class="control-label col-sm-3 required">Upload file</label>
                                    <div class="col-sm-9">
                                        <p>:-<br> (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc, txt)</p>
                                        <input type="file" (change)="IncomingFile($event.target.files);" />
                                    </div>
                                </div> -->

                                <!-- <input type="submit" class="btn btn-primary pull-right" value="Update"> -->
                            </form>
                        </div>
                    </div>
                    <!-- / .modal-content -->

                </div>
                <!-- / .modal-dialog -->

        </div>
    </div>
</div>
