<!-- <section style="margin: 3% 0% 1% 0%;">
  <div class="row no-gutters" style="justify-content: center;">

    <div class="col-lg-9 col-md-12 heading-section3">
      <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Observations</h1>
    </div>
  </div>
</section> 
ftco-section-->
<section class=" m-3">
    <div class="container px-md-0 table-responsive">
        <!-- <div class="pull-left"><a type="button" class="btn btn-primary" routerLink="../newprofile">Back</a>
      </div><br><br><br> -->
        <table class="table table-bordered" id="prescriptionRecord">
            <thead class="thead-light">
                <tr class="trColor">
                    <th class="thFont" colspan="8">
                        <div class="pull-right"><input type="button" href="#CreateObservationModel" data-toggle="modal" class="btn btn-primary" value="+ {{'NewObservatiom.AddObservation'|translate}}"></div>
                    </th>
                </tr>
                <tr>
                    <th style="display: none;">S/No</th>
                    <th>{{'Common.description'|translate}}</th>
                    <th>{{'NewObservatiom.ReadingCategories'|translate}}</th>
                    <!-- <th>Snomed</th> -->
                    <th>{{'NewObservatiom.Values'|translate}}</th>
                    <th>{{'Common.comments'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of ObservationsList; index as i">
                    <td style="display: none;">{{i + 1}}</td>
                    <td>{{data.msemr_description}}</td>
                    <td>{{data.bcrm_readingcategories_name}}</td>
                    <td>
                        <p *ngIf="data.bcrm_readingcategories_name=='Weight'">{{data.bcrm_weightinkilograms}} KG</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Height'">{{data.bcrm_heightinmetres}} Meters</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Blood Pressure'">{{data.msemr_valuerangehighlimit}}/{{data.msemr_valuerangelowlimit}}</p>
                        <p *ngIf="data.bcrm_readingcategories_name=='Diabetes'">{{data.bcrm_bloodsugarbeforemeals}}/{{data.bcrm_bloodsugaraftermeal}}</p>
                    </td>
                    <td>{{data.msemr_comment}}</td>

                </tr>
            </tbody>
        </table>
        <br />

        <div id="CreateObservationModel" class="modal fade">
            <!-- class modal and fade -->
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- modal header -->
                        Create Observation Request
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
                    </div>
                    <div class="modal-body">
                        <!-- modal body -->
                        <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm" autocomplete="off" id="CreateObservation">

                            <div class="form-group row">
                                <label class="col-md-3  col-sm-3 required">{{'NewObservatiom.ReadingCategories'|translate}}</label>
                                <div class="col-sm-9">
                                    <select class="form-control " id='purpose' name="bcrm_readingcategories" ngModel required (change)="onCategory($event.target.value)" #bcrm_readingcategories="ngModel" #name="ngModel">
                    <option value="">Select Reading Categories</option>
                    <option value="101">Blood Pressure</option>
                    <option value="102">Diabetes</option>
                    <option value="103">Height</option>
                    <option value="104">Weight</option>
                    <option value="105">Other</option>
                  </select>
                                </div>
                            </div>
                            <div class="form-group row" id="msemr_description">
                                <label class="control-label col-sm-3">{{'Common.description'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control1" id="msemr_description1" placeholder="{{'Common.description'|translate}}" name="msemr_description" ngModel required #msemr_description="ngModel" readonly>
                                    <input type="hidden" name="browser" id="browser1">
                                    <input type="hidden" name="bcrm_snomedname" id="snomedname">
                                    <input type="hidden" id="bcrm_conceptid">
                                </div>
                            </div>
                            <div class="form-group row" id="Snomed">
                                <label class="control-label col-sm-3">{{'NewObservatiom.Snomed'|translate}}</label>
                                <div class="col-sm-9">
                                    <input list="browsers" name="browser" class="form-control getSnomed">
                                    <datalist id="browsers">
                      <option [value]="title.pt.term" *ngFor="let title of QueryResult"   class="form-control" >{{title.pt.term}}</option> 
                      <input type="hidden"  [value]="title.conceptId" id="getCode"  *ngFor="let title of QueryResult" >
                      <input type="hidden" name="name" class="form-control" ngModel required #name="ngModel"
                 id="snomedname">
                  </datalist>
                                </div>
                            </div>


                            <div class="form-group row" id="SnomedCode">
                                <label class="control-label col-sm-3">{{'Condition.code'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control1" placeholder="{{'Condition.code'|translate}}" name="msemr_code" ngModel required #msemr_code="ngModel" id="msemr_code" disabled>
                                </div>
                            </div>
                            <div class="form-group row" id="msemr_valuerangehighlimit">
                                <label class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.SystolicPressure'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="Enter High Value" name="msemr_valuerangehighlimit" ngModel required #msemr_valuerangehighlimit="ngModel">
                                </div>
                            </div>
                            <div class="form-group row" id="msemr_valuerangelowlimit">
                                <label class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.DiastolicPressure'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="Enter Low Value" name="msemr_valuerangelowlimit" ngModel required #msemr_valuerangelowlimit="ngModel">
                                </div>
                            </div>
                            <div class="form-group row" id="bcrm_bloodsugarbeforemeals">
                                <label class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.BeforeMeals'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="{{'NewObservatiom.BeforeMeals'|translate}}" name="bcrm_bloodsugarbeforemeals" ngModel required #bcrm_bloodsugarbeforemeals="ngModel">
                                </div>
                            </div>
                            <div class="form-group row" id="bcrm_bloodsugaraftermeal">
                                <label class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.AfterMeals'|translate}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="{{'NewObservatiom.AfterMeals'|translate}}" name="bcrm_bloodsugaraftermeal" ngModel required #bcrm_bloodsugaraftermeal="ngModel">
                                </div>
                            </div>
                            <div class="form-group row" id="bcrm_weightinkilograms">
                                <label class="control-label col-md-3 col-sm-3 required">{{'NewObservatiom.Weight'|translate}}</label>
                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="kg" id="bcrm_weight_kg" name="bcrm_weightinkilograms" ngModel required #bcrm_weightinkilograms="ngModel" (keyup)="weightConverter('bcrm_weight_kg')">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted required">{{'NewObservatiom.WeightinKg'|translate}}</small>
                                </div>

                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="Gram" id="bcrm_weight_gram" (keyup)="weightConverter('bcrm_weight_gram')">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted ">{{'NewObservatiom.Weightingram'|translate}}</small>
                                </div>

                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="Pound" id="bcrm_weight_pound" (keyup)="weightConverter('bcrm_weight_pound')">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted">{{'NewObservatiom.Weightinpound'|translate}}</small>
                                </div>

                            </div>
                            <div class="form-group row" id="bcrm_heightinmetres">
                                <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.Height'|translate}}</label>
                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="Meter" id="bcrm_height_meter" (keyup)="LengthConverter('bcrm_height_meter')" name="bcrm_heightinmetres" ngModel required #bcrm_heightinmetres="ngModel">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted required">{{'NewObservatiom.HeightinMeter'|translate}}</small>
                                </div>

                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="Feet" id="bcrm_height_feet" (keyup)="LengthConverter('bcrm_height_feet')">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted">{{'NewObservatiom.Heightinfeet'|translate}}</small>
                                </div>
                                <div class="col-sm-2">
                                    <input type="number" class="form-control" placeholder="Cm" id="bcrm_height_cm" (keyup)="LengthConverter('bcrm_height_cm')">
                                    <small id="bcrm_height_MetersText" class="form-text text-muted">{{'NewObservatiom.Heightincm'|translate}}</small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="control-label col-md-3 col-sm-3">{{'Common.comments'|translate}}</label>
                                <div class="col-sm-9">
                                    <textarea type="text" class="form-control" rows="3" placeholder="{{'Common.comments'|translate}}" name="msemr_comment" ngModel #msemr_comment="ngModel"></textarea>
                                </div>
                            </div>
                            <input type="submit" class="btn btn-primary pull-right" value="{{'Common.Save'|translate}}">
                        </form>
                    </div>
                </div>
                <!-- / .modal-content -->

            </div>
            <!-- / .modal-dialog -->

        </div>

        <div id="UpdateObservationModel" class="modal fade">
            <!-- class modal and fade -->
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        {{'Common.UpdateObservation'|translate}}
                        <!-- modal header -->
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            ×
          </button>
                    </div>
                    <div class="modal-body  result-shade1">
                        <!-- modal body -->
                        <form class="form" #EditDetails="ngForm" (ngSubmit)="onUpdate(EditDetails)" #f="ngForm" autocomplete="off">
                            <div class="form-group row">
                                <label class="control-label col-sm-3">{{'Common.description'|translate}}:</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="{{'Common.description'|translate}}" [(ngModel)]="Observation.msemr_description" name="msemr_description" ngModel required #msemr_description="ngModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3  col-sm-3 ">{{'NewObservatiom.ReadingCategories'|translate}}:</label>
                                <div class="col-sm-9">
                                    <select class="form-control" id='purpose' name="bcrm_readingcategories" [(ngModel)]="Observation.bcrm_readingcategories" ngModel required #bcrm_readingcategories="ngModel" #name="ngModel">
                  <option value="">Select Reading Categories</option>
                  <option value="101">Blood Pressure</option>
                  <option value="102">Diabetes</option>
                  <option value="103">Height</option>
                  <option value="104">Weight</option>
                  <option value="105">Other</option>
                </select>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                  <label class="col-md-3  col-sm-3">Snomed Name :</label>
                  <div class="col-sm-4">
                    <input type="text" class="form-control getUpdateSnomed" placeholder="Search Snomad By Name">
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" id='snomedname' name="bcrm_conceptid" [(ngModel)]="Observation.bcrm_conceptid" ngModel required
                      #bcrm_conceptid="ngModel" #name="ngModel">
                      <option value="">Select Snomed Code</option>
                      <option *ngFor="let snomedData of snomedCodeList" value="{{snomedData.conceptId}}">
                        {{snomedData.code}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-md-3 col-sm-3">Quantity:</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" placeholder="Enter Quantity" [(ngModel)]="Observation.msemr_valuetypequantityvalue"
                      name="msemr_valuetypequantityvalue" ngModel required #msemr_valuetypequantityvalue="ngModel">
                  </div>
                </div> -->
                            <div class="form-group row">
                                <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.High'|translate}}:</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="Enter High Value" [(ngModel)]="Observation.msemr_valuerangehighlimit" name="msemr_valuerangehighlimit" ngModel required #msemr_valuerangehighlimit="ngModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-3 col-sm-3">{{'NewObservatiom.Low'|translate}}:</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="Enter Low Value" name="msemr_valuerangelowlimit" [(ngModel)]="Observation.msemr_valuerangelowlimit" ngModel required #msemr_valuerangelowlimit="ngModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-md-3 col-sm-3">{{'Common.comments'|translate}}:</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" placeholder="{{'Common.comments'|translate}}" name="msemr_comment" [(ngModel)]="Observation.msemr_comment" ngModel required #msemr_comment="ngModel">
                                </div>
                            </div>
                            <input type="submit" class="btn btn-primary pull-right" value="{{'Profile.update'|translate}}">
                        </form>
                    </div>
                </div>
                <!-- / .modal-content -->

            </div>
            <!-- / .modal-dialog -->

        </div>

    </div>
</section>