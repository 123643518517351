import { Component, OnInit } from '@angular/core';
import { NhsService } from '../nhs/nhs.service';
import axios from 'axios';
declare var $: any;
@Component({
  selector: 'app-live-well',
  templateUrl: './live-well.component.html',
  styleUrls: ['./live-well.component.css']
})
export class LiveWellComponent implements OnInit {

  constructor(private nhsService: NhsService) {
        /******Active Current Component Nav****** */
        $(".nav-item").removeClass("active");
        $("#nav-item-NHS").addClass("active");
        /******End:Active Current Component Nav****** */
   }

  ngOnInit(): void {
    $(document).ready(function () {
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /**************************************** */
      $("#condit").select2({
        //data: country
      });
    });
    const config = {

      headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
    };
    axios.get(`https://api.nhs.uk/live-well/`,
      config)
      .then((response) => {

        
        if (response !== null && response !== undefined) {
          $('#liveWellHeader').append(response.data.about.name);
          $('#liveWellDescription').append(response.data.description);
          for (var i = 0; i < response.data.relatedLink.length; i++) {
            $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[i].url + "'>" + response.data.relatedLink[i].name + "</a><br>");
          }
          for (var j = 0; j < response.data.mainEntityOfPage.length; j++) {
            $('#mainContent').append("<div class='col-md-4'><div class='card' style='width: 20rem;margin-bottom: 15px;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);'><div class='card-body'><h5 class='card-title'>" + response.data.mainEntityOfPage[j].name + "</h5><p class='card-text'>" + response.data.mainEntityOfPage[j].text + "</p><a target='_blank' href='" + response.data.mainEntityOfPage[j].url + "' class='card-link'>" + response.data.mainEntityOfPage[j].name + "</a></div></div></div>");
          }
          for (var k = 0; k < response.data.significantLink.length; k++) {
            $('#significantLink').append("<ul><li><a target='_blank' href='" + response.data.significantLink[k].url + "'>" + response.data.significantLink[k].name + "</a></li><ul>");
            $('#significantLink').append("<p>" + response.data.significantLink[k].description + "</p>");

          }
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }
      })
  }

}
