import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { SharepointService } from 'src/app/ApiServices/SharePoint/sharepoint.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-share-point',
  templateUrl: './share-point.component.html',
  styleUrls: ['./share-point.component.css']
})
export class SharePointComponent implements OnInit {
  path: any; parentUrl: string; CurrentDirectory: string; FolderName: string; oldObj: []; insialData: []; uploadFile: true; dataTable: true; NewFolder: any; ResotreFolder: any; tableData: [];
  folderData: any; folderIsExist: boolean; searchText: string;

  constructor(private router: Router,
    public sharepointService: SharepointService,
    private toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initializationfunction();
  }

  //Reload All data and gettting all information from server*/
  initializationfunction() {

    this.tableData = [];
    if (environment.LoginUserId != null && environment.LoginUserId != "") {
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /****************** -------- **********************/
      this.sharepointService.getFile(environment.LoginUserId).subscribe((results) => {
        
        if (results != null) {
          if (results['children'] != null || results['label'] != null) {
            //$("#iframeReload").hide();
            this.folderIsExist = true;
            this.folderData = results;
            console.log(results);
            this.ResotreFolder = results;
            this.FolderName = this.folderData.children[0].label;
            this.CurrentDirectory = this.folderData.children[0].relativeUrl;
            var p = this.CurrentDirectory.split('/');
            this.path = [];
            if (p[0] == "https:") {
              for (var i = 3; i < p.length; i++) {
                if (p[i] == "contact") { }
                else { this.path.push(p[i]); }
              }
            }
            else {
              for (var i = 2; i < p.length; i++) {
                this.path.push(p[i]);
              }
            }
            this.parentUrl = this.folderData.children[0].parentUrl.split(this.FolderName)[0];
            this.tableData = results['children'][0];
          }
          else {
          }
        }

        /**************************************** */
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });

    }
    else {

    }
  }

  DownloadIntoLocal(ListOfFile: any): void {
    
    if (ListOfFile.type == "folder") {

      this.FolderName = ListOfFile.label;
      this.CurrentDirectory = ListOfFile.relativeUrl;
      this.parentUrl = ListOfFile.parentUrl;
      var p = ListOfFile.relativeUrl.split('/');
      this.path = [];
      if (p[0] == "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p[i] == "contact") { }
          else { this.path.push(p[i]); }
        }
      }
      else {
        for (var i = 2; i < p.length; i++) {
          this.path.push(p[i]);
        }
      }
      this.parentUrl = ListOfFile.parentUrl.toLowerCase().split('/' + this.FolderName)[0];
      this.ReloadData(ListOfFile);
    }
    else if (ListOfFile.relativeUrl != null && ListOfFile.relativeUrl != "" && ListOfFile.relativeUrl != undefined) {

      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /****************** -------- **********************/
      this.sharepointService.downloadFile(ListOfFile.relativeUrl).subscribe((results) => {

        var linkElement = document.createElement('a');
        try {
          var __blob = new Blob([results], { type: "application/octet-stream" });
          var url = window.URL.createObjectURL(__blob);

          linkElement.setAttribute('href', url);
          linkElement.setAttribute("download", ListOfFile.label);

          var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
          });
          linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
          console.log(ex);
        }
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
      }
        , (err) => {

          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error : ");
          //this.router.navigate(['/']);
        });
    }
    else {
      this.toastr.info("Does't have Retrival Path for this File ")
    }
  }

  ReloadData(obj): void {

    var localthis = this;
    this.oldObj = this.tableData;
    this.tableData = [];
    if (obj.type == "folder") {
      // setTimeout(function () {

      // }, 1000);
      console.log("INNER");
      this.tableData = Object.assign({}, obj);
      console.log(this.tableData);
      localthis.changeDetection.detectChanges();
    }
  }

  back(fname): void {
    var l = this.tableData;
    if (this.ResotreFolder.label == "fname") {
      this.tableData = this.ResotreFolder;
      this.CurrentDirectory = this.ResotreFolder.RelativeUrl;
      var p = this.ResotreFolder.RelativeUrl.split('/');
      this.path = [];
      if (p[0] == "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p[i] == "contact") { }
          else { this.path.push(p[i]); }
        }
      }
      else {
        for (var i = 2; i < p.length; i++) {
          this.path.push(p[i]);
        }
      }
    }
    else {
      this.getfolder(fname, this.ResotreFolder.children);
    }
  }

  getfolder(fname, root): void {

    for (var j = 0; j < root.length; j++) {

      if (root[j].label == fname) {
        this.parentUrl = root[j].parentUrl;
        this.FolderName = fname;
        var m = root[j];
        this.CurrentDirectory = root[j].relativeUrl;
        var p = root[j].relativeUrl.split('/');
        this.path = [];
        if (p[0] == "https:") {
          for (var i = 3; i < p.length; i++) {
            if (p[i] == "contact") { }
            else { this.path.push(p[i]); }
          }
        }
        else {
          for (var i = 2; i < p.length; i++) {
            this.path.push(p[i]);
          }
        }
        this.assign(root[j]);
      }
      else {
        if (root[j].children != null) {

          this.getfolder(fname, root[j].children);
        }
      }
    }
  }
  assign(data): void {
    this.tableData = data;
    this.changeDetection.detectChanges();
  }

  /*Add Folder IN Sharepoint Location*/
  AddFolder() {
    $("#W2P1").modal("toggle");
    // BootstrapDialog.alert($("#W2P1").html());
  }

  onUploadFile(changeEvent): void {
    
    let currentFolderFileList = this.tableData['children'];
    var file = changeEvent.target.files[0];
    let isFilePresentInDirectory = currentFolderFileList.find(x => x['label'] == file.name);
    if (isFilePresentInDirectory != undefined) {
      $('input[type="file"]').val('');
      this.toastr.info("File Already Exist in the Directory please Change the File Name or Upload Another file", "");
    } else {
      var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.xlsx|\.word|\.pdf|\.mp4|\.avi|\.wmv|\.mp3|\.wav|\.mpeg-4|\.zip)$/i;
      if (!allowedExtensions.exec(file.name)) {
        this.toastr.info("This File Extension Not Allowed to Upload in Sharepoint ", "")
        $('input[type="file"]').val('');

      } else {
        if (filesize <= 1) {
          //*************Show / hide loader *******************/
          $('#ftco-loader').addClass('show');
          /****************** -------- **********************/
          var payload = new FormData();
          payload.append("file", file);
          this.sharepointService.UploadFile(environment.LoginUserId, this.parentUrl, this.CurrentDirectory, this.FolderName, null, payload).subscribe((results) => {

            $('input[type="file"]').val('');
            this.initializationfunction();
            //*************Show / hide loader *******************/
            //$('#ftco-loader').removeClass('show');
            /****************** -------- **********************/
          }, (err) => {
            //*************Show / hide loader *******************/
            $('#ftco-loader').removeClass('show');
            /****************** -------- **********************/
            this.toastr.error(err, "Error : ");
            this.router.navigate(['/']);
          });
        }
        else {
          $('input[type="file"]').val('');
          this.toastr.info("The Maximum Size Limit of Document is 1 MB", "");
        }
      }
    }



  }

  onUploadFolder(it: NgForm) {

    $("#W2P1").modal("toggle");
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    var NewFolder = it.value;
    this.sharepointService.UploadFolder(environment.LoginUserId, this.parentUrl, this.CurrentDirectory, this.FolderName, NewFolder.name).subscribe((results) => {

      this.initializationfunction();
      //*************Show / hide loader *******************/
      // $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
}
