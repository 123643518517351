<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text">
            <h3>Digital Imagery</h3>
            <p><a >Home /</a> Digital Imagery</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br/>
  <!-- <div class="container"> <div class="pull-left"><a  type="button" class="btn btn-primary"routerLink="../newprofile">Back</a></div></div>
  -->
  <div class="container" style="border: 1px solid #999;">
    <div id="Cimar_Report_Data" style="padding: 1%">

    </div>
  </div><br>
