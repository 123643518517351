import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GetAppointmentsService {

  constructor(private http: HttpClient) { }

  getAppointmentsByEmail(Email, date) {
    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/Get?Email=${Email}&date=${date}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  getStartMeetingUrl(appointmentId,patientId) {
    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/GetStartMeetingURl?appointmentId=${appointmentId}&patientId=${patientId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  getAppointmentsByEmailAndRange(PatietnId, ToDate, FromDate) {

    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/GetAppointment?PatientId=${PatietnId}&startDate=${ToDate}&endDate=${FromDate}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  getFutureAppointment(PatietnId) {

    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/GetFutureAppointment?PatientId=${PatietnId}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  getAppointmentsByEmailForDoctorsView() {
    return this.http.get(`${environment.apiUrl}/api/CrmAppointment/GetByDay?email=${environment.LoginUserEmail}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  updatePatientStatus(App_id, Staus) {
    return this.http.put(`${environment.apiUrl}/api/Teams/UpdateAppointmentStatus?appointmetId=${App_id}&appointmentStatus=${Staus}`, "").pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  handleError(error) {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if (error.error != undefined) {
        errorMessageUi = error.error.message
      }
      else {
        errorMessageUi = error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}