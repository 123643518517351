<!------ Do not add anything above this tag ---------->
<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container containerpadding">
        <div class="row" style="justify-content: center;">
            <p class="hcolor">Contact Us</p>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-10">
        <h5 class="card-title font-weight-bold">
            <p>Contact us at contact@oxvc.health if you have any questions
             </p>         
        </h5>
       <br>
    </div>
</div>
<div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
        <div class="card">
            <div class="card-body">
            <form id="msform" #it="ngForm" (ngSubmit)="onSubmit(it)" #f="ngForm">
             
            
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" name="firstname" class="form-control required" ngModel
                                            #firstname="ngModel" placeholder="Enter First Name" required>
                                    </div>
                                </div>
            
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" name="lastname" class="form-control required" ngModel #lastname="ngModel"
                                            placeholder="Enter Last Name" required>
                                    </div>
                                </div>
            
                            </div>
            
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Mobile Number</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="mobilephone" placeholder="Enter Mobile Number"
                                            ngModel required #mobilephone="ngModel" #name="ngModel" ng2TelInput required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Office Number</label>
                                    <div class="col-sm-9">
                                        <input type="text" name="telephone1" class="form-control " ngModel 
                                            #telephone1="ngModel" placeholder="Enter Office Number">
                                    </div>
                                </div>
                            </div>
            
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Speciality</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id='bcrm_speciality' name="bcrm_speciality"
                                            #bcrm_speciality="ngModel" ngModel >
                                            <option value="">--Select Speciality--</option>
                                            <option *ngFor="let speciality of Specialitylist" [value]="speciality.Id">
                                                {{speciality.msemr_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating required col-sm-3">Email</label>
                                    <div class="col-sm-9">
                                        <input type="email" class="form-control" name="emailaddress1"
                                            placeholder="Enter Email" ngModel required
                                            #emailaddress1="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>
                          
            
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" name="address" class="form-control " ngModel 
                                            #address="ngModel" placeholder="Enter Address">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Education</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id='bcrm_education' name="bcrm_education"
                                            placeholder="Select education" ngModel #bcrm_education="ngModel">
                                            <option value="">--Select Education--</option>
                                            <option value="1">Postgraduate education</option>
                                            <option value="2">Continuing medical education</option>
            
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Qualifications</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id='bcrm_qualifications' name="bcrm_qualifications" ngModel
                                            #bcrm_qualifications="ngModel" >
                                            <option value="">--Select Qualification--</option>
                                            <option *ngFor="let qualification of Qualificationslist" [value]="qualification.Id">
                                                {{qualification.msemr_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Website URL</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="bcrm_websiteurl"
                                            placeholder="Enter Website URL" ngModel  #bcrm_websiteurl="ngModel"
                                            #name="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">GMC Number</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="bcrm_gmcnumber" placeholder="Enter GMC Number"
                                            ngModel  #bcrm_gmcnumber="ngModel" #name="ngModel">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">Assistant</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" name="bcrm_assistant" placeholder="Enter Assistant"
                                            ngModel  #bcrm_assistant="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row bmd-form-group">
                                    <label class="bmd-label-floating  col-sm-3">About Me</label>
            
                                </div>
                            </div>
            
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <textarea class="round" name="bcrm_aboutme" rows="4" cols="210" ngModel #aboutme="ngModel"
                                    #bcrm_aboutme="ngModel"></textarea>
                            </div>
            
                        </div>
                        <div class="row pull-left" style="padding: 1% 1% 3% 1%;">
                            <input type="submit" class="btn btn-primary " value="Submit" [disabled]='!it.valid'>
                        </div>
            
            
            </form>
            
            </div>
            </div>
    </div>
    <div class="col-lg-2"></div>
</div>
<br>

