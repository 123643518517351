<!-- <section style="margin: 3% 0% 1% 0%;">
    <div class="row no-gutters" style="justify-content: center;">

        <div class="col-lg-9 col-md-12 heading-section3">
            <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Invoices</h1>
        </div>
    </div>
</section> 
ftco-section-->
<section class="m-3">
    <div class="container px-md-0 table-responsive">
        <!-- <div class="pull-left"><a  type="button" class="btn btn-primary"routerLink="../newprofile">Back</a>
        </div><br><br><br> -->
        <table class="table table-bordered" id="invoiceRecord">
            <thead class="thead-light">
                <tr>
                    <th>{{'Invoice.invoiceName'|translate}} </th>
                    <th>{{'Invoice.invoiceId'|translate}} </th>
                    <th>{{'Invoice.totalAmount'|translate}} </th>
                    <th>{{'Invoice.currency'|translate}}</th>
                    <th>{{'Common.Status'|translate}}</th>
                    <th>{{'Common.createdOn'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of invoiceList">
                    <td>{{invoice.name}}</td>
                    <td>{{invoice.invoicenumber}}</td>
                    <td>{{invoice.totalamount}}</td>
                    <td>{{invoice.transactioncurrencyidName}}</td>
                    <td>{{invoice.statuscode}}</td>
                    <td>{{invoice.createdon | date:'dd-MMM-yyyy'}}</td>
            </tbody>
        </table>
    </div>
</section>