import { Component, OnInit } from '@angular/core';
import { KbarticleService } from 'src/app/ApiServices/KbArticle/kbarticle.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-kbarticle',
  templateUrl: './kbarticle.component.html',
  styleUrls: ['./kbarticle.component.css']
})
export class KBArticleComponent implements OnInit {
  data: object;
  TotalKbArticle: any;
  NoKBA = false;
  searchDept;
  constructor(private KbarticleService: KbarticleService,
    private router: Router,
    private toastr: ToastrService) {
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-KBarticle").addClass("active");
    /******End:Active Current Component Nav****** */
  }

  ngOnInit(): void {
    this.getAllArticles()
  }
  getAllArticles(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    
    this.KbarticleService.getAllArticles().subscribe((res) => {

      if (res != null) {
        this.data = res;
      }
      else {
        this.toastr.warning("No Departments");
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      // this.toastr.warning(err, "Error : ");
      this.toastr.warning("No Departments");
      //this.router.navigate(['/']);
    });
  }


  KBview(data) {

  }

}
