import { Component, OnInit } from '@angular/core';
import { CrmconditionsService } from 'src/app/ApiServices/Condition/crmconditions.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-condition-new',
  templateUrl: './condition-new.component.html',
  styleUrls: ['./condition-new.component.css']
})
export class ConditionNewComponent implements OnInit {
  Condition: object;
  constructor(private CrmconditionsService: CrmconditionsService) { }

  ngOnInit(): void {
    var data:any=JSON.parse(localStorage.getItem("userProfileDataClaims"));
    environment.LoginUserEmail=data.userEamil;
     environment.LoginUserId= data.userId;
   this.getConditions()
  }

  RefreshPage(){
    this.ngOnInit()
  }
  getConditions(): void {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.Condition = null;
debugger;
    this.CrmconditionsService.getConditions(environment.LoginUserId).subscribe((res) => {
      
      if(res != null){
        this.Condition = res;
    
      }

      setTimeout(() => {
        $('#dataTableExample').DataTable().destroy();
        $('#dataTableExample').DataTable({
          "aLengthMenu": [
            [10, 30, 50, -1],
            [10, 30, 50, "All"]
          ],
          "iDisplayLength": 10,
          "language": {
            search: ""
          }
        });
        $('#dataTableExample').each(function () {
          var datatable = $(this);
          // SEARCH - Add the placeholder for Search and Turn this into in-line form control
          var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
          search_input.attr('placeholder', 'Search');
          search_input.removeClass('form-control-sm');
          // LENGTH - Inline-Form control
          var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
          length_sel.removeClass('form-control-sm');
        });
      }, 500);

     
    
     //*************Show / hide loader *******************/
     $('#ftco-loader').removeClass('show');
     /**************************************** */
    });
  }
}
