import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KbarticleService } from 'src/app/ApiServices/KbArticle/kbarticle.service';
import { DepartmentsService } from 'src/app/ApiServices/Department/departments.service';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
declare var $: any;
@Component({
  selector: 'app-kbartical-details',
  templateUrl: './kbartical-details.component.html',
  styleUrls: ['./kbartical-details.component.css']
})
export class KBArticalDetailsComponent implements OnInit {

  /*********** variables  ************* */
  Token: string;
  TotalKbArticle: any;
  groupId: any;
allDoctors: any;
allergies: object;
searchText;
title:any;
  isLoggedIn: boolean = false;

  constructor(public KbarticleService: KbarticleService,
    public DepartmentsService:DepartmentsService,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private headerComponent: HeaderComponent) {
    this.Token = localStorage.getItem("AuthToken");
    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }
  ngOnInit(): void {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    var KbArticalId = this.activatedRoute.snapshot.paramMap.get('id');

    this.KbarticleService.KBDetailRecord(KbArticalId).subscribe((res) => {
      
      if (res != null) {
        this.TotalKbArticle = res;
        this.groupId =this.TotalKbArticle.KBArticle.bcrm_groupid;
        this.title = this.TotalKbArticle.KBArticle.title;
        $("#KBcontent").append(this.TotalKbArticle.KBArticle.detail.content);
        this.getDoctorList(this.groupId);
       
      } else {
        this.toastr.error("Error");
        $('#ftco-loader').removeClass('show');
      }
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      //********************************************/
    });


  
  }
  getDoctorList(groupId): void{
   
    this.DepartmentsService.getDepartmentsMembers(groupId).subscribe((res) => {
      
      this.allDoctors = res['value'];
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
     
    },(err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
    });
  }

 
}
