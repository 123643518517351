import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http'; 
import {Observable, throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
   providedIn: 'root'
   })

export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector){ }
  intercept(req: HttpRequest<any>, next: HttpHandler) { 
    debugger;
    let tokenizedReq = req.clone({     
      setHeaders: {
        //Authorization :`bearer ${localStorage.AuthToken}`,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains;',
        'Content-Security-Policy': "default-src 'self'; style-src 'self' 'unsafe-inline'",
        'X-Frame-Options': 'SAMEORIGIN',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
         //Read token
      },
    })
    return next.handle(tokenizedReq)
  }
}

//complete
