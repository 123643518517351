import { Component, OnInit } from '@angular/core';
import { GetAppointmentsService } from 'src/app/ApiServices/Booking/get-appointments.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-all-doctor-view',
  templateUrl: './all-doctor-view.component.html',
  styleUrls: ['./all-doctor-view.component.css']
})
export class AllDoctorViewComponent implements OnInit {

  Token: string;
  date: Date;
  listOfAppointments: object;
  CurrentAppointmentData: any
  CurretTime: any;
  TodayDate: any;
  isOnloadAPi: boolean = true;
  constructor(private appointmentsService: GetAppointmentsService,
    private toastr: ToastrService,
    private router: Router) {
    $(".waitingRoomNav").removeClass("active");
    $("#allDoctorsView").addClass("active");
  }

  ngOnInit(): void {
    this.callAppointmentsAPI();
    let localThis = this;
    // setInterval(function () {
    //   localThis.isOnloadAPi = false;
    //   localThis.callAppointmentsAPI();
    // }, 30000);
  }
  callAppointmentsAPI() {
    this.appointmentsService.getAppointmentsByEmailForDoctorsView().subscribe((res) => {
      let app_List = res;
      var others = [];
      var Completed = [];
      var Canceled = [];
      var Running = [];
      var Waiting = [];
      var OthersAppointments = [];
      if (res != null) {
        $.each(app_List, function (key1, appointmentData) {
          var obj = {
            Id: appointmentData.id,
            title: appointmentData.doctor_name,
            start: new Date(appointmentData.startTime),
            end: new Date(appointmentData.endTime),
            bcrm_doctorstatus: appointmentData.bcrm_doctorstatus,
            bcrm_doctorstatusformatted: appointmentData.bcrm_doctorstatusformatted,
            serviceName: appointmentData.serviceName,
            teams_URL: appointmentData.teams_URL,
            contact_name: appointmentData.contact_name,
            subject: appointmentData.subject,
            bcrm_ispatientready: appointmentData.bcrm_ispatientready,
            isMyAppointment: true
          };
          if (obj.contact_name == environment.LoginUserfullName) {
            if (obj.bcrm_doctorstatusformatted == "Pending") {
              Waiting.push(obj);
            }
            else if (obj.bcrm_doctorstatusformatted == "Finished") {
              Completed.push(obj);
            }
            else if (obj.bcrm_doctorstatusformatted == "Ready") {
              Running.push(obj);
            }
            else if (obj.bcrm_doctorstatusformatted == "cancel") {
              Canceled.push(obj);
            }
            else {
              others.push(obj);
            }
          }
          else {
            /**Show the Ohters Appointmes Also */
            // obj.isMyAppointment = false;
            // OthersAppointments.push(obj)
          }
        });
      }
      this.showCalenderView(OthersAppointments, Completed, Waiting, Running, Canceled, others);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }
  showCalenderView(OthersAppointments, CompletedList, WaitingList, RuningList, CanceledList, othersList) {

    // sample calendar events data
    var NyEvents = {
      id: 0,
      backgroundColor: 'rgba(73,80,87, .25)',
      borderColor: '#212529',
      events: OthersAppointments
    };
    var CompletedEvents = {
      id: 1,
      backgroundColor: 'rgba(16,183,89, .25)',
      borderColor: '#10b759',
      events: CompletedList
    };
    var WaitingEvents = {
      id: 2,
      backgroundColor: 'rgba(253,126,20,.25)',
      borderColor: '#fd7e14',
      events: WaitingList
    };
    var RuningEvents = {
      id: 3,
      backgroundColor: 'rgba(0,204,204,.25)',
      borderColor: '#00cccc',
      events: RuningList
    };
    var CanceledEvents = {
      id: 4,
      backgroundColor: 'rgba(241,0,117,.25)',
      borderColor: '#f10075',
      events: CanceledList
    };
    var otherEvents = {
      id: 5,
      backgroundColor: 'rgba(91,71,251,.2)',
      borderColor: '#5b47fb',
      events: othersList
    };
    let evtentArrayList = [NyEvents, CompletedEvents, WaitingEvents, RuningEvents, CanceledEvents, otherEvents];


    this.bindFullCalendar(evtentArrayList);
  }

  bindFullCalendar(EventsArray): void {

    var localThis = this;
    // initialize the calendar
    $('#fullcalendar1').fullCalendar('destroy');
    $('#fullcalendar1').fullCalendar({
      header: {
        left: 'null',
        center: 'title',
        right: 'null'
      },
      nowIndicator: true,
      editable: true,
      droppable: true, // this allows things to be dropped onto the calendar
      dragRevertDuration: 0,
      defaultView: 'agendaDay',
      eventLimit: true, // allow "more" link when too many events
      eventSources: EventsArray,
      viewRender: (function () {
        return function (view) {
          var view = $('#fullcalendar1').fullCalendar('getView');
          if (view.name == "agendaDay") {
          }
        }
      })(),
      eventClick: function (event, jsEvent, view) {
        /************on the Click of Appointments****** */
        if (event.isMyAppointment) {
          localThis.CurrentAppointmentData = event;
        } else {
          localThis.toastr.info("you Can't see other Appointments", "")
        }

      },
      dayClick: function (date, jsEvent, view) {
        /************on the Click of day****** */
      },
      eventDragStop: function (event, jsEvent, ui, view) {
      }
    });


  }

  setPatientStatus(appointmentsData): void {
    //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    this.appointmentsService.updatePatientStatus(appointmentsData.Id, true).subscribe((res) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      window.location.reload();
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }
  openTeamsMeeting(meetingURL: string): void {
    window.open(meetingURL);
  }

}
