import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HeaderComponent } from '../../../../../src/app/Navigation/header/header.component';
import { SickNotesService } from '../../../../../src/app/ApiServices/Sick/sick-notes.service';
declare var $: any;

@Component({
  selector: 'app-sick-notes',
  templateUrl: './sick-notes.component.html',
  styleUrls: ['./sick-notes.component.css']
})
export class SickNotesComponent implements OnInit {

  SickData: object;
  SickDatanotmain: any;
  ProxyData: any;
  Token: string;
  isLoggedIn: boolean = false;
  UpdateSickNotes: any = {};
  allergyObj: any;
  CheckValuetrue: boolean = false;
  CheckValuetruerequest: boolean = false;
  GuardianValue: boolean = false;
  GuardianValueUpdate: boolean = false;
  userMainname: any;
  userMainId: any;
  bcrm_patientguardianvalue: any;
  Dataofthepatient: any;
  bcrm_patientguardianvalueAll: any;
  Alldatasharepoint: any;
  Urlmain: string;

  constructor(private sickNotesService: SickNotesService,
    private router: Router,
    private toastr: ToastrService,
    private headerComponent: HeaderComponent) {
    this.Token = "";//"";

    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.userMainname = environment.LoginUserfullName;
    this.userMainId = environment.LoginUserId;
    this.bcrm_patientguardianvalue = environment.LoginUserId;
    this.bcrm_patientguardianvalueAll = environment.LoginUserId;
    this.getSickNotesAll();
    this.getAllProxy();
  }

  getSickNotesAll(): void {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getSickNotes(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.SickDatanotmain = res;
        this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardianName == environment.LoginUserfullName || !x.bcrm_patientguardianName);
      }
      //   setTimeout(function () {
      //     $('#SickNotesRecord').DataTable();
      // }, 50);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting sick notes");
      // this.router.navigate(['/']);
    });
  }


  getAllProxy() {

    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
      }

      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting proxy");
      // this.router.navigate(['/']);
    });
  }


  editSickNotes(data) {
    debugger;
    this.UpdateSickNotes = data;
    if (this.UpdateSickNotes['bcrm_fromdate'] && this.UpdateSickNotes['bcrm_fromdate'].length > 0)
      this.UpdateSickNotes['bcrm_fromdate'] = this.UpdateSickNotes['bcrm_fromdate'].substring(0, 10);

  }

  onSubmit(it) {
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /************************************************** */
    this.allergyObj = it.value;
    this.allergyObj.bcrm_isproxy = this.GuardianValue;
    this.Dataofthepatient = it.value.bcrm_patientguardian;
    this.allergyObj.bcrm_status = '1';
    //this.allergyObj.bcrm_modifiedby = "Patient_" + environment.LoginUserEmail + "",
    this.sickNotesService.createSickNotes(this.allergyObj, environment.LoginUserId).subscribe((res) => {

      if (res == true) {
        this.toastr.success("Sick notes successfully created");
        //   $('#SickNotesRecord').DataTable().clear().destroy();
        this.SickData = null;
        this.bcrm_patientguardianvalueAll = this.Dataofthepatient;
        this.getSickNotesAllAftersubmit(this.Dataofthepatient);
        $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image11").attr("src", "../../../assets/images/Allergies.png");
        //   setTimeout(function () {
        //     $('#SickNotesRecord').DataTable();
        // }, 50);
        $('#Modal1').modal('hide');
        // this.ReloadComponent();   
        $('#Modal1 form')[0].reset();
      }
      else {
        this.toastr.error("Error while creating sick notes");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while creating sick notes");
      // this.router.navigate(['/']);
    });
  }

  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }

  showConfirm(allergyid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.value) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').addClass('show');
        /**************************************** */
        this.sickNotesService.deleteSickNotes(allergyid).subscribe((res) => {

          if (res == true) {
            this.toastr.info("Deleted Successfully");
            this.ReloadComponent();
          }
          $('#ftco-loader').removeClass('show');
          /**************************************** */
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error while Deleting sick notes");
          //this.router.navigate(['/']);
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        );
      }
    });

  }


  onUpdate(EditSickNotesdata) {
    debugger;
    var datatopost = EditSickNotesdata;
    datatopost.bcrm_isproxy = this.UpdateSickNotes.bcrm_isproxy;
    this.Dataofthepatient = datatopost.bcrm_patientguardian;

    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.sickNotesService.updateSickNotes(datatopost, environment.LoginUserId).subscribe((res) => {
      if (res == true) {
        this.toastr.success("Sick Notes Update Successfully");
        $('#ModalAll').modal('hide');
        //this.ReloadComponent();   
        this.bcrm_patientguardianvalueAll = this.Dataofthepatient;
        this.getSickNotesAllAftersubmit(this.Dataofthepatient);
      }
      else {
        this.toastr.error("Sick Notes not updated");
      }
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while updating sick notes");
      //this.router.navigate(['/']);
    });
  }


  Makethefeildempty(Event) {
    debugger
    this.CheckValuetrue = Event.currentTarget.checked;
    if (this.CheckValuetrue == false) {
      $("#bcrm_pleaseprovidedetailsofwhyyouarenotfittow").val("");
      $("#purpose").val("");
      $("#purposeedit").val("");
      $("#purpose").prop('disabled', true);
      $("#purposeedit").prop('disabled',true);
    } else{
      $("#purpose").prop('disabled', false);
      $("#purposeedit").val(this.UpdateSickNotes.bcrm_doyouthinkyouwouldbenefitfromanyofthefol);
      $("#purposeedit").prop('disabled',false);
    }
  }

  Makethefeildemptyrequest(Event) {
    debugger
    this.CheckValuetruerequest = Event.currentTarget.checked;
    if (this.CheckValuetruerequest == false) {
      $("#bcrm_medicalcondition").val(""); $("#purpose").prop('disabled', true);
    }
  }

  ChecktheGuardianValue(Event) {
    debugger
    var DataofEvent = Event.currentTarget.value;
    if (DataofEvent != environment.LoginUserId) {
      this.GuardianValue = true
    }
    else {
      this.GuardianValue = false;
    }
  }

  ChecktheGuardianValueUpdate(Event) {
    debugger
    var Updateguardianvaliue = Event.currentTarget.value;
    if (Updateguardianvaliue != environment.LoginUserId) {
      this.UpdateSickNotes.bcrm_isproxy = true
    }
    else {
      this.UpdateSickNotes.bcrm_isproxy = false;
    }
  }

  ChecktheValueofProxyandFilterdata(Event) {
    debugger
    this.SickData = null;
    //   $('#SickNotesRecord').DataTable().clear().destroy();
    var Dataofproxy = Event.currentTarget.value
    this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardian == Dataofproxy);
    //   setTimeout(function () {
    //     $('#SickNotesRecord').DataTable();
    // }, 50);
  }



  Getdownloadssahrepointdocument(fullURL) {

    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.Alldatasharepoint = null;

    var URL = fullURL.split("bcrm_sicknote");
 
    this.sickNotesService.Getsharepointpdf(URL[1]).subscribe((results) => {

      var linkElement = document.createElement('a');
      try {
        var __blob = new Blob([results], { type: "application/octet-stream" });
        var url = window.URL.createObjectURL(__blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", "Procedure.pdf");

        var clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);
      } catch (ex) {
        //console.log(ex);
      }


      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while getting file");
      // this.router.navigate(['/']);
    });
  }


  getSickNotesAllAftersubmit(value): void {
    // $('#SickNotesRecord').DataTable().clear().destroy();
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.SickData = null;
    this.sickNotesService.getSickNotes(environment.LoginUserId).subscribe((res) => {

      if (res != null) {
        this.SickDatanotmain = res;
        //  this.SickData =  this.SickDatanotmain;
        debugger
        this.SickData = this.SickDatanotmain.filter(x => x.bcrm_patientguardian == value);
      }
      //   setTimeout(function () {
      //     $('#SickNotesRecord').DataTable();
      // }, 50);
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error while geeting sick notes");
      // this.router.navigate(['/']);
    });
  }


}
