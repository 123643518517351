import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ValidateLoginService } from 'src/app/ApiServices/Login/validate-login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
/*******-************Variable *********************/
B2CToken:string;
  constructor(public validateLoginService: ValidateLoginService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    //Get Token From Url
    
    this.B2CToken = window.location.href.split("=")[1];   
    if (this.B2CToken != "" && this.B2CToken != undefined) {
      this.validateLoginService.postRegistrationDataIntoCRM({ username: "", password: "", otp: "", b2ctoken: this.B2CToken }).subscribe((res) => {
        
        localStorage.setItem("AuthToken", res['access_token']);
        localStorage.setItem("UserName", res['fullName']);
        /**Set The Access Token in the Local Storage */
        var base64Url = res['access_token'].split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');     
        environment.LoginUserEmail = JSON.parse(window.atob(base64)).unique_name;
        environment.LoginUserfullName=res['fullName'];  
      });
    }


  }

}
