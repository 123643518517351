import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Navigation/header/header.component';
import { FooterComponent } from './Navigation/footer/footer.component';
import { HomeComponent } from './Modules/home/home.component';
import { DoctorsComponent } from './Modules/doctors/doctors.component';
import { WaitingRoomComponent } from './Modules/waiting-room/waiting-room.component';
import { BookAppointmentsComponent } from './Modules/book-appointments/book-appointments.component';
import { NHSComponent } from './Modules/nhs/nhs.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LiveWellComponent } from './Modules/live-well/live-well.component';
import { ConditionsComponent } from './Modules/conditions/conditions.component';
import { MedicinesComponent } from './Modules/medicines/medicines.component';
import { VideoComponent } from './Modules/video/video.component';
import { RegistrationComponent } from './Navigation/registration/registration.component';
import { DeparmentsComponent } from './Modules/deparments/deparments.component';
import { CoronaComponent } from './Modules/corona/corona.component';
import { ProfileComponent } from './Modules/profile/profile.component';
import { ContactUsComponent } from './Modules/contact-us/contact-us.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AllDoctorViewComponent } from './Modules/waiting-room/all-doctor-view/all-doctor-view.component';
import { PatientOnlyViewComponent } from './Modules/waiting-room/patient-only-view/patient-only-view.component';
import { KBArticleComponent } from './Modules/kbarticle/kbarticle.component';
import { KBArticalDetailsComponent } from './Modules/kbartical-details/kbartical-details.component';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestAppointmentComponent } from './Modules/request-appointment/request-appointment.component';
import { WaitingListViewComponent } from './Modules/waiting-room/waiting-list-view/waiting-list-view.component';
import { SharePointComponent } from './Modules/share-point/share-point.component';
import { Configuration } from 'msal';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ArchwizardModule } from 'angular-archwizard';
import {
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration,
  BroadcastService
} from '@azure/msal-angular';
import { msalConfig, msalAngularConfig } from './app-config';
import { TermsComponent } from './Modules/terms/terms.component';
import { CookiesComponent } from './Modules/cookies/cookies.component';
import { AllergiesComponent } from './Modules/profile/allergies/allergies.component';
import { ProfileDetailComponent } from './Modules/profile/profile-detail/profile-detail.component';
import { NewconditionsComponent } from './Modules/profile/newconditions/newconditions.component';
import { NewinvoicesComponent } from './Modules/profile/newinvoices/newinvoices.component';
import { NewTdlComponent } from './Modules/profile/new-tdl/new-tdl.component';
import { NewobservationsComponent } from './Modules/profile/newobservations/newobservations.component';
import { NewprescriptionsComponent } from './Modules/profile/newprescriptions/newprescriptions.component';
import { NewDigitalimageryComponent } from './Modules/profile/new-digitalimagery/new-digitalimagery.component';
import { NewdocumentsComponent } from './Modules/profile/newdocuments/newdocuments.component';
import { HowitworksComponent } from './Modules/howitworks/howitworks.component';
import { CookiedetailsComponent } from './Modules/cookiedetails/cookiedetails.component';
import { RequestprescriptionComponent } from './Modules/profile/requestprescription/requestprescription.component';
import { ConsentComponent } from './Modules/profile/consent/consent.component';
import { ProcedureComponent } from './Modules/profile/procedure/procedure.component';
import { JoinusComponent } from './Modules/joinus/joinus.component';
import { ContactdetailComponent } from './Modules/contact-us/contactdetail/contactdetail.component';
import { PrivacyComponent } from './Modules/privacy/privacy.component';
import { ObservationDetailComponent } from './Modules/profile/observation-detail/observation-detail.component';
import { CaseComponent } from './Modules/profile/case/case.component';
import { NotesComponent } from './Modules/profile/notes/notes.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SickNotesComponent } from './Modules/profile/sick-notes/sick-notes.component';
import { ChatHistoryComponent } from './Modules/profile/chat-history/chat-history.component';
import { TokenInterceptorService } from 'src/token-interceptor.service';
import { NavigateComponent } from './Navigation/navigate/navigate.component';
import { AllergyComponent } from './Module/Profile/allergy/allergy.component';
import { InvoicesComponent } from './Module/Profile/invoices/invoices.component';
import { TestResultsComponent } from './Module/Profile/test-results/test-results.component';
import { ObservationsComponent } from './Module/Profile/observations/observations.component';
import { PrescriptionsComponent } from './Module/Profile/prescriptions/prescriptions.component';
import { DigitalImageryComponent } from './Module/Profile/digital-imagery/digital-imagery.component';
import { DocumentsComponent } from './Module/Profile/documents/documents.component';
import { ConsentsComponent } from './Module/Profile/consents/consents.component';
import { OnlineConsultationsComponent } from './Module/Profile/online-consultations/online-consultations.component';
import { HistoryChatComponent } from './Module/Profile/history-chat/history-chat.component';
import { ConditionNewComponent } from './Module/Profile/condition-new/condition-new.component';
import { SickNoteNewComponent } from './Module/Profile/sick-note-new/sick-note-new.component';
import { RequestPrescriptionNewComponent } from './Module/Profile/request-prescription-new/request-prescription-new.component';
import { ClinicianDeptComponent } from './Module/clinician-dept/clinician-dept.component';
import { BookappointmentnewComponent } from './Module/bookappointmentnew/bookappointmentnew.component';
import { RequestappointmentnewComponent } from './Module/requestappointmentnew/requestappointmentnew.component';
import { HowitworknewComponent } from './Module/howitworknew/howitworknew.component';
import { WaitingRoomNewComponent } from './Module/waiting-room-new/waiting-room-new.component';
import { WaitingListNewComponent } from './Module/waiting-room-new/waiting-list-new/waiting-list-new.component';
import { PatientOnlyViewNewComponent } from './Module/waiting-room-new/patient-only-view-new/patient-only-view-new.component';
import { AllDoctorViewNewComponent } from './Module/waiting-room-new/all-doctor-view-new/all-doctor-view-new.component';
import { ConsentsProcedureComponent } from './Module/Profile/consents-procedure/consents-procedure.component';
import { NoteNewComponent } from './Module/Profile/note-new/note-new.component';
import { NewHomeComponent } from './Module/new-home/new-home.component';
import { TepmHomeComponent } from './Module/tepm-home/tepm-home.component';
import { ShowProfileDataComponent } from './Module/Profile/show-profile-data/show-profile-data.component';

export const CreateTraslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.txt');
}


function MSALConfigFactory(): Configuration {
  return msalConfig;
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigateComponent,
    FooterComponent,
    HomeComponent,
    DoctorsComponent,
    WaitingRoomComponent,
    BookAppointmentsComponent,
    NHSComponent,
    LiveWellComponent,
    ConditionsComponent,
    MedicinesComponent,
    VideoComponent,
    RegistrationComponent,
    DeparmentsComponent,
    CoronaComponent,
    ProfileComponent,
    ContactUsComponent,
    AllDoctorViewComponent,
    PatientOnlyViewComponent,
    KBArticleComponent,
    KBArticalDetailsComponent,
    RequestAppointmentComponent,
    WaitingListViewComponent,
    SharePointComponent,
    TermsComponent,
    CookiesComponent,
    AllergiesComponent,
    ProfileDetailComponent,
    NewconditionsComponent,
    NewinvoicesComponent,
    NewTdlComponent,
    NewobservationsComponent,
    NewprescriptionsComponent,
    NewDigitalimageryComponent,
    NewdocumentsComponent,
    HowitworksComponent,
    CookiedetailsComponent,
    RequestprescriptionComponent,
    ConsentComponent,
    ProcedureComponent,
    JoinusComponent,
    ContactdetailComponent,
    PrivacyComponent,
    ObservationDetailComponent,
    CaseComponent,
    NotesComponent,
    SickNotesComponent,
    ChatHistoryComponent,
    AllergyComponent,
    InvoicesComponent,
    TestResultsComponent,
    ObservationsComponent,
    PrescriptionsComponent,
    DigitalImageryComponent,
    DocumentsComponent,
    ConsentsComponent,
    OnlineConsultationsComponent,
    HistoryChatComponent,
    ConditionNewComponent,
    SickNoteNewComponent,
    RequestPrescriptionNewComponent,
    ClinicianDeptComponent,
    BookappointmentnewComponent,
    RequestappointmentnewComponent,
    HowitworknewComponent,
    WaitingRoomNewComponent,
    WaitingListNewComponent,
    PatientOnlyViewNewComponent,
    AllDoctorViewNewComponent,
    ConsentsProcedureComponent,
    NoteNewComponent,
    NewHomeComponent,
    TepmHomeComponent,
    ShowProfileDataComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    Ng2SearchPipeModule,
    NgbModule,
    Ng2TelInputModule,
    ArchwizardModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCLtnMdAFvx7AuBphUi_hTE3_w8XlbZjAw&amp;libraries=places&amp;callback=initAutocomplete',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CreateTraslateLoader,
        deps: [HttpClient]
      }
    })
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      //useClass: TokenInterceptorService,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    BroadcastService,
    HeaderComponent,
    NavigateComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }