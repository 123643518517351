import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { SharepointService } from 'src/app/ApiServices/SharePoint/sharepoint.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;

declare var validImageTypes: ['image/gif',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'video/mp4',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'application/msword'];

@Component({
  selector: 'app-newdocuments',
  templateUrl: './newdocuments.component.html',
  styleUrls: ['./newdocuments.component.css']
})
export class NewdocumentsComponent implements OnInit {

  path: any; parentUrl: string; CurrentDirectory: string; FolderName: string; oldObj: []; insialData: []; uploadFile: true; dataTable: true; NewFolder: any; ResotreFolder: any; tableData: [];
  folderData: any; folderIsExist: boolean; searchText: string;

  constructor(private router: Router,
    public sharepointService: SharepointService,
    private toastr: ToastrService,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initializationfunction();
    
  }
  //Reload All data and gettting all information from server*/
  initializationfunction() {
    debugger;
    this.tableData = [];
    if (environment.LoginUserId != null && environment.LoginUserId != "") {
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /****************** -------- **********************/
      this.sharepointService.getFile(environment.LoginUserId).subscribe((results) => {

        if (results != null) {
          if (results['children'][0] != null) {
            //$("#iframeReload").hide();
            this.folderIsExist = true;
            this.folderData = results;
            console.log(results);
            this.ResotreFolder = results;

              this.FolderName = this.folderData.children[0].label;
              this.CurrentDirectory = this.folderData.children[0].RelativeUrl;
              var p = this.CurrentDirectory.split('/');
              this.path = [];
              if (p[0] == "https:") {
                for (var i = 3; i < p.length; i++) {
                  if (p[i] == "contact") { }
                  else { this.path.push(p[i]); }
                }
              }
              else {
                for (var i = 2; i < p.length; i++) {
                  this.path.push(p[i]);
                }
              }
              this.parentUrl = this.folderData.children[0].ParentUrl.split(this.FolderName)[0];
              this.tableData = results['children'][0];
            }

        }
        $("#your-image8").attr("src", "../../../assets/images/Documents.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");

        /**************************************** */
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });

    }
    else {

    }
  }
  DownloadIntoLocal(ListOfFile: any): void {
    debugger;
    if (ListOfFile.type == "folder") {
      this.FolderName = ListOfFile.label;
      this.CurrentDirectory = ListOfFile.RelativeUrl;
      this.parentUrl = ListOfFile.ParentUrl;
      var p = ListOfFile.RelativeUrl.split('/');
      this.path = [];
      if (p[0] == "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p[i] == "contact") { }
          else { this.path.push(p[i]); }
        }
      }
      else {
        for (var i = 2; i < p.length; i++) {
          this.path.push(p[i]);
        }
      }
      this.parentUrl = ListOfFile.ParentUrl.toLowerCase().split('/' + this.FolderName)[0];
      this.ReloadData(ListOfFile);
    }
    else if (ListOfFile.RelativeUrl != null && ListOfFile.RelativeUrl != "" && ListOfFile.RelativeUrl != undefined) {
      debugger;
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /****************** -------- **********************/
      this.sharepointService.downloadFile(encodeURIComponent(ListOfFile.RelativeUrl)).subscribe((results) => {

        var linkElement = document.createElement('a');
        try {
          var __blob = new Blob([results], { type: "application/octet-stream" });
          var url = window.URL.createObjectURL(__blob);
          linkElement.setAttribute('href', url);
          linkElement.setAttribute("download", ListOfFile.label);
          var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
          });
          linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
          console.log(ex);
        }
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        //this.router.navigate(['/']);
      });
    }
    else {
      this.toastr.info("Does't have retrival path for this file ")
    }
  }
  ReloadData(obj): void {
    debugger;
    var localthis = this;
    this.oldObj = this.tableData;
    this.tableData = [];
    if (obj.type == "folder") {
      // setTimeout(function () {

      // }, 1000);
      console.log("INNER");
      this.tableData = Object.assign({}, obj);
      console.log(this.tableData);
      localthis.changeDetection.detectChanges();
    }
  }
  back(fname): void {
    var l = this.tableData;
    if (this.ResotreFolder.label == "fname") {
      this.tableData = this.ResotreFolder;
      this.CurrentDirectory = this.ResotreFolder.RelativeUrl;
      var p = this.ResotreFolder.RelativeUrl.split('/');
      this.path = [];
      if (p[0] == "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p[i] == "contact") { }
          else { this.path.push(p[i]); }
        }
      }
      else {
        for (var i = 2; i < p.length; i++) {
          this.path.push(p[i]);
        }
      }
    }
    else {
      this.getfolder(fname, this.ResotreFolder.children);
    }
  }
  getfolder(fname, root): void {
    debugger;
    for (var j = 0; j < root.length; j++) {

      if (root[j].label == fname) {
        this.parentUrl = root[j].parentUrl;
        this.FolderName = fname;
        var m = root[j];
        this.CurrentDirectory = root[j].RelativeUrl;
        var p = root[j].RelativeUrl.split('/');
        this.path = [];
        if (p[0] == "https:") {
          for (var i = 3; i < p.length; i++) {
            if (p[i] == "contact") { }
            else { this.path.push(p[i]); }
          }
        }
        else {
          for (var i = 2; i < p.length; i++) {
            this.path.push(p[i]);
          }
        }
        this.assign(root[j]);
      }
      else {
        if (root[j].children != null) {

          this.getfolder(fname, root[j].children);
        }
      }
    }
  }
  assign(data): void {
    this.tableData = data;
    this.changeDetection.detectChanges();
  }
  /*Add Folder IN Sharepoint Location*/
  AddFolder() {
    $("#W2P1").modal("toggle");
    // BootstrapDialog.alert($("#W2P1").html());
  }
  onUploadFile(changeEvent): void {
    debugger
    let currentFolderFileList = this.tableData['children'];
    var file = changeEvent.target.files[0];
    let isFilePresentInDirectory;
    if (currentFolderFileList) {
      isFilePresentInDirectory = currentFolderFileList.find(x => x['label'] == file.name);
    }
    if (isFilePresentInDirectory != undefined) {
      $('input[type="file"]').val('');
      this.toastr.info("File Already Exist in the Directory please Change the File Name or Upload Another file", "");
    } else {
      var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB  

      const validImageTypes = [
        'image/gif',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'video/mp4',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-excel',
        'application/msword'
      ];

      if (!validImageTypes.includes(file['type'])) {
        this.toastr.info("This File Type Not Allowed to Upload in Sharepoint ", "")
        $('input[type="file"]').val('');

      } else {
        if (filesize <= 1) {
          //*************Show / hide loader *******************/
          $('#ftco-loader').addClass('show');
          /****************** -------- **********************/
          var payload = new FormData();
          payload.append("file", file);
          this.sharepointService.UploadFile(environment.LoginUserId, this.parentUrl, this.CurrentDirectory, this.FolderName, null, payload).subscribe((results) => {
            this.toastr.success("File upload successfully ");
            $('input[type="file"]').val('');
            this.initializationfunction();           
          }, (err) => {
            //*************Show / hide loader *******************/
            $('#ftco-loader').removeClass('show');
            /****************** -------- **********************/
            this.toastr.error(err, "Something went wrong");
            this.router.navigate(['/']);
          });
        }
        else {
          $('input[type="file"]').val('');
          this.toastr.info("The Maximum Size Limit of Document is 1 MB", "");
        }
      }
    }
  }
  onUploadFolder(it: NgForm) {
    debugger;
    $("#W2P1").modal("toggle");
    let Foldercheck = this.tableData['children']
let FolderExistAlredy = Foldercheck.find(x => x['label'].toLowerCase() == it.value.name.toLowerCase());

if (FolderExistAlredy != undefined) {
this.toastr.info("Folder Already Exist in the Directory Please Change the Folder Name", "");
$('#ftco-loader').removeClass('show');
return
}
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /****************** -------- **********************/
    var NewFolder = it.value;
    this.sharepointService.UploadFolder(environment.LoginUserId, this.parentUrl, this.CurrentDirectory, this.FolderName, NewFolder.name).subscribe((results) => {
      this.toastr.success("Folder created successfully ");
      this.initializationfunction();
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }
}
