import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DoctorsService {

  constructor(private http: HttpClient) { }

  // Get Doctors for this api //
  getDoctorsdata() {
    return this.http.get(`${environment.apiUrl}/api/Doctor/Get`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  getDoctorDetailById(DoctorId) {
    return this.http.get(`${environment.apiUrl}/api/OXDHStaff/GetStaffById?Id=${DoctorId}`).pipe(
      // retry(1), 
      catchError(this.handleError),
    );
  }

  getDoctorDetailSharePoint(DoctorId) {
    return this.http.get(`${environment.apiUrl}/api/Doctor/GetSharePoint?userId=${DoctorId}`).pipe(
      catchError(this.handleError),
    );
  }

  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;
      errorMessageUi = error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessageUi = error.error.message
    }
    return throwError(errorMessageUi);
  }
}

