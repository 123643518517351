
<section class="m-3">
    <div class="container px-md-0 table-responsive">

        <div class="col-md-6" >
            <select class="form-control" style="border-radius:0px !important;" [(ngModel)]="bcrm_patientguardianvalueAll" (change)="ChecktheValueofProxyandFilterdata($event)">
                <option value="" disabled>Select Guardian / Proxy</option>
                <option value={{userMainId}}>{{userMainname}} (Me)</option>
                <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                </option>
              </select>
        </div><br>

        <table class="table table-bordered " id="SickNotesRecord">
            <thead class="thead-light">
                <tr class="trColor">
                    <th class="thFont" colspan="7">
                        <div class="pull-right"><input type="button" href="#Modal1" data-toggle="modal" class="btn btn-primary" value="Request Sick Note"></div>
                    </th>
                </tr>
                <tr>
                    <th style="display: none;">S/No</th>
                    <th>Patient</th>
                    <th>First day you felt unwell</th>
                    <th>Symptoms you are experiencing</th>
                    <th>Reason for sick Leave</th>              
                    <th>Status</th>
                    <th>{{'Common.createdOn'|translate}}</th>
                    <th>Delete / Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of SickData; index as i">
                    <td style="display: none;">{{i + 1}}</td>
                    <td>{{data.bcrm_patientguardianName}}</td>
                    <td>{{data.bcrm_fromdate | date:'dd/MM/yyyy'}}</td>
                    <td>{{data.bcrm_whatarethesymptomsyouareexperiencing}}</td>                   
                    <td>{{data.bcrm_reasonforsickleave}}</td>               
                    <td>
                        <span *ngIf="data.bcrm_status == '1'"> Requested</span>
                        <span *ngIf="data.bcrm_status == '2'"> Approved</span>
                        <span *ngIf="data.bcrm_status == '3'"> Cancellation</span>
                    </td> 
                    <td>{{data.createdOn | date:'dd/MM/yyyy'}} </td>
                    <th>
                   <span *ngIf="data.bcrm_status == '1' || data.bcrm_status == '3'"><i  class="fa fa-trash" (click)="showConfirm(data.Id)"  aria-hidden="true"></i></span>
                   &nbsp;&nbsp;&nbsp;
                  <span *ngIf="data.bcrm_status == '1' || data.bcrm_status == '3'" ><a href="#ModalAll" (click)="editSickNotes(data)"data-toggle="modal" class="fa fa-pencil" aria-hidden="true"></a></span>
                  &nbsp;&nbsp;&nbsp;
                  <span *ngIf="data.bcrm_status == '2' && data.fullURL != ''" (click)="Getdownloadssahrepointdocument(data.fullURL)"><a style="color:#009FE3 ;font-size:14px"></a><i class="fa fa-download" aria-hidden="true">
                    
                  </i></span>

            </th>
                </tr>
            </tbody>
        </table>


        <div id="Modal1" class="modal fade">
            <!-- class modal and fade -->
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                     <h5>Request Sick Note</h5>
                        <!-- modal header -->
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
                    </div>
                    <div class="modal-body  result-shade1">
                        <!-- modal body -->
                        <form class="form" #it="ngForm" (ngSubmit)="onSubmit(it)"  #f="ngForm" autocomplete="off">


                            <div class="form-group row">
                                <div class="col-md-6">
                                    <!-- <label class="control-label"> </label>   -->
                                    <select  name="bcrm_patientguardian" [(ngModel)]="bcrm_patientguardianvalue" id="bcrm_patientguardian"
                                     ngModel #bcrm_patientguardian="ngModel" class="form-control" (change)="ChecktheGuardianValue($event)">
                                        <option value="" disabled>Select Guardian / Proxy</option>
                                        <option value={{userMainId}}>{{userMainname}} (Me)</option>
                                        <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                                        </option>
                                      </select>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row bmd-form-group"  *ngIf="GuardianValue == true">
                                        <!-- <div class="col-sm-1">
                                            <input type="checkbox" id="GuardianValue"  name="bcrm_isproxy" [(ngModel)]="GuardianValue">
                                        </div> -->
                                        <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                                    </div>
                                </div>
                              </div>

                            <div class="form-group row">
                              
                                <div class="col-md-6">
                                    <label class="control-label required">When was the first day you felt unwell?</label><br><br>
                                        <input type="date" class="form-control" name="bcrm_fromdate" ngModel  #bcrm_fromdate="ngModel" id="bcrm_fromdate" required>                               
                               </div>
                               <div class="col-md-6">
                                <label class="control-label required">What are the symptoms you are experiencing?</label>                                                   
                                    <textarea class="form-control" name="bcrm_whatarethesymptomsyouareexperiencing" ngModel  #bcrm_whatarethesymptomsyouareexperiencing="ngModel" id="bcrm_whatarethesymptomsyouareexperiencing" rows="4" cols="50" required>
                                    </textarea>                          
                                </div>

                            </div>

                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label class="control-label">Is the request related to an ongoing medical condition?</label>                          
                                         
                                         <label class="switch"><input type="checkbox" id="togBtn" name="bcrm_ismedicalcondition"
                                           #bcrm_ismedicalcondition="ngModel" ngModel (change)="Makethefeildemptyrequest($event)">
                                         <div class="slider round">
                                           <span class="on">{{'Common.Yes'|translate}}</span> <span class="off">{{'Common.No'|translate}}</span>
                                         
                                         </div>
                                       </label>                                 
                               </div>

                               <div class="col-md-6" *ngIf="CheckValuetruerequest == true">
                                <label class="control-label">If yes please specify</label>
                                    <input type="text" class="form-control" name="bcrm_medicalcondition" ngModel  #bcrm_medicalcondition="ngModel" id="bcrm_medicalcondition">                               
                           </div>
                        </div>


                        <div class="form-group row">
                           <div class="col-md-6">
                            <label class="control-label">Are you fit to work?</label>                           
                            <div>
                                 <label class="switch"><input type="checkbox" id="togBtn" name="bcrm_areyoufittowork"
                                   #bcrm_areyoufittowork="ngModel" ngModel  (change)="Makethefeildempty($event)">
                                 <div class="slider round">
                                   <span class="on">{{'Common.Yes'|translate}}</span> <span class="off">{{'Common.No'|translate}}</span>
                                   
                                 </div>
                               </label>
                           </div>   
                                 
                       </div>


                       <!-- <div class="col-md-6" *ngIf="CheckValuetrue == false">
                        <label class="control-label">If no please provide details of why you are not fit to work </label>
                            <input type="text" class="form-control" name="bcrm_pleaseprovidedetailsofwhyyouarenotfittow" ngModel  #bcrm_pleaseprovidedetailsofwhyyouarenotfittow="ngModel" id="bcrm_pleaseprovidedetailsofwhyyouarenotfittow">                               
                   </div> -->
                </div>

                <div class="form-group row">
                   <div class="col-md-6">
                    <label class="control-label">If your employer agrees do you think you would benefit from any of the following? </label>                           
                        <select class="form-control" id='purpose' name="bcrm_doyouthinkyouwouldbenefitfromanyofthefol"  ngModel #bcrm_doyouthinkyouwouldbenefitfromanyofthefol="ngModel">
                           <option value="">Select</option>
                           <option value="1">Phased return to work</option>
                           <option value="2">Amended duties</option>
                           <option value="3">Altered hours</option>
                           <option value="4">Workplace adaptation</option>
                           <option value="5">None</option>
                         </select>                            
               </div>
               <!-- <div class="col-md-6">
                <label class="control-label required">Please provide comments and time lines </label>                           
                    <textarea class="form-control" name="bcrm_patientcomments" ngModel  #bcrm_patientcomments="ngModel" id="bcrm_patientcomments" rows="4" cols="50" required>
                   </textarea>
           </div>
                                -->



                                <div class="col-md-6">
                                    <label class="control-label required">Reason For Sick Leave</label>
                                    <textarea class="form-control" name="bcrm_reasonforsickleave" ngModel  #bcrm_reasonforsickleave="ngModel" id="bcrm_reasonforsickleave" rows="4" cols="50" required>
                                   </textarea>
                               </div>



                            </div>

                     <div class="form-group row">



                    </div>

                            <input type="submit" class="btn btn-primary pull-right"  [disabled]="!it.valid" value="Submit">
                        </form>
                    </div>
                </div>
                <!-- / .modal-content -->

            </div>
            <!-- / .modal-dialog -->

        </div>


        <div id="ModalAll" class="modal fade">
            <!-- class modal and fade -->
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- modal header -->
                        <h5>Edit Sick Notes</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
                    </div>
                    <div class="modal-body  result-shade1">
                        <!-- modal body -->

                        <form class="form" #EditSickNotesdata="ngForm"  (ngSubmit)="onUpdate(UpdateSickNotes)" #f="ngForm" autocomplete="off">


                            <div class="form-group row">
                            
                                <!-- <div class="col-md-2"></div> -->
                                <div class="col-md-6">
                                    <!-- <label class="control-label"> </label>    -->
                                    <select  name="bcrm_patientguardian" id="bcrm_patientguardian" class="form-control"
                                    name="bcrm_patientguardian" [(ngModel)]="UpdateSickNotes.bcrm_patientguardian"  ngModel #bcrm_patientguardian="ngModel" 
                                    (change)="ChecktheGuardianValueUpdate($event)">
                                        <option value="">Select Guardian / Proxy</option>
                                        <option value={{userMainId}}>{{userMainname}} (Me)</option>
                                        <option [value]="data.relatedPersonId" *ngFor="let data of ProxyData" class="form-control">{{data.fullName}}
                                        </option>
                                      </select>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row bmd-form-group" *ngIf="UpdateSickNotes.bcrm_isproxy == true">
                                        <!-- <div class="col-sm-1">
                                            <input type="checkbox" id="bcrm_isproxy"  name="bcrm_isproxy" [(ngModel)]="UpdateSickNotes.bcrm_isproxy">
                                        </div> -->
                                        <label class="md-label-floating col-sm-6">Guardian / Proxy</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row">


                                <div class="col-md-6">
                                     <label class="control-label">When was the first day you felt unwell?</label><br><br>
                                         <input type="date" class="form-control" name="bcrm_fromdate" ngModel  [(ngModel)]="UpdateSickNotes.bcrm_fromdate" #bcrm_fromdate="ngModel" id="bcrm_fromdate">                               
                                </div>

                                  
                            <div class="col-md-6">
                                <label class="control-label">What are the symptoms you are experiencing?</label>
                                <textarea class="form-control" name="bcrm_whatarethesymptomsyouareexperiencing" [(ngModel)]="UpdateSickNotes.bcrm_whatarethesymptomsyouareexperiencing" ngModel  #bcrm_whatarethesymptomsyouareexperiencing="ngModel" id="bcrm_whatarethesymptomsyouareexperiencing" rows="4" cols="50">
                               </textarea>                          
                              </div>
                         </div>
     
                         <div class="form-group row">


                            <div class="col-md-6">
                                <label class="control-label">Is the request related to an ongoing medical condition?</label>                           
                                     
                                     <label class="switch"><input type="checkbox" id="togBtn" name="bcrm_ismedicalcondition"
                                       #bcrm_ismedicalcondition="ngModel" [(ngModel)]="UpdateSickNotes.bcrm_ismedicalcondition" ngModel>
                                     <div class="slider round">
                                       <span class="on">{{'Common.Yes'|translate}}</span> <span class="off">{{'Common.No'|translate}}</span>
                                       
                                     </div>
                                   </label>
                                     
                           </div>

                           <div class="col-md-6" *ngIf="UpdateSickNotes.bcrm_ismedicalcondition == true">
                            <label class="control-label">If yes please specify </label>
                                <input type="text" class="form-control" [(ngModel)]="UpdateSickNotes.bcrm_medicalcondition" name="bcrm_medicalcondition" ngModel  #bcrm_medicalcondition="ngModel" id="bcrm_medicalcondition">                               
                       </div>
                          </div>
     
                          <div class="form-group row">

                            <div class="col-md-6">
                                <label class="control-label">Are you fit to work?</label>                           
                                <div>
                                     <label class="switch"><input type="checkbox" id="togBtn" name="bcrm_areyoufittowork"
                                       #bcrm_areyoufittowork="ngModel" ngModel [(ngModel)]="UpdateSickNotes.bcrm_areyoufittowork" (change)="Makethefeildempty($event)"> 
                                     <div class="slider round">
                                       <span class="on">{{'Common.Yes'|translate}}</span> <span class="off">{{'Common.No'|translate}}</span>
                                       
                                     </div>
                                   </label>
                               </div>   
                                     
                           </div>
                        
                            <!-- <div class="col-md-6" *ngIf="UpdateSickNotes.bcrm_areyoufittowork == false">
                                <label class="control-label">If no please provide details of why you are not fit to work </label>
                                    <input type="text" class="form-control" [(ngModel)]="UpdateSickNotes.bcrm_pleaseprovidedetailsofwhyyouarenotfittow" name="bcrm_pleaseprovidedetailsofwhyyouarenotfittow" ngModel  #bcrm_pleaseprovidedetailsofwhyyouarenotfittow="ngModel" id="bcrm_pleaseprovidedetailsofwhyyouarenotfittow">                               
                           </div>
                             -->
     
                         
     
                          </div>
     
                          <div class="form-group row">
                          

                            <div class="col-md-6">
                                <label class="control-label">If your employer agrees do you think you would benefit from any of the following? </label>                           
                                    <select class="form-control" id='purposeedit' name="bcrm_doyouthinkyouwouldbenefitfromanyofthefol" [(ngModel)]="UpdateSickNotes.bcrm_doyouthinkyouwouldbenefitfromanyofthefol"  ngModel #bcrm_doyouthinkyouwouldbenefitfromanyofthefol="ngModel">
                                       <option value="">Select</option>
                                       <option value="1">Phased return to work</option>
                                       <option value="2">Amended duties</option>
                                       <option value="3">Altered hours</option>
                                       <option value="4">Workplace adaptation</option>
                                       <option value="5">None</option>
                                     </select>                            
                           </div>
     
                         <!-- <div class="col-md-6">
                              <label class="control-label">Please provide comments and time lines  </label>                         
                                  <textarea class="form-control" name="bcrm_patientcomments" [(ngModel)]="UpdateSickNotes.bcrm_patientcomments" ngModel  #bcrm_patientcomments="ngModel" id="bcrm_patientcomments" rows="4" cols="50">
                                </textarea> 
                         
                                </div>      -->


                                <div class="col-md-6">
                                    <label class="control-label">Reason For Sick Leave</label>                              
                                    <textarea class="form-control" name="bcrm_reasonforsickleave" [(ngModel)]="UpdateSickNotes.bcrm_reasonforsickleave" ngModel  #bcrm_reasonforsickleave="ngModel" id="bcrm_reasonforsickleave" rows="4" cols="50">
                                  </textarea>                              
                               </div>

                          </div>
<!-- 
                          <div class="col-md-6">
                            <label class="control-label">Reason For Sick Leave</label>                              
                            <textarea class="form-control" name="bcrm_reasonforsickleave" [(ngModel)]="UpdateSickNotes.bcrm_reasonforsickleave" ngModel  #bcrm_reasonforsickleave="ngModel" id="bcrm_reasonforsickleave" rows="4" cols="50">
                          </textarea>                              
                       </div> -->
     
                          <div class="form-group row">
                             <!-- <div class="col-md-6">
                                  <label class="control-label">Status</label>                           
                                  <select class="form-control" id='purpose' name="bcrm_status"  ngModel #bcrm_status="ngModel" [(ngModel)]="UpdateSickNotes.bcrm_status">
                                     <option value="">Select Status</option>
                                     <option value="1">Requested</option>
                                     <option value="2">Approved</option>
                                     <option value="3">Cancellation</option>
                                   </select>                          
                             </div> -->
     
                         <!-- <div class="col-md-6">
                              <label class="control-label">Reason For Sick Leave</label>                              
                              <textarea class="form-control" name="bcrm_reasonforsickleave" [(ngModel)]="UpdateSickNotes.bcrm_reasonforsickleave" ngModel  #bcrm_reasonforsickleave="ngModel" id="bcrm_reasonforsickleave" rows="4" cols="50">
                            </textarea>  
                              
                         </div>
      -->
                          </div>       
                          
                          <input type="submit" class="btn btn-primary pull-right" value="Update">


                      </form>

                      
                    </div>
                </div>
                <!-- / .modal-content -->

            </div>
            <!-- / .modal-dialog -->

        </div>

    </div>
</section>