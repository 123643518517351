<!-- <section style="margin: 3% 0% 1% 0%;">
    <div class="row no-gutters" style="justify-content: center;">

        <div class="col-lg-9 col-md-12 heading-section3">
            <h1 style="color: #fff;font-family: 'Yantramanav', sans-serif;margin: 0% 0% 0% 2%;">Conditions</h1>
        </div>
    </div>
</section> -->

<section class="m-3">
    <div class="container px-md-0 table-responsive">
        <!-- <div class="pull-left"><a  type="button" class="btn btn-primary"routerLink="../newprofile">Back</a>
      </div><br><br><br> -->
        <table class="table table-bordered" id="coditionRecord">
            <thead class="thead-light">
                <tr>
                    <th>{{'Common.name'|translate}}</th>
                    <th>{{'Condition.ConditionCode'|translate}}</th>
                    <th>{{'Condition.Practitioner'|translate}}</th>
                    <th>{{'Condition.clinicalStatus'|translate}}</th>
                    <th>{{'Condition.verificationStatus'|translate}}</th>
                    <th>{{'Common.createdOn'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of conditions">
                    <td>{{data.msemr_name}}</td>
                    <td>{{data.msemr_conditioncode}}</td>
                    <td>{{data.msemr_practitioner}}</td>
                    <td>{{data.msemr_clinicalstatus}}</td>
                    <td>{{data.msemr_verificationstatus}}</td>
                    <td>{{data.createdOn | date:'dd-MMM-yyyy'}}</td>
            </tbody>
        </table>
    </div>
</section>