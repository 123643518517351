<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="container">
                                <span><i class="fa fa-arrow-circle-o-left" style="font-size:36px"
                                        (click)="getObservation()"></i></span><br>
                                <div class="panel-group">

                                    <div class="panel panel-primary" style="border-color: #337ab7; border: 1px solid;">
                                        <div class="panel-heading" style="background-color: #337ab7;">
                                            <h4 style="text-align: center; color: white;"><u>HL7 Report</u></h4>

                                        </div>
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                                                <div class="col-md-5 col-lg-5 col-sm-12">
                                                    <label style="font-weight: 700;">Name:</label>
                                                    {{observationsResult[0]?.bcrm_patientName}}
                                                </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12">
                                                    <label style="font-weight: 700;">Report Produced by:</label>
                                                    {{ObservationDetail?.bcrm_consultingdoctor}}
                                                </div>
                                                <div class="col-md-1 col-lg-1 col-sm-12"></div>
                                            </div>


                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="dob">DOB | Age:</label>
                                                    {{age}}

                                                </div>
                                                <div class="col-md-5">
                                                    <label for="received">Received:</label>

                                                </div>
                                                <div class="col-md-1"></div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="gender">Gender:</label>
                                                    {{observationsResult[0]?.patient_gender}}

                                                </div>
                                                <div class="col-md-5">
                                                    <label for="hospital">Hospital no:</label>
                                                    000122332

                                                </div>
                                                <div class="col-md-1"></div>
                                            </div>


                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="labrefno">Lab Ref no.:</label>
                                                    OX112233

                                                </div>
                                                <div class="col-md-5">
                                                    <label for="reference">Reference:</label>

                                                </div>
                                                <div class="col-md-1"></div>

                                            </div>

                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="collected">Collected:</label>

                                                </div>
                                                <div class="col-md-5">
                                                    <label for="dreport">Report Date:</label>
                                                    {{ObservationDetail?.bcrm_datetimeoftransaction| date:'dd/MM/yyyy'}}
                                                </div>
                                                <div class="col-md-1"></div>

                                            </div>

                                            <hr width="90%">

                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="">Visit (PV1):</label>

                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>


                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5">
                                                    <label for="cdoctor">Consulting Doctor:</label>
                                                    {{ObservationDetail?.bcrm_consultingdoctor}}
                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>


                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-10">
                                                    <label for="ocr1">Common Order (ORC):</label> {{ocr1}}
                                                </div>
                                                <div class=""></div>
                                            </div>




                                            <div class="row" *ngFor="let data of ocr2; let i = index; ">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-10">
                                                    <label>Observation Request Segment (OBR[{{i+1}}]):</label><br>
                                                    {{data}}
                                                </div>
                                                <div class=""></div>
                                            </div>

                                            <hr width="90%">


                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-10">
                                                    <table class="table" id="tableid">
                                                        <thead>
                                                            <tr>
                                                                <th style="display: none;">S/No</th>
                                                                <th scope="col">Test Name</th>
                                                                <th scope="col">Result</th>
                                                                <th scope="col">Units</th>
                                                                <th scope="col">Reference Range</th>
                                                                <!-- <th scope="col">Notes</th> -->
                                                            </tr>
                                                        </thead>
                                                        <tr *ngFor="let data of observationsResult; index as i">
                                                            <td style="display: none;">{{i + 1}}</td>
                                                            <td>{{data?.bcrm_text}}</td>
                                                            <td>{{data?.bcrm_observationvalue}}</td>
                                                            <td>{{data?.bcrm_units}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-md-1"></div>

                                            </div>

                                            <hr width="90%">

                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-10">
                                                    <b style="text-align: center;"> Formatted by OXDH</b>
                                                </div>
                                                <div class="col-md-1"></div>
                                            </div>
                                            <!-- <h6>
                                                <p style="text-align: center;">Authorised By: Clinical Pathology, TDL
                                                </p>
                                            </h6> -->


                                        </div>
                                    </div>
                                </div>
                            </div> <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>