import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HeaderComponent } from 'src/app/Navigation/header/header.component';
import { BookAppointmentsServiesService } from 'src/app/ApiServices/Booking/book-appointments-servies.service';
import { RequestAppService } from 'src/app/ApiServices/Booking/request-app.service';
import { SickNotesService } from '../../../../src/app/ApiServices/Sick/sick-notes.service';
declare var $: any;

@Component({
  selector: 'app-book-appointments',
  templateUrl: './book-appointments.component.html',
  styleUrls: ['./book-appointments.component.css']
})
export class BookAppointmentsComponent implements OnInit {
  // call consent 
  //#region Global Variables
  Token: string; today: Date; allServices: any; selectedServiceDoctors: any; isServicSelected: boolean = false; IsTimeSlotArray: boolean = false; isLoggedIn: any;
  Dom_serviceName: string; Dom_serviceDurationInMins: Number; Dom_serviceId: string; Dom_staffId: string; Dom_StaffName: string; Dom_staffemail: string;
  Dom_price: string; startDate: string; startDateTime: string; endDate: string; Phone: string; Address: string; City: string; State: string; Country: string; Subject: string;
  Notes: string; userEmail: string = environment.LoginUserEmail; userMobile: string = environment.LoginUserMobile; userName: string = environment.LoginUserfullName; handler: any = null; TimeSlotArray: object;
  lastAppointmentComments: string; Questions: any; allDoctors: any; ServiceName: string; DoctorFullName: string; patient_Name: string; patient_Email: string; patient_MobilePhone: string;
  Comment: string; bcrm_anythingnew: string; RequestAppointmentsData: object; dialcode: string; inputObj: any; email: string;
  searchText: string; btnshow: boolean; preferredcontactmethod: string;  ProxyData:any;   GuardianValue: boolean = false; userMainname:any; userMainId:any; bcrm_patientguardianvalue:any;
 QNA:any;
  //#endregion

  //#region constructor and Include the servies
  constructor(private router: Router,
    public BookServies: BookAppointmentsServiesService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private headerComponent: HeaderComponent,
    public requestserive: RequestAppService,private sickNotesService: SickNotesService) {

    this.isLoggedIn = headerComponent.loggedIn;
    if (!this.isLoggedIn) {
      toastr.error("Have To login First", "To See The Doctors Details");
      this.router.navigate(['/'], { skipLocationChange: true });
    }
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-Appointment").addClass("active");
    /******End:Active Current Component Nav****** */
  }
  //#endregion

  //#region Ng-On Init
  ngOnInit(): void {
    debugger
    this.userMainname = environment.LoginUserfullName;
    this.userMainId = environment.LoginUserId;
    this.bcrm_patientguardianvalue = environment.LoginUserId;
    // this.loadStripe();
    var localThis = this;
    var date = new Date();
    localThis.today = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    this.getAllProxy();

    //#region Document ready Function
    $(document).ready(function () {
      $(".selectbtn").show();

    });

    $("#Services1").click(function () {
      $("#your-image1").attr("src", "../../../assets/images/doctor.png");
      $("#your-image").attr("src", "../../../assets/images/serviceactive.png");
    })

    $("#Doctors1").click(function () {
      $("#your-image1").attr("src", "../../../assets/images/doctoractive.png");
      $("#your-image2").attr("src", "../../../assets/images/question.png");
    })
    $("#Questions").click(function () {
      $("#your-image2").attr("src", "../../../assets/images/questionfinal.png");
      $("#your-image3").attr("src", "../../../assets/images/detailactive.png");
    })
    $("#Questions1").click(function () {
      $("#your-image2").attr("src", "../../../assets/images/questionactive.png");
      $("#your-image3").attr("src", "../../../assets/images/details.png");
    })
    $("#Details").click(function () {
      $("#your-image3").attr("src", "../../../assets/images/detailfinal.png");
      $("#your-image4").attr("src", "../../../assets/images/summaryactive.png");
    })
    $("#Details1").click(function () {
      $("#your-image3").attr("src", "../../../assets/images/detailactive.png");
      $("#your-image4").attr("src", "../../../assets/images/summary.png");
    })
    //#endregion

    //#region Step Wizard Metodes
    $(document).ready(function () {
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;

      $(".next1").click(function () {

        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        if (localThis.Dom_serviceId == null) {
          return false;
        }
        if (($('#your-image1').attr('src') == "../../../assets/images/doctoractive.png") && localThis.startDate == undefined) {
          return localThis.toastr.error("Please select a time slot for appointment");

        }
        if ($("#basic-information").hasClass("active") && !validateForm()) return false;
        //Add Class Active
        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            next_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });

      $(".previous").click(function () {

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            previous_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });
      $('.radio-group .radio').click(function () {

        $(this).parent().find('.radio').removeClass('selected');
        $(this).addClass('selected');
      });
      var currentTab = 0;
      function validateForm() {

        // This function deals with validation of the form fields
        var x, y, z, i, valid = true;
        x = document.getElementsByClassName("basic-information");
        y = x[currentTab].getElementsByTagName("input");
        z = x[currentTab].getElementsByTagName("textarea");
        // A loop that checks every input field in the current tab:
        for (i = 0; i < y.length; i++) {
          // If a field is empty...
          if (y[i].value == "") {
            // add an "invalid" class to the field:
            y[i].className += " invalid";
            // and set the current valid status to false
            valid = false;
          }
        }
        for (i = 0; i < z.length; i++) {
          // If a field is empty...
          if (z[i].value == "") {
            // add an "invalid" class to the field:
            z[i].className += " invalid";
            // and set the current valid status to false
            valid = false;
          }
        }
        return valid; // return the valid status
      }
      $(".submit").click(function () {

        return false;
      })

    });
    //#endregion

    //#region Check Email in Params
    this.email = this.activatedRoute.snapshot.paramMap.get('userName');
    $('#ftco-loader').addClass('show');
    //#endregion

    //#region Get the Serivice Details

    if (this.email != "" && this.email != null) {
      this.BookServies.getAllServicesByEmail(this.email).subscribe((res) => {
        debugger;
        if (res != null) {
          $("#Phone").val(this.userMobile)
          let ServicesDataList = res;
          if (ServicesDataList != null) {
            /**Formate The Time Hour and Min */
            // ServicesDataList.forEach(function (value) {

            //   var duration = value.defaultDuration;
            //   var defaultDuration = duration.match(/\d+/g).join(".");

            //   if (duration.match(/[a-zA-Z]+/g)[1] === "M") {
            //     defaultDuration = defaultDuration + " Mins";
            //   }
            //   else if (duration.match(/[a-zA-Z]+/g)[1] === "H") {
            //     if (defaultDuration.split(".").length > 1) {
            //       defaultDuration = (Number(defaultDuration.split(".")[0]) * 60) + (Number(defaultDuration.split(".")[1])) + " Mins"
            //     }
            //     else {
            //       defaultDuration = defaultDuration + " Hour";
            //     }

            //   }
            //   value.defaultDuration = defaultDuration;
            // });
            this.allServices = ServicesDataList;
          }

        }
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });
    }
    else {
      this.BookServies.getAllServices().subscribe((res) => {
        if (res != null) {

          $('#ftco-loader').addClass('show');
          $("#Phone").val(this.userMobile)
          let ServicesDataList = res;
          if (ServicesDataList != null) {
            // /**Formate The Time Hour and Min */
            // ServicesDataList.forEach(function (value) {

            //   var duration = value.defaultDuration;
            //   var defaultDuration = duration.match(/\d+/g).join(".");

            //   if (duration.match(/[a-zA-Z]+/g)[1] === "M") {
            //     defaultDuration = defaultDuration + " Mins";
            //   }
            //   else if (duration.match(/[a-zA-Z]+/g)[1] === "H") {
            //     if (defaultDuration.split(".").length > 1) {
            //       defaultDuration = (Number(defaultDuration.split(".")[0]) * 60) + (Number(defaultDuration.split(".")[1])) + " Mins"
            //     }
            //     else {
            //       defaultDuration = defaultDuration + " Hour";
            //     }

            //   }
            //   value.defaultDuration = defaultDuration;
            // });
            this.allServices = ServicesDataList;
          }

        }
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error : ");
        this.router.navigate(['/']);
      });
    }
    //#endregion

    //#region Load Date picker
    $('#datepicker').datepicker({
      autoclose: true,
      startDate: "+0d",
      // daysOfWeekDisabled: [6, 0],
      todayHighlight: true,
      minDate: new Date()
    });
    //#endregion

    //#region Set Todays Date
    // if Today is Sunday Then Set Moday's Date:pandint
    $('#datepicker').datepicker('setDate', localThis.today);
    //#endregion

    //#region Function On-Change Of Date
    $('#datepicker').on('changeDate', function () {
      if ($('#datepicker').datepicker('getFormattedDate') == "") {
        $('#datepicker').datepicker('setDate', localThis.today);
      } else {
        localThis.today = $('#datepicker').datepicker('getFormattedDate');
        localThis.TimeSlotArray = [];
        localThis.IsTimeSlotArray = false;
        localThis.startDate = null;
        if (localThis.Dom_serviceId != null && localThis.Dom_serviceId != "" && localThis.Dom_serviceId != undefined) {
          localThis.getDoctorsList(localThis.Dom_serviceId);
        }
      }
    });
    //#endregion
  }
  //#endregion 

  //#region function for set flag in phonecode
  telInputObject(obj, val) {
    this.inputObj = obj;
    if (val) {
      obj.setNumber(val);
    }
  }
  //#endregion

  //#region On change on Servies Seletion
  getDoctorsListByService(serviceData): void {
    debugger;
    /**********************************Data assign********************* */
    this.Dom_serviceName = serviceData.name;
    this.Dom_serviceId = serviceData.id;
    $(`.selectbtn`).show();
    $(`#${serviceData.id}_selectbtn`).hide();
    this.Dom_serviceDurationInMins = serviceData.defaultDuration;
    // if (spiltTime.length > 0) {

    //   this.Dom_serviceDurationInMins = spiltTime[1] == "Mins" ? Number(spiltTime[0]) : Number(spiltTime[0]) * 60;
    // }
    this.Dom_price = serviceData.defaultPrice
    /**------------------Alert And Add active class--------------- */
    // this.toastr.info("you have selected", serviceData.name);
    $(".card").removeClass("actives");
    $(`#${serviceData.id}_card`).addClass("actives");
    this.BookServies.CheckPatientConsentStatus(environment.LoginUserId).subscribe((res) => {
      debugger;
      if (res) {
        $("#Services").click(function () {
          $("#your-image").attr("src", "../../../assets/images/servicefinal.png");
          $("#your-image1").attr("src", "../../../assets/images/doctoractive.png");
        })
      }
      else {
        return this.toastr.warning("", "We do not have your signed consent. These are normally reviewed and approved daily. If you think your consents should have already been processed please email contact@oxvc.health");
      }
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.info(err, "Error : ");
      // this.router.navigate(['/']);
    });
    // $("#coloractive1").addClass("activesfont");
    // $("#coloractive2").addClass("activesfont");
    /** ------------Call Get Doctors Api As pr the Selected Servies*------------------ */
    this.getDoctorsList(serviceData.id);
    this.onServiceQuestions(serviceData.id);


  }
  //#endregion

  //#region Get All Doctor List 
  getDoctorsList(serviceId): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    /**---------- null the Object------------- */
    this.Dom_staffId = "";
    this.selectedServiceDoctors = [];
    this.IsTimeSlotArray = false;
    this.BookServies.getDoctorsByServicId(serviceId).subscribe((res) => {
      this.allDoctors=res;
      // var Doc_filter_list = []
      // var SelectedDateFilter = new Date($('#datepicker').datepicker('getFormattedDate'));
      // var selectedDay = SelectedDateFilter.getDay() == 0 ? 6 : SelectedDateFilter.getDay() - 1;
      // $.each(res, function (index, value) {

      //   if (value.WorkingHours[selectedDay].TimeSlots.length > 0) {
      //     Doc_filter_list.push(value);
      //   }
      // });
      this.selectedServiceDoctors = this.allDoctors;
      if (this.allDoctors.length > 0) {
        if (this.email != null) {
          var DoctorData = this.allDoctors.find(x => x['email'].toLowerCase() == this.email.toLowerCase());
          this.Dom_staffId = DoctorData.Id;
          this.onDoctorChange(this.Dom_staffId);
        }
      }
      else {
        this.toastr.info("No Doctor avaliable for this selected service")
      }
      this.isServicSelected = true;
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }
  //#endregion

  //#region On change on Doctore Seletion
  onDoctorChange(event): void {
    if (event != "") {
      debugger;
      //*************Show / hide loader *******************/
      $('#ftco-loader').addClass('show');
      /***************************************************/
      var SelectedDoctorData = this.selectedServiceDoctors.find(x => x['Id'] == event);
      /**
       * Set Data Glo
       */
      this.Dom_staffId = SelectedDoctorData.Id;
      this.Dom_StaffName = SelectedDoctorData.name;
      this.Dom_staffemail = SelectedDoctorData.email;
      /**
       * parsing Data
       */
      var SelectDate = new Date($('#datepicker').datepicker('getFormattedDate'));
      SelectDate = new Date(SelectDate.setHours(23, 59, 59))
      // var wokingDay = SelectDate.getDay() - 1 < 0 ? 6 : SelectDate.getDay() - 1;
      // var getWorkingHours = SelectedDoctorData.WorkingHours[wokingDay];
      /**
       * Creating Object To send 
       */

      if (SelectDate !== undefined) {
        /**
       * Get Working time of Docotor and combain the selected date with docotr Working Hour 
       */
        // let selectedDate = SelectDate.toISOString();
        // let startTime = selectedDate.split("T")[0] + "T" + getWorkingHours.TimeSlots[0].Start.split("T")[1];
        // let endTime = selectedDate.split("T")[0] + "T" + getWorkingHours.TimeSlots[0].End.split("T")[1];

        const timeSlotRequest = {
          staffEmail: this.Dom_staffemail,
          selectedDate: SelectDate.toISOString(),
          startTime: SelectDate.toISOString().split('23:59:59.000Z')[0] + '07:59:59.000Z',
          endTime: SelectDate.toISOString().split('23:59:59.000Z')[0] + '18:00:00.000Z',
          meetingTimeDuration: this.Dom_serviceDurationInMins
        }

        this.BookServies.getStaffAvailableTime(timeSlotRequest).subscribe((res) => {

          if (res) {
            this.TimeSlotArray = res;
            this.IsTimeSlotArray = true;

            //*************Show / hide loader *******************/
            $('#ftco-loader').removeClass('show');
            /*****************   *********************** */
          }
        }, (err) => {
          //*************Show / hide loader *******************/
          $('#ftco-loader').removeClass('show');
          /****************** -------- **********************/
          this.toastr.error(err, "Error : ");
          //this.router.navigate(['/']);
        });
      }
      else {
        this.toastr.warning("No Doctor avaliable for this selected service");
        this.getDoctorsList(this.Dom_serviceId);
      }
    }
    else {
      this.TimeSlotArray = [];
      this.IsTimeSlotArray = false;
      this.Dom_staffId = "";
      this.Dom_StaffName = "";
      this.Dom_staffemail = "";
    }
  }
  //#endregion

  //#region On the Select of time
  onTimeSelect(TimeEventDomObject, Time): void {
    debugger;
    var localThis = this;
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */

    $(".TimeSlot").removeClass("slotactive")
    TimeEventDomObject.srcElement.classList.add("slotactive");
    //this.formattedDateTime(new Date($('#datepicker').datepicker('getFormattedDate')), TimeEventDomObject.target.innerHTML)
    localThis.startDate = Time;
    this.startDateTime = localThis.startDate;

    var Ddtate = new Date(Time);
    var EdDate = new Date(Ddtate.setHours(Ddtate.getHours() + 1))
    this.endDate = EdDate.toISOString();
    $("#Doctors").click(function () {
      $("#your-image1").attr("src", "../../../assets/images/doctorfinal.png");
      $("#your-image2").attr("src", "../../../assets/images/questionactive.png");
    })
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
  }
  //#endregion

  //#region on Change service Data
  onServiceQuestions(serviceId): void {
    debugger;
    //*************Show / hide loader *******************/
    //$('#ftco-loader').addClass('show');
    /**************************************** */
    this.Questions = [];
    this.requestserive.getQuestionsByServiceId(serviceId).subscribe((res) => {

      this.Questions = res;
      $("#Phone").val(this.userMobile)
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }
  //#endregion

  //#region on Country Change
  onCountryChange($event) {
    this.dialcode = $event.dialCode;
  }
  //#endregion

  //#region Show the Service Details on the Model Pop up
  showInfoInPopUp(serviceData: any): void {
    $("#servicename").html(serviceData.name);
    $("#servicedescription").html(serviceData.description);
    $("#servicesdefaultPrice").html(serviceData.defaultPrice);
    $("#servicesdefaultDuration").html(serviceData.defaultDuration);
    $("#servicesstaffMemberIdslength").html(serviceData.staffMemberIds.length + " Specialists");
    $('#showServiceDetails').modal('show');
  }
  //#endregion

  // preferred contact
  getpreferrredContact(value) {
    debugger;
    this.preferredcontactmethod = value
  }
  //#region On Submit Funtion 
  onSubmit(appointment: NgForm): void {
    debugger;
    let isFundedOPtionValue = $("#isFunded").find('option:selected').val();
    let requestObj = appointment.value;
    /**PArsing Questions */
    let Questions_AnsList = [];

    $.each(this.Questions, function (index, value) {

      let dataToPush = {
        id: value["Id"],
        question: value["Name"],
        answer: $("#" + value["Id"]).val()
      }
      Questions_AnsList.push(dataToPush);
    });

    var phone = $("#Phone").val();;
    if (phone != "") {
      this.Phone = phone;
    }

    let localTime = this.DateToLocalString(new Date(this.startDate));

    let RequestAppointmentsDataToPost = {
      /**Basic Details */
      contactid: environment.LoginUserId,
      contactName: this.userName,
      contactemail: this.userEmail,
      contactPhone: this.Phone,
      staffId: this.Dom_staffId,
      staffName: this.Dom_StaffName,
      staffEmailAddress: this.Dom_staffemail,
      serviceId: this.Dom_serviceId,
      serviceName: this.Dom_serviceName,
      serviceDuration: this.Dom_serviceDurationInMins,
      servicePrice: this.Dom_price,
      street: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
      notes: this.Notes,
      subject: this.Subject,
      //subject : Questions_AnsList[0]?.answer,
      startDate: this.startDate,
      endDate: this.endDate,
      needOnlineMeeting: true,
      localTimeString: localTime,
      anyThingNewSinceYourLastAppointment: this.lastAppointmentComments,
      funded: isFundedOPtionValue,
      preferredContactMethod: this.preferredcontactmethod,
      /**Question Json */
      customerResponses: JSON.stringify(Questions_AnsList),
      relatedPersonCollection: [{
        id: environment.LoginUserId,
        Name: this.userName,
        Email: this.userEmail,
        Phone: this.Phone,
      }]
    };

    this.RequestAppointmentsData = RequestAppointmentsDataToPost;
    this.QNA=JSON.parse(RequestAppointmentsDataToPost.customerResponses);
    this.ServiceName = RequestAppointmentsDataToPost.serviceName;
    this.DoctorFullName = RequestAppointmentsDataToPost.staffName;

    this.patient_Name = RequestAppointmentsDataToPost.contactName;
    this.patient_Email = RequestAppointmentsDataToPost.contactemail;
    this.patient_MobilePhone = RequestAppointmentsDataToPost.contactPhone;

    this.Subject = RequestAppointmentsDataToPost.subject;
    this.Comment = RequestAppointmentsDataToPost.notes;
    this.bcrm_anythingnew = RequestAppointmentsDataToPost.anyThingNewSinceYourLastAppointment;

  }
  //#endregion

  //#region Submit to check consent form
  Submit(DatatoPost): void {
    debugger;
    DatatoPost.Notes = " ";
    DatatoPost.IsProxy = this.GuardianValue;
    DatatoPost.bcrm_patientguardian = this.bcrm_patientguardianvalue;
    //this.pay(this.Dom_price, DatatoPost)
    this.PatientBooking(DatatoPost);
  }
  //#endregion

  //#region Create Appointment In CRM
  createAppointmentInCRM(RequestAppointmentsData) {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.BookServies.createAppointment(RequestAppointmentsData).subscribe((res) => {
      if(res) {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
        this.toastr.success("Appointment is created");
       this.router.navigate(['/waitingRoom'], { skipLocationChange: true });
      }else{
            //*************Show / hide loader *******************/
            $('#ftco-loader').removeClass('show');
            /**************************************** */
            this.toastr.error("Appointment did not create");
      }

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      // this.router.navigate(['/']);
    });
  }
  //#endregion

  //#region Payment Process
  pay(amount, RequestAppointmentsData) {
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    var localThis = this;
    localThis.createAppointmentInCRM(RequestAppointmentsData);
    // var localThis = this;
    // var handler = (<any>window).StripeCheckout.configure({
    //   key: 'pk_test_tciTRCe2Ctpyi3MQ2xVW4YDk00RH0x9XCO',
    //   locale: 'auto',
    //   token: function (token: any) {
    //     // You can access the token ID with `token.id`.
    //     // Get the token ID to your server-side code for use.
    //     console.log(token)
    //     /** ------------Call payment Process Api The Done Payment*------------------ */
    //     localThis.BookServies.paymentProcess(amount, token).subscribe((res) => {
    //       if (res) {
    //         localThis.toastr.info("Payment Done", res.toString())
    //         //*************Show / hide loader *******************/
    //         $('#ftco-loader').removeClass('show');
    //         /**************************************** */
    //         localThis.createAppointmentInCRM(RequestAppointmentsData);

    //       }
    //     }, (err) => {
    //       localThis.createAppointmentInCRM(RequestAppointmentsData);
    //       //*************Show / hide loader *******************/
    //       $('#ftco-loader').removeClass('show');
    //       /****************** -------- **********************/
    //       this.toastr.error(err, "Error : ");
    //       //this.router.navigate(['/']);
    //     });
    //   }
    // });
    // handler.open({
    //   name: 'OxVc Portal',
    //   description: 'Virtual Clinic',
    //   amount: amount * 100,
    //   currency: "GBP",
    // });
  }
  //#endregion

  //#region Check Patient Consent

  PatientBooking(AppointmentData) {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    /**---------- null the Object------------- */

    // this.BookServies.CheckPatientConsentStatus(userId).subscribe((res) => {

    //   if (res) {

    this.createAppointmentInCRM(AppointmentData);
    // }
    // else {
    //   this.toastr.warning("", "We do not have your signed consent. These are normally reviewed and approved daily. If you think your consents should have already been processed please email contact@oxvc.health");
    //   //*************Show / hide loader *******************/
    //   $('#ftco-loader').removeClass('show');
    //   /****************** -------- **********************/
    // }
    // }, (err) => {
    //   //*************Show / hide loader *******************/
    //   $('#ftco-loader').removeClass('show');
    //   /****************** -------- **********************/
    //   this.toastr.info(err, "Error : ");
    //   // this.router.navigate(['/']);
    // });
  }
  //#endregion

  //#region Load Payment Process script
  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_tciTRCe2Ctpyi3MQ2xVW4YDk00RH0x9XCO',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log(token)
            alert('Payment Success!!');
          }
        });
      }
      window.document.body.appendChild(s);
    }
  }
  //#endregion

  //#region Function : To convert the Date in to local date string
  DateToLocalString(acept_Date) {
    let day = acept_Date.getDate() < 10 ? "0" + acept_Date.getDate() : "" + acept_Date.getDate();
    let month = acept_Date.getMonth() < 9 ? "0" + (acept_Date.getMonth() + 1) : "" + (acept_Date.getMonth() + 1);
    let hour = acept_Date.getHours() < 10 ? "0" + acept_Date.getHours() : "" + acept_Date.getHours();
    let minutes = acept_Date.getMinutes() < 10 ? "0" + acept_Date.getMinutes() : "" + acept_Date.getMinutes();
    return day + "/" + month + "/" + acept_Date.getFullYear() + "  " + hour + ":" + minutes;
  }
  //#endregion

  //#region Generic Fuction to filter the Service on the basis of the hidden from Customers
  filterServiceOfType(condition) {
    if (this.allServices != null) {
      return this.allServices.filter(x => x.isHiddenFromCustomers == condition);
    }
  }
  //#endregion

  getAllProxy(){
  
    debugger
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.ProxyData = null;
    this.sickNotesService.getProxyDetails(environment.LoginUserId).subscribe((res) => {
      if (res != null) {
        this.ProxyData = res;
      }

    $('#ftco-loader').removeClass('show');
      /**************************************** */
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      this.router.navigate(['/']);
    });
  }

  ChecktheGuardianValue(Event){
    debugger
       var DataofEvent = Event.currentTarget.value;
       if(DataofEvent != environment.LoginUserId){
        this.GuardianValue = true
        
       }
       else{
        this.GuardianValue = false;
       }
       
      }

}