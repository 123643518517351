import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CaseService {

  constructor(private http: HttpClient) { }

  getCase(ID) {
    return this.http.get(`${environment.apiUrl}/api/Case/GetCases?Customer_Id=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  //
  getNotes(ID) {
    return this.http.get(`${environment.apiUrl}/api/Case/GetNotesForCase?CaseID=${ID}`).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }

  createNotes(Note){
    debugger;
    return this.http.post(`${environment.apiUrl}/api/Case/CreateNotesForCase?CreatedFrom=1`, Note).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  createCase(Case,ContactId){
    return this.http.post(`${environment.apiUrl}/api/Case/Create?contactId=${ContactId}`, Case).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  upadteCase(Case,ContactId){
    return this.http.put(`${environment.apiUrl}/api/Case/UpdateCase?contactId=${ContactId}`, Case).pipe(
      // retry(1), 
      catchError(this.handleError)
    );
  }
  handleError(error) {
    
    //*************Show / hide loader *******************/
    $('#ftco-loader').removeClass('show');
    /**************************************** */
    let errorMessage = '';
    let errorMessageUi='';
    if (error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = `Error: ${error.error.message}`;  
      errorMessageUi=error.error.message;
    } else {
      // server-side error 
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.error!=undefined){
        errorMessageUi=error.error.message
      }
      else{
        errorMessageUi=error.message
      }
    }
    //  // Show error on hte Ui and Deirect to home
    //  this.toastr.error("Error", errorMessageUi);
    //  this.router.navigate(['/']);

    return throwError(errorMessageUi);
  }
}
