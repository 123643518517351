<div class="main-wrapper">
    <div class="page-wrapper">
      <div class="page-content">
  
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
              <div class="row heading">
                  <div class="col-md-4">
                    <h5> <i data-feather="file-text"> </i> Chat History <span style="color: gray;"></span></h5>
                  </div>
                  <div class="col-md-8">&nbsp; <button type="button" class="btn btn-primary float-right ml-1"
                    (click)="RefreshPage()"><i class="fa fa-refresh ref"
                        aria-hidden="true"></i></button>
                        &nbsp; </div>
              
                </div>
              <hr>
                <div class="table-responsive">
                  <table class="table-bordered" id="dataTableExample">
                    <thead>
                      <tr>
                        <th style="width: 15% !important;padding: 15PX;">Created on</th>
                        <th style="width: 60% !important;padding: 15PX;"> Symptoms Summary</th>
                        <!-- <th style="width: 25% !important;padding: 15PX;"> Possible Causes</th> -->
                        <!-- <th style="width: 25% !important;">Suggested Care</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of consentdata">
                        <td style="width: 15% !important;padding: 15PX;">{{data.createdOn | date:'dd-MMM-yyyy'}}</td>
                        <td style="width: 15% !important;padding: 15PX;" >{{data.symptomSummary}}</td>
                        <!-- <td style="width: 15% !important;padding: 15PX;">{{data.possible_causes}}</td> -->
                        <!-- <td>{{data.triage_result}}</td> -->
                        <!-- <td> 
                        <button href="#Modal1" class="btn fa-btn" data-toggle="modal"  aria-hidden="true">View</button>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>