<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text">
            <h3>Medicines</h3>
            <p><a href="index.html">Home /</a> Medicines</p>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="form-select" id="default-select" style="margin-top:2%;margin-bottom:5%">
                <div class="" tabindex="0">
                    <span class="current">Choose which Medicine(s) you want content on. </span>
                    <select style="display: none;" id="condit" class="list">
                        <option value="Aciclovir">Aciclovir (including Zovirax)</option>
                        <option value="Acrivastine">Acrivastine</option>
                        <option value="Adalimumab">Adalimumab</option>
                        <option value="Alendronic-acid">Alendronic acid</option>
                        <option value="Allopurinol">Allopurinol</option>
                        <option value="Alogliptin">Alogliptin</option>
                        <option value="Amitriptyline-for-depression">Amitriptyline for depression</option>
                        <option value="Amitriptyline-for-pain">Amitriptyline for pain</option>
                        <option value="Amlodipine">Amlodipine</option>
                        <option value="Amoxicillin">Amoxicillin</option>
                        <option value="Apixaban">Apixaban</option>
                        <option value="low-dose-aspirin">Aspirin - low dose</option>
                        <option value="Aspirin-for-pain-relief">Aspirin for pain relief</option>
                        <option value="Atenolol">Atenolol</option>
                        <option value="Atorvastatin">Atorvastatin</option>
                        <option value="Azathioprine">Azathioprine</option>
                        <option value="Azithromycin">Azithromycin</option>
                        <option value="Beclometasone-inhalers">Beclometasone inhalers</option>
                        <option value="Beclometasone-nasal-spray">Beclometasone nasal spray</option>
                        <option value="Bendroflumethiazide">Bendroflumethiazide</option>
                        <option value="Benzoyl-peroxide">Benzoyl peroxide</option>
                        <option value="Betahistine">Betahistine</option>
                        <option value="Bisacodyl">Bisacodyl</option>
                        <option value="Bisoprolol">Bisoprolol</option>
                        <option value="Bumetanide">Bumetanide</option>
                        <option value="Buscopan-hyoscine-butylbromide">Buscopan (hyoscine butylbromide)</option>
                        <option value="Candesartan">Candesartan</option>
                        <option value="Carbamazepine">Carbamazepine</option>
                        <option value="Carbocisteine">Carbocisteine</option>
                        <option value="Cefalexin">Cefalexin</option>
                        <option value="Cetirizine">Cetirizine</option>
                        <option value="Chloramphenicol">Chloramphenicol</option>
                        <option value="Chlorphenamine-including-Piriton">Chlorphenamine (including Piriton)</option>
                        <option value="Cinnarizine">Cinnarizine</option>
                        <option value="Ciprofloxacin">Ciprofloxacin</option>
                        <option value="Citalopram">Citalopram</option>
                        <option value="Clarithromycin">Clarithromycin</option>
                        <option value="Clobetasol">Clobetasol</option>
                        <option value="Clobetasone">Clobetasone</option>
                        <option value="Clonazepam">Clonazepam</option>
                        <option value="Clopidogrel">Clopidogrel</option>
                        <option value="Clotrimazole">Clotrimazole</option>
                        <option value="Clotrimazole-for-thrush">Clotrimazole for thrush (Canesten)</option>
                        <option value="Co-amoxiclav">Co-amoxiclav</option>
                        <option value="co-codamol-adults">Co-codamol for adults</option>
                        <option value="Co-codamol-for-children">Co-codamol for children</option>
                        <option value="Co-codaprin-aspirin-and-codeine">Co-codaprin (aspirin and codeine)</option>
                        <option value="Co-dydramol">Co-dydramol</option>
                        <option value="Codeine">Codeine</option>
                        <option value="Colchicine">Colchicine</option>
                        <option value="Cyclizine">Cyclizine</option>
                        <option value="Dabigatran">Dabigatran</option>
                        <option value="Dapagliflozin">Dapagliflozin</option>
                        <option value="Diazepam">Diazepam</option>
                        <option value="Diclofenac">Diclofenac</option>
                        <option value="Digoxin">Digoxin</option>
                        <option value="Dihydrocodeine">Dihydrocodeine</option>
                        <option value="Diltiazem">Diltiazem</option>
                        <option value="coronary-heart-disease">Diphenhydramine</option>
                        <option value="Dipyridamole">Dipyridamole</option>
                        <option value="Docusate">Docusate</option>
                        <option value="Domperidone">Domperidone</option>
                        <option value="Donepezil">Donepezil</option>
                        <option value="Dosulepin">Dosulepin</option>
                        <option value="Doxazosin">Doxazosin</option>
                        <option value="Doxycycline">Doxycycline</option>
                        <option value="Duloxetine">Duloxetine</option>
                        <option value="Edoxaban">Edoxaban</option>
                        <option value="Empagliflozin">Empagliflozin</option>
                        <option value="Enalapril">Enalapril</option>
                        <option value="Erythromycin">Erythromycin</option>
                        <option value="Escitalopram">Escitalopram</option>
                        <option value="Esomeprazole">Esomeprazole</option>
                        <option value="Ezetimibe">Ezetimibe</option>
                        <option value="Felodipine">Felodipine</option>
                        <option value="Ferrous-fumarate">Ferrous fumarate</option>
                        <option value="Ferrous-sulfate">Ferrous sulfate</option>
                        <option value="Fexofenadine">Fexofenadine</option>
                        <option value="Flucloxacillin">Flucloxacillin</option>
                        <option value="Fluconazole">Fluconazole</option>
                        <option value="Fluoxetine-Prozac">Fluoxetine (including Prozac)</option>
                        <option value="Fluticasone-inhalers">Fluticasone inhalers</option>
                        <option value="Fluticasone-nasal-spray-and-drops">Fluticasone nasal spray and drops</option>
                        <option value="Fluticasone-skin-creams">Fluticasone skin creams</option>
                        <option value="Folic-acid">Folic acid</option>
                        <option value="Furosemide">Furosemide</option>
                        <option value="Fusidic-acid">Fusidic acid</option>
                        <option value="Fybogel-ispaghula-husk">Fybogel (ispaghula husk)</option>
                        <option value="Gabapentin">Gabapentin</option>
                        <option value="Gaviscon">Gaviscon (alginic acid)</option>
                        <option value="Gliclazide">Gliclazide</option>
                        <option value="Glimepiride">Glimepiride</option>
                        <option value="Glyceryl-trinitrate-GTN">Glyceryl trinitrate (GTN)</option>
                        <option value="Heparinoid">Heparinoid</option>
                        <option value="Hydrocortisone">Hydrocortisone</option>
                        <option value="Hydrocortisone-buccal-tablets">Hydrocortisone buccal tablets</option>
                        <option value="Hydrocortisone-for-piles-and-itchy-bottom">Hydrocortisone for piles and itchy bottom</option>
                        <option value="Hydrocortisone-injections">Hydrocortisone injections</option>
                        <option value="Hydrocortisone-rectal-foam">Hydrocortisone rectal foam</option>
                        <option value="Hydrocortisone-skin-creams">Hydrocortisone skin creams</option>
                        <option value="Hydrocortisone-tablets">Hydrocortisone tablets</option>
                        <option value="Hydroxocobalamin">Hydroxocobalamin</option>
                        <option value="Hyoscine-hydrobromide">Hyoscine hydrobromide (Kwells and Joy-Rides)</option>
                        <option value="ibuprofen-and-codeine-nurofen-plus">Ibuprofen and codeine (including Nurofen Plus)</option>
                        <option value="Ibuprofen-for-adults">Ibuprofen for adults (including Nurofen)</option>
                        <option value="Ibuprofen-for-children">Ibuprofen for children</option>
                        <option value="Indapamide">Indapamide</option>
                        <option value="Irbesartan">Irbesartan</option>
                        <option value="Isotretinoin-capsules">Isotretinoin capsules (Roaccutane) </option>
                        <option value="Isotretinoin-gel">Isotretinoin gel (Isotrex)</option>
                        <option value="Labetalol">Labetalol</option>
                        <option value="Lactulose">Lactulose </option>
                        <option value="Lamotrigine">Lamotrigine c</option>
                        <option value="Lansoprazole">Lansoprazole</option>
                        <option value="Lercanidipine">Lercanidipine</option>
                        <option value="Levetiracetam">Levetiracetam</option>
                        <option value="Levothyroxine">Levothyroxine</option>
                        <option value="Lidocaine-for-mouth-and-throat">Lidocaine for mouth and throat</option>
                        <option value="Lidocaine-for-piles-and-itchy-bottom">Lidocaine for piles and itchy bottom</option>
                        <option value="lidocaine-for-skin">Lidocaine skin cream</option>
                        <option value="Linagliptin">Linagliptin</option>
                        <option value="Lisinopril">Lisinopril</option>
                        <option value="Loperamide">Loperamide (including Imodium)</option>
                        <option value="Loratadine">Loratadine (including Clarityn)</option>
                        <option value="Lorazepam">Lorazepam</option>
                        <option value="Losartan">Losartan</option>
                        <option value="Low-dose-aspirin">Low-dose aspirin</option>
                        <option value="Lymecycline">Lymecycline</option>
                        <option value="Macrogol">Macrogol</option>
                        <option value="Mebendazole">Mebendazole</option>
                        <option value="Mebeverine">Mebeverine</option>
                        <option value="Melatonin">Melatonin</option>
                        <option value="Metformin">Metformin</option>
                        <option value="Methotrexate">Methotrexate</option>
                        <option value="Metoclopramide">Metoclopramide</option>
                        <option value="Metoprolol">Metoprolol</option>
                        <option value="Metronidazole">Metronidazole</option>
                        <option value="Mirtazapine">Mirtazapine</option>
                        <option value="Montelukast">Montelukast</option>
                        <option value="Morphine">Morphine</option>
                        <option value="Naproxen">Naproxen</option>
                        <option value="Nefopam">Nefopam</option>
                        <option value="Nicorandil">Nicorandil</option>
                        <option value="Nifedipine">Nifedipine</option>
                        <option value="Nitrofurantoin">Nitrofurantoin</option>
                        <option value="Olmesartan">Olmesartan</option>
                        <option value="Omeprazole">Omeprazole</option>
                        <option value="Oxycodone">Oxycodone</option>
                        <option value="Pantoprazole">Pantoprazole</option>
                        <option value="Paracetamol-for-adults">Paracetamol for adults</option>
                        <option value="Paracetamol-for-children">Paracetamol for children (including Calpol)</option>
                        <option value="Paroxetine">Paroxetine</option>
                        <option value="Pepto-Bismol">Pepto-Bismol</option>
                        <option value="Perindopril">Perindopril</option>
                        <option value="Phenoxymethylpenicillin">Phenoxymethylpenicillin</option>
                        <option value="Phenytoin">Phenytoin</option>
                        <option value="Pioglitazone">Pioglitazone</option>
                        <option value="Pravastatin">Pravastatin</option>
                        <option value="Prednisolone">Prednisolone</option>
                        <option value="Pregabalin">Pregabalin</option>
                        <option value="Promethazine">Promethazine (including Phenergan)</option>
                        <option value="Propranolol">Propranolol</option>
                        <option value="Pseudoephedrine">Pseudoephedrine (including Sudafed) </option>
                        <option value="Rabeprazole">Rabeprazole</option>
                        <option value="Ramipril">Ramipril</option>
                        <option value="Ranitidine">Ranitidine</option>
                        <option value="Risedronate">Risedronate</option>
                        <option value="Rivaroxaban">Rivaroxaban</option>
                        <option value="Rosuvastatin">Rosuvastatin</option>
                        <option value="Salbutamol-inhaler">Salbutamol inhaler</option>
                        <option value="Saxagliptin">Saxagliptin</option>
                        <option value="Senna">Senna (including Senokot)</option>
                        <option value="Sertraline">Sertraline</option>
                        <option value="Sildenafil-Viagra">Sildenafil (including Viagra)</option>
                        <option value="peripheral-neuropathy">Peripheral neuropathy</option>
                        <option value="Simeticone">Simeticone</option>
                        <option value="Simvastatin">Simvastatin</option>
                        <option value="Sitagliptin">Sitagliptin</option>
                        <option value="Sodium-valproate">Sodium valproate</option>
                        <option value="Sotalol">Sotalol</option>
                        <option value="Sumatriptan">Sumatriptan</option>
                        <option value="Tadalafil">Tadalafil</option>
                        <option value="Tamsulosin">Tamsulosin</option>
                        <option value="Temazepam">Temazepam</option>
                        <option value="Thiamine-vitamin-B1">Thiamine (vitamin B1)</option>
                        <option value="Ticagrelor">Ticagrelor</option>
                        <option value="Topiramate">Topiramate</option>
                        <option value="Tramadol">Tramadol</option>
                        <option value="Tranexamic-acid">Tranexamic acid</option>
                        <option value="Trazodone">Trazodone</option>
                        <option value="Trimethoprim">Trimethoprim</option>
                        <option value="Valproic-acid">Valproic acid</option>
                        <option value="Valsartan">Valsartan</option>
                        <option value="Venlafaxine">Venlafaxine</option>
                        <option value="Warfarin">Warfarin</option>
                        <option value="Zolpidem">Zolpidem</option>
                        <option value="Zopiclone">Zopiclone</option>

                    </select>
                </div>
            </div>
            <hr />
            <h3 id="MedicineHeader"></h3>
            <h4 id="description"></h4>
            <div id="desp"></div>
            <div class="nhsuk-inset-text" id="Relatedlink"></div>
            <hr />
            <div id="mainContent"></div>
        </div>
    </div>
</div>