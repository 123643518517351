import { Component, ViewChild, NgZone, ElementRef, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
import { Ng2TelInputModule } from 'ng2-tel-input';
declare var $: any

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  inputObj: any;
  insuranceCompanyList: any;
  isInsuranceEnable: boolean = false;
  profile: any;
  file: any;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  dialcode: string;
  private geoCoder;
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'

  };
  // @ViewChild('search')
  // public searchElementRef: ElementRef;
  @ViewChild('search', { static: true }) searchElementRef: ElementRef;

  constructor(private profileservice: ProfileService,
    private router: Router, private toastr: ToastrService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private intlObj: Ng2TelInputModule) {
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-profile").addClass("active");
    /******End:Active Current Component Nav****** */
  }
  ngOnInit(): void {
    //Image change by id  show active when click on it//
    $("#profile").click(function() {
      // Change src attribute of image
      $("#your-image").attr("src", "../../../assets/images/Asset 2.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#allergy").click(function() {
      // Change src attribute of image
      $("#your-image1").attr("src", "../../../assets/images/Allergies.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newconditions").click(function() {
      // Change src attribute of image
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image2").attr("src", "../../../assets/images/Conditions.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newinvoices").click(function() {
      // Change src attribute of image
      $("#your-image3").attr("src", "../../../assets/images/Invoice.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newobservations").click(function() {
      // Change src attribute of image
      $("#your-image4").attr("src", "../../../assets/images/TDL-Observation.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newobservationOOB").click(function() {
      // Change src attribute of image
      $("#your-image5").attr("src", "../../../assets/images/Observations.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newprescriptions").click(function() {
      // Change src attribute of image
      $("#your-image6").attr("src", "../../../assets/images/Prescriptions.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newcimar").click(function() {
      // Change src attribute of image
      $("#your-image7").attr("src", "../../../assets/images/Digital.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
    $("#newdocument").click(function() {
      // Change src attribute of image
      $("#your-image8").attr("src", "../../../assets/images/Documents.png");
      $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
      $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
      $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
      $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
      $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
      $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
      $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image").attr("src", "../../../assets/images/profile.png");
      $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
      $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
    });
      $("#newConsent").click(function() {
        // Change src attribute of image
        $("#your-image9").attr("src", "../../../assets/images/Documents.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
      });
      $("#case").click(function() {
        // Change src attribute of image
        $("#your-image10").attr("src", "../../../assets/images/Documents.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
      });

      $("#SickNotes").click(function() {
        // Change src attribute of image
        $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image11").attr("src", "../../../assets/images/Allergies.png");
        $("#your-image12").attr("src", "../../../assets/images/Asset 3.png");
      });

      $("#ChatHistory").click(function() {
        // Change src attribute of image
        $("#your-image10").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image7").attr("src", "../../../assets/images/Asset 9.png");
        $("#your-image5").attr("src", "../../../assets/images/Asset 7.png");
        $("#your-image4").attr("src", "../../../assets/images/Asset 6.png");
        $("#your-image2").attr("src", "../../../assets/images/Asset 4.png");
        $("#your-image6").attr("src", "../../../assets/images/Asset 8.png");
        $("#your-image3").attr("src", "../../../assets/images/Asset 5.png");
        $("#your-image1").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image").attr("src", "../../../assets/images/profile.png");
        $("#your-image8").attr("src", "../../../assets/images/Asset 10.png");
        $("#your-image9").attr("src", "../../../assets/images/Asset 3.png");
        $("#your-image12").attr("src", "../../../assets/images/Allergies.png");
        $("#your-image11").attr("src", "../../../assets/images/Asset 3.png");
      });

  }
}
