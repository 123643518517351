import { Component, OnInit } from '@angular/core';
import axios from 'axios';
declare var $: any;

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.css']
})
export class MedicinesComponent implements OnInit {

  constructor() { 
        /******Active Current Component Nav****** */
        $(".nav-item").removeClass("active");
        $("#nav-item-NHS").addClass("active");
        /******End:Active Current Component Nav****** */
  }

  ngOnInit(): void {
    $(document).ready(function () {

      $("#condit").select2({
   
      });

      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };
  
        axios.get(`https://api.nhs.uk/medicines/Aciclovir`,
        config)
        .then((response) => {
  
          
          if (response !== null && response !== undefined) {
            $('#MedicineHeader').append(response.data.about.name);

            $('#description').append(response.data.description);

            for (var j = 0; j < response.data.relatedLink.length; j++) {
                $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[j].url + "'>" + response.data.relatedLink[j].name + "</a><br>");
                $('#Relatedlink a').addClass('MedLinkedUrl');
            }

            for (var k = 0; k < response.data.mainEntityOfPage.length; k++) {
                if (response.data.mainEntityOfPage[k].headline != null && response.data.mainEntityOfPage[k].headline != undefined) {
                    $('#mainContent').append("<h2> " + response.data.mainEntityOfPage[k].headline + "</h2>");
                }
                for (var l = 0; l < response.data.mainEntityOfPage[k].mainEntityOfPage.length; l++) {
                    if (response.data.mainEntityOfPage[k].text != null) {
                        $('#mainContent').append(" <p>" + response.data.mainEntityOfPage[k].text + "</p>");
                        $('#mainContent a').addClass('MedLinkedUrl');
                    }

                    $('#mainContent').append("<p>" + response.data.mainEntityOfPage[k].mainEntityOfPage[l].text + "</p>");
                    $('#mainContent a').addClass('MedLinkedUrl');
                }
            }
    $(".my_Loader").fadeOut("slow");

        }
      
        })
      
    });
    $('body').on('change', '#condit', function (e) {
      
      $(".my_Loader").show();
      var Condition = document.getElementById("condit");
      var conditionVal = e.target.value;
      //   var conditionVal = document.getElementById("condition").value;

      $("#conditionHeader").empty();
      $("#hasPart").empty();
      $("#Relatedlink").empty();
      $("#mainContent").empty();
      $("#CondDescription").empty();
      e.preventDefault();
      e.stopPropagation();
     
     
     
      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };
      if (conditionVal != null && conditionVal != "" && conditionVal != undefined) {
        axios.get(`https://api.nhs.uk/medicines/` + conditionVal,
        config)
        .then((response) => {
  
          
          if (response !== null  && response !== undefined) {
            $('#MedicineHeader').append(response.data.about.name);

            $('#description').append(response.data.description);

            for (var j = 0; j < response.data.relatedLink.length; j++) {
                $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[j].url + "'>" + response.data.relatedLink[j].name + "</a><br>");
                $('#Relatedlink a').addClass('MedLinkedUrl');
            }

            for (var k = 0; k < response.data.mainEntityOfPage.length; k++) {
                if (response.data.mainEntityOfPage[k].headline != null && response.data.mainEntityOfPage[k].headline != undefined) {
                    $('#mainContent').append("<h2> " + response.data.mainEntityOfPage[k].headline + "</h2>");
                }
                for (var l = 0; l < response.data.mainEntityOfPage[k].mainEntityOfPage.length; l++) {
                    if (response.data.mainEntityOfPage[k].text != null) {
                        $('#mainContent').append(" <p>" + response.data.mainEntityOfPage[k].text + "</p>");
                        $('#mainContent a').addClass('MedLinkedUrl');
                    }

                    $('#mainContent').append("<p>" + response.data.mainEntityOfPage[k].mainEntityOfPage[l].text + "</p>");
                    $('#mainContent a').addClass('MedLinkedUrl');
                }
            }
    $(".my_Loader").fadeOut("slow");

        }
      
        })
      }
     
  });
  
  $(document).on("click", ".MedLinkedUrl", function (e) {
    
    e.preventDefault();
    e.stopPropagation();
    var ahref = ($(this).attr('href'));
    if (ahref.includes("https")) {
        window.open(ahref);
        $(".my_Loader").fadeOut("slow");

    }
    else{
      $("#MedicineHeader").empty();
      $("#description").empty();
      $("#Relatedlink").empty();
      $("#mainContent").empty();

      const config = {

        headers: { 'subscription-key': '4e00413484974acdbe5f8488ae9f247e' }
      };
     
        axios.get(`https://api.nhs.uk/` + ahref,
        config)
        .then((response) => {
  
          
          if (response != null  && response != undefined) {
            $('#MedicineHeader').append(response.data.about.name);
            $('#description').append(response.data.description);

            for (var j = 0; j < response.data.relatedLink.length; j++) {
                $('#Relatedlink').append("<a target='_blank' href='" + response.data.relatedLink[j].url + "'>" + response.data.relatedLink[j].name + "</a><br>");
                $('#Relatedlink a').addClass('MedLinkedUrl');
            }
            for (var k = 0; k < response.data.mainEntityOfPage.length; k++) {
                if (response.data.mainEntityOfPage[k].headline != null && response.data.mainEntityOfPage[k].headline != undefined) {
                    $('#mainContent').append("<h2> " + response.data.mainEntityOfPage[k].headline + "</h2>");
                }
                for (var l = 0; l < response.data.mainEntityOfPage[k].mainEntityOfPage.length; l++) {
                    if (response.data.mainEntityOfPage[k].text != null) {
                        $('#mainContent').append(" <p>" + response.data.mainEntityOfPage[k].text + "</p>");
                        $('#Relatedlink a').addClass('MedLinkedUrl');
                    }
                    $('#mainContent').append("<p>" + response.data.mainEntityOfPage[k].mainEntityOfPage[l].text + "</p>");
                    $('#Relatedlink a').addClass('MedLinkedUrl');
                }
            }
            $(".my_Loader").fadeOut("slow");

        }
      
        })
      
    }
  });

  }

}
