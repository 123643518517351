<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="page-content">

      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row heading">
                <div class="col-md-4">
                  <h5> <i data-feather="file-text"> </i> Consent <span style="color: gray;"></span></h5>
                </div>
              </div>
              <div class="table-responsive pt-2">
                <table class="table table-bordered" id="dataTableExample">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Appointment Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of consentdata; index as i">
                      <td style="display: none;">{{i + 1}}</td>
                      <td>{{data.description}}</td>
                      <td>{{data.consentStatusFormatted}}</td>
                      <td>{{data.performedStartDate | date:'dd-MMMM-yyyy'}},{{procedureTime}}</td>
                      <td> <button [disabled]="data.consentStatus == 1 || data.consentStatus == 2"
                          [routerLink]="['/ConsentProcedure', data.Id ]" skipLocationChange class="btn fa-btn">
                          Proceed</button>
                        <button href="#Modal" class="btn fa-btn" data-toggle="modal" (click)="ViewDetail(data)"
                          [hidden]="data.consentStatus == 3 || data.consentStatus==4" aria-hidden="true">View</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="Modal" role="dialog">
  <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Procedure Information</h5>
              <!-- modal header -->
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
        ×
      </button>
          </div>
          <div class="modal-body" *ngIf="(proceduredata | keyvalue)?.length">
              <div class="container">
                  <div class="row">
                      <div class="col-sm-4" style=" margin-left: -1%;">
                          <div>
                              <strong>rocedure</strong> :
                          </div>
                          <div>
                              <strong> Status</strong>:

                          </div>
                          <div>
                              <strong>Type</strong>:
                          </div>
                          <div>
                              <strong>Appointment Date</strong>:
                          </div>

                      </div>
                      <div class="col-sm-8">
                          <div>
                              {{proceduredata.description }}
                          </div>
                          <div>
                              {{proceduredata.consentStatusFormatted}}

                          </div>
                          <div>
                              {{proceduredata.consentTypeFormatted}}
                          </div>
                          <div>
                              {{proceduredata.performedStartDate| date:'dd-MMMM-yyyy'}} at {{procedureTime}}
                          </div>

                      </div>
                  </div>
              </div>

              <br>
              <h6 style="color: black;">Please watch the following video and review the information relating to your {{proceduredata.description}}</h6>
              <div>
                  <a href="{{proceduredata.videoUrl}}" target="_blank" style="cursor: pointer;text-decoration: underline;">{{proceduredata.videoUrl}}</a>
              </div>
              <br>
              <h6 style="color: black;">Additional Information:</h6>
              <div class="row" id="docList" *ngIf="additionalvalue != undefined">
                  <ul>
                      <li *ngFor="let data of urlList"><a href="{{data.url}}" target="_blank" style="cursor: pointer;text-decoration: underline;">{{data.name}}</a></li>
                  </ul>
              </div>
              <h6 style="color: black;">Procedure Questions:</h6>

              <div *ngFor="let data of consentquestion  let i = index;">
                  <div *ngIf="data.type==1">
                      <label for="Questions">{{i+1}}.<strong> {{data.name}}<br><label>Ans:</label> {{data.bcrm_answer}}
                      </strong>
                      </label>
                  </div>

                  <div *ngIf="data.type==2">
                      <label for="Questions">{{i+1}}. <strong>{{data.name}}</strong></label>
                      <label>Ans: {{data.answer}}</label>
                  </div>


                  <div *ngIf="data.type==3">
                      <label for="Questions">{{i+1}}. <strong>{{data.name}}</strong></label><br> &nbsp; <label class="switch"><input type="checkbox" id="Questionsccheckbox" name="Questionsccheckbox"
              [(ngModel)]="data.bcrm_answer">
            <div class="slider round"><span class="on">Yes</span><span class="off">No</span></div>
            </label>
                  </div>
              
                  
                <div *ngIf="data.bcrm_type==4">
                      <label for="Questions">{{i+1}}. <strong>{{data.name}}</strong></label>
                  </div>


          </div>
      </div>

  </div>




</div>