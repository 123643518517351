import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.css']
})
export class HowitworksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".nav-item").removeClass("active");
    $("#nav-item-HowitWorks").addClass("active");
  }

}
