import { Component, ViewChild, NgZone, ElementRef, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import axios from 'axios';
import { ProfileService } from 'src/app/ApiServices/Profile/profile.service';
declare var $: any

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit {
  readonlyemergencycontact: boolean = false;
  RelationShipProxy: any;
  proxy: any;
  Mobilephoneproxy: any;
  imageURL: string;
  imgURL: any;
  inputObj: any;
  insuranceCompanyList: any;
  isInsuranceEnable: boolean = false;
  profile: any;
  file: any;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  dialcode: string;
  isprofile: boolean = true;
  issummary: boolean = false;
  contactgplist: any;
  relationshipkinslist: any
  private geoCoder;
  isSelectfunded: boolean = false;
  isalcoholdrink: boolean = false;
  followemail: boolean;
  donotbulkemail: boolean;
  donotphone: boolean;
  donotpostalmail: boolean;
  donotfax: boolean;
  donotemail: boolean
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'

  };
  specialCharactermobile = false;
  mobileinput: any;
  specialCharacteremail = false;
  Emailinputgp;
  // @ViewChild('search')
  // public searchElementRef: ElementRef;
  @ViewChild('search', { static: true }) searchElementRef: ElementRef;

  constructor(private profileservice: ProfileService,
    private router: Router, private toastr: ToastrService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private intlObj: Ng2TelInputModule) {
    /******Active Current Component Nav****** */
    $(".nav-item").removeClass("active");
    $("#nav-item-profile").addClass("active");
    /******End:Active Current Component Nav****** */
  }
  ngOnInit(): void {
    //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    /**************************************** */
    $('#file-upload').change(function () {
      var i = $(this).prev('label').clone();
      var file = $('#file-upload')[0].files[0].name;
      $(this).prev('label').text(file);
    });
    $('#datepicker').datepicker({
      format: 'mm/dd/yyyy',
      weekStart: 1,
      daysOfWeekHighlighted: "6,0",
      autoclose: true,
      todayHighlight: true,
    });
    // 
    $('#ftco-loader').removeClass('show');

    this.getProfile();
    this.getcontactgp();
    // this.getrelationship();



    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          for (var i = 0; i < place.address_components.length; i++) {

            var addressType = place.address_components[i].types[0];
            // for house no.
            var number = place.address_components[0].long_name;
            var streetname = place.address_components[1].long_name;
            //var street = number.concat(streetname);
            function spaces(x) {
              var res = '';
              while (x--) res += ' ';
              return res;
            }
            var street = number + spaces(1) + streetname;

            $("#route").val(street);
            if (this.componentForm[addressType]) {

              var val = place.address_components[i][this.componentForm[addressType]];
              if (document.getElementById(addressType) != null) {
                var myInput = $("#" + addressType);
                myInput.val(val);
                myInput.trigger('input'); // Use for Chrome/Firefox/Edge
                myInput.trigger('change');
                (<HTMLInputElement>document.getElementById(addressType)).value = val;
              }
            }
          }
        });
      });
    });

    //
    //#region
    var localThis = this;
    setTimeout(function () {
      $('#mySelect3').change(function (e) {
        var Id = e.target.value;
        localThis.getproxyrelationshipdata(Id);
      });
    }, 200);
    //#endregion
  }
  telInputObject(obj, val) {
    this.inputObj = obj;
    if (val) {
      obj.setNumber(val);
    }
  }
  onCountryChange($event) {
    this.dialcode == $event.dialCode;
  }
  getProfile(): void {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */

    this.profileservice.getProfile(environment.LoginUserEmail).subscribe((res) => {
      debugger;
      //*************Show / hide loader *******************/

      $('#ftco-loader').addClass('show');
      /**************************************** */
      this.profile = res;
      this.profile.imageBytes = 'data:image/png;base64,' + res['entityimage'];
      this.imgURL = this.profile.imageBytes;
      var date = this.profile.birthdate.toString("yyyy-mm-dd").split('T')[0];
      this.profile.birthdate = date;
      // 
      if(this.profile.emergencycontact != ""){debugger
        this.readonlyemergencycontact = true;
       }
       else{
         this.readonlyemergencycontact = false;
       }
      // Set toggle as allow mans false in crm but set ture for user experience//
      //#region 
      if (this.profile.donotbulkemail == false) {
        this.profile.donotbulkemail = true;
        this.donotbulkemail = false;
      } else {
        this.profile.donotbulkemail = false;
        this.donotbulkemail = true;
      }

      //
      if (this.profile.donotemail == false) {
        this.profile.donotemail = true;
        this.donotemail = false;

      }
      else {
        this.profile.donotemail = false;
        this.donotemail = true;
      }

      //
      if (this.profile.donotfax == false) {
        this.profile.donotfax = true;
        this.donotfax = false;
      } else {
        this.profile.donotfax = false;
        this.donotfax = true;
      }

      //
      if (this.profile.donotphone == false) {
        this.profile.donotphone = true;
        this.donotphone = false;
      } else {
        this.profile.donotphone = false;
        this.donotphone = true;
      }
      //
      if (this.profile.donotpostalmail == false) {
        this.profile.donotpostalmail = true;
        this.donotpostalmail = false;
      }

      else {
        this.profile.donotpostalmail = false;
        this.donotpostalmail = true;
      }


      //
      if (this.profile.followemail == false) {
        this.profile.followemail = false;
        this.followemail = false;
      } else {
        this.profile.followemail = true;
        this.followemail = true;
      }


      //#endregion

      var phonenumber = this.profile.mobilephone;

      if (this.profile.bcrm_funded == 3) {
        this.getInsuranceCompany();
      }
      if (this.profile.bcrm_funded == 1 && this.profile.bcrm_funded_name == 'Private' || this.profile.bcrm_funded == 2 && this.profile.bcrm_funded_name == 'NHS') {
        this.isSelectfunded = true
      } else {
        this.isSelectfunded = false
      }
      if (this.profile.bcrm_neverdrinkalcohol == true) {
        this.isalcoholdrink = true;
      } else {
        this.isalcoholdrink = false;
      }


      setTimeout(function () {
        $("#mobilephone").val(phonenumber);
      }, 1000);
      setTimeout(function () {

        $('#ftco-loader').removeClass('show');
      }, 3000);
    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');

      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  openfileDialog() {


    $("#file-input").val();
  }
  IncomingFile(event) {

    this.file = event[0];
    var reader = new FileReader();
    this.imageURL = this.file;
    reader.readAsDataURL(this.file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // document.getElementById("blah").src = this.file;
    }

  }
  Uploadfile() {

    if (this.file != undefined) {
      $('#ftco-loader').addClass('show');

      const formData = new FormData();
      formData.append('id', environment.LoginUserId);
      formData.append('file', this.file);
      this.profileservice.UploadPhoto(formData).subscribe((res) => {

        if (res == true) {
          this.getProfile();
        }
      }, (err) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /****************** -------- **********************/
        this.toastr.error(err, "Error :");
        //this.router.navigate(['/']);
      });
    }
    else {
      this.toastr.warning("Please choose file from select file");
    }

  }
  onSubmit(it: NgForm) {
    debugger
    if (this.isalcoholdrink == false) {
      this.profile.bcrm_howmanyunitsofalcoholdoyoudrinkinanavera = "";
    }
    it.value.donotbulkemail = this.donotbulkemail;
    it.value.donotemail = this.donotemail;
    it.value.donotfax = this.donotfax;
    it.value.donotphone = this.donotphone;
    it.value.donotpostalmail = this.donotpostalmail;
    it.value.followemail = this.followemail;
    $('#ftco-loader').addClass('show');
    var mobilephone = $("#mobilephone").val();
    this.profile = it.value;
    this.profile.id = environment.LoginUserId;
    this.profile.address1_line1 = $("#route").val();
    this.profile.address1_city = $("#locality").val();
    this.profile.address1_stateorprovince = $("#administrative_area_level_1").val();
    this.profile.address1_postalcode = $("#postal_code").val();
    this.profile.address1_country = $("#country").val();
    this.profile.donotbulkemail = it.value.donotbulkemail;
    this.profile.donotemail = it.value.donotemail;
    this.profile.donotfax = it.value.donotfax;
    this.profile.donotphone = it.value.donotphone;
    this.profile.donotpostalmail = it.value.donotpostalmail;
    this.profile.followemail = it.value.followemail;
    if (mobilephone != "") {
      this.profile.mobilephone = mobilephone;
    }
    this.profileservice.updateProfile(this.profile).subscribe((res) => {

      if (res == true) {
        this.toastr.success("Profile updated successfully");
        this.getProfile();
      }
      else {
        this.toastr.error("Error while updating profile");
      }
      setTimeout(function () {
        $('#ftco-loader').removeClass('show');
      }, 2000);

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /****************** -------- **********************/
      this.toastr.error(err, "Error : ");
      //this.router.navigate(['/']);
    });
  }

  // 

  getEmail(Value) {
    debugger;
    if (Value == false)
      this.donotemail = true;
    else
      this.donotemail = false
  }
  getFollowEmail(Value) {
    debugger;
    if (Value == false)
      this.followemail = false;
    else
      this.followemail = true;
  }
  getBulkEmail(Value) {
    if (Value == false)
      this.donotbulkemail = true;
    else
      this.donotbulkemail = false
  }
  getPhone(Value) {
    if (Value == false)
      this.donotphone = true;
    else
      this.donotphone = false
  }
  getMails(Value) {
    if (Value == false)
      this.donotpostalmail = true;
    else
      this.donotpostalmail = false
  }
  getFax(Value) {
    if (Value == false)
      this.donotfax = true;
    else
      this.donotfax = false
  }
  getInsuranceCompany() {
    debugger;
    this.isInsuranceEnable = true;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    axios.get(`${environment.apiUrl}/api/NoAuth/GetAccount`).then(
      (response) => {
        this.insuranceCompanyList = response.data;
        //*************Show / hide loader *******************/
        // $('#ftco-loader').removeClass('show');
        /**************************************** */
      },
      (error) => {
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      }
    );
  }
  IsCompanyEnable(fundedValue: string) {

    this.insuranceCompanyList = [];
    if (fundedValue == "3")//Insurance
    {
      this.getInsuranceCompany();
      this.isSelectfunded = false;

    } else {
      this.isInsuranceEnable = false;
      this.isSelectfunded = true;
    }
  }
  getprofiledata() {

    this.isprofile = true;
    this.issummary = false;
  }
  getsummary() {

    this.issummary = true;
    this.isprofile = false;
  }
  getcontactgp() {
    debugger;
    //*************Show / hide loader *******************/
    // $('#ftco-loader').addClass('show');
    /**************************************** */
    this.profileservice.getContactGP().subscribe((response) => {

      this.contactgplist = response
      //*************Show / hide loader *******************/
      // $('#ftco-loader').removeClass('show');
      /**************************************** */
    },
      (error) => {
        console.log(error);
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */
      });

  }
  getrelationship() {
    debugger;
    //*************Show / hide loader *******************/
    $('#ftco-loader').addClass('show');
    /**************************************** */
    this.profileservice.getRelationship().subscribe((response) => {
      this.relationshipkinslist = response
      var option = '';
      for (var i = 0; i < this.relationshipkinslist.length; i++) {
        var optionvalue = this.relationshipkinslist[i];
        if (optionvalue.id == this.profile.msemr_contact1relationship) {
          console.log(optionvalue.id)
          option += "<option value='" + optionvalue.id + "'selected>" + optionvalue.msemr_name + "</option>";
        } else {
          option += "<option value='" + optionvalue.id + "'>" + optionvalue.msemr_name + "</option>";
        }

      }
      //
      $('#mySelect2').html(option);
      $('.js-example-basic-single').select2();
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /**************************************** */
    },
      (error) => {
        //*************Show / hide loader *******************/
        $('#ftco-loader').removeClass('show');
        /**************************************** */

      });
  }
  isDrink(value) {

    if (value == true) {
      this.isalcoholdrink = true;

    } else {
      this.isalcoholdrink = false;
      (<HTMLInputElement>document.getElementById("alcoholunit")).value = "";
    }

  }
  //
  getrelationshipForproxy() {
    $("#mobilephoneproxy").val("+44");
    debugger;
    $('#ftco-loader').addClass('show');
    this.profileservice.getRelationship().subscribe((response) => {
      this.relationshipkinslist = response
      var option = '';
      this.relationshipkinslist.unshift({
        id: "",
        msemr_name: "--Select--"
      })
      for (var i = 0; i < this.relationshipkinslist.length; i++) {
        var optionvalue = this.relationshipkinslist[i];
        option += "<option value='" + optionvalue.id + "'>" + optionvalue.msemr_name + "</option>";
      }
      //
      $('#mySelect3').html(option);
      $('.js-example-basic-single1').select2();


      $('#ftco-loader').removeClass('show');
    },
      (error) => {
        console.log(error);
        setTimeout(function () {

          $('#ftco-loader').removeClass('show');

        }, 100);

      });
  }
  AddProxy(proxy: NgForm) {
    debugger

    this.proxy = proxy.value
    this.proxy.mobilephone = $("#mobilephoneproxy").val();
    // 
    var format = /^\+[0-9]{6,18}[0-9]$/;
    var validmobile = format.test(this.proxy.mobilephone);
    if (!validmobile && this.proxy.mobilephone != "") {
      return this.toastr.error("Please fill the field correctly");
    }

    //
    var format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validemail = format.test(this.proxy.emailaddress1);
    if (!validemail) {
      return this.toastr.error("Please fill the field correctly");
    }
    if (this.proxy.lastname == "" || this.proxy.emailaddress1 == "" || this.proxy.mobilephone == "") {
      return this.toastr.error("Mandatory fields should not be blank");
    }

    $('#ftco-loader').addClass('show');

    this.proxy.Id = environment.LoginUserId;
    this.proxy.Isproxy = true;
    this.proxy.relationship = this.RelationShipProxy;

    this.profileservice.AddProxy(this.proxy).subscribe((res: any) => {

      if (res.status == true) {
        this.toastr.success(res.responseMessage);
        $('#Modal1 form')[0].reset();
        $("#Modal1 .close").click();
        this.ReloadComponent();
      }

      else {
        this.toastr.error(res.responseMessage);
      }

      $('#ftco-loader').removeClass('show');

    }, (err) => {
      //*************Show / hide loader *******************/
      $('#ftco-loader').removeClass('show');
      /***************** -------- *********************/
      $("#Modal1 .close").click();
      this.toastr.error("Error while adding proxy");

    });


  }
  cleardata() {
    debugger
    $('#Modal1 form')[0].reset();
    this.ReloadComponent();
  }
  ReloadComponent(): void {
    let Location = this.router.url;
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([Location], { skipLocationChange: true });
    });
  }
  getproxyrelationshipdata(value) {

    this.RelationShipProxy = value;
  }

  checkmobilephoneproxy(Event) {
    debugger
    this.Mobilephoneproxy = Event['currentTarget']['value'];
    this.mobileinput = document.getElementById('mobileInfo');
    const mobilephone = Event['currentTarget']['value'];
    var format = /^\+[0-9]{6,18}[0-9]$/;


    if (format.test(mobilephone)) {
      this.specialCharactermobile = true;
    }

    if (!format.test(mobilephone)) {
      this.specialCharactermobile = false;
    }

    this.mobileinput.classList.add('toolBox');
    this.mobileinput.classList.remove('tooltiptext');

  }


  onCountryChangehomePhone($event) {
    debugger

    if (this.Mobilephoneproxy != "+") {
      this.dialcode = $event.dialCode;
      if (this.dialcode != undefined) {
        $("#mobilephoneproxy").val('+' + this.dialcode);
      }
    }
    else {
      $("#mobilephoneproxy").val(this.Mobilephoneproxy);
    }
  }

  // check email validation//
  checkEmail(Event) {

    this.Emailinputgp = document.getElementById('emailInfo');
    const PartnerEmail = Event['currentTarget']['value'];
    var format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (format.test(PartnerEmail)) {
      this.specialCharacteremail = true;
    }

    if (!format.test(PartnerEmail)) {
      this.specialCharacteremail = false;
    }

    this.Emailinputgp.classList.add('toolBox');
    this.Emailinputgp.classList.remove('tooltiptext');

  }
}
